/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
function Warning() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const {
    openside,
    getReportListForUser,
    getReportListForSponsor,
    Warning,
    getApprovedWorker,
    getAllDeductCoin,
  } = useContext(ApiContext);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [usercount, setUserCount] = useState();
  const [search, setSearch] = useState("");
  const [reportlimit, setReportLimit] = useState(5);
  const [reportpage, setReportPage] = useState(1);
  const [reportcountPage, setReportCountPage] = useState(1);
  const [reportCount, setReportCount] = useState();
  const [workerlimit, setWorkerLimit] = useState(5);
  const [workerpage, setWorkerPage] = useState(1);
  const [workercountPage, setWorkerCountPage] = useState(1);
  const [workerCount, setworkerCount] = useState();
  const [userreportlist, setUserReportList] = useState([]);
  const [sponsorreportlist, setsponsorReportList] = useState([]);
  const [workerlist, setWorkerList] = useState([]);
  const [status, setStatus] = useState("approve");
  const [show, setShow] = useState(false);
  const [warning, setwarning] = useState("");
  const [userdata, setUserData] = useState();
  const [sponsordata, setSponsorData] = useState();
  const [workerdata, setWorkerData] = useState();
  const [type, settype] = useState();
  const [showViewModal, setShowViewModal] = useState(false);
  const [details, setDetails] = useState();
  const [deducCreditList, setDeducCreditList] = useState([]);
  const [deductVal, setDeductVal] = useState(200);
  const [dates, setDates] = useState("");
  const [sponsordates, setSponsorDates] = useState("");
  const [workerdates, setWorkerDates] = useState("");
  const [name, setName] = useState("");
  const closeViewModal = () => {
    deactivetTr(details.report_id);
    setShowViewModal(false);
  };

  const handleClose = () => {
    setwarning("");
    if (userdata != undefined) {
      var myElement = document.getElementById("tr" + userdata?.report_id);
      myElement.classList.remove("acTive");
    } else if (sponsordata != undefined) {
      deactivetTr(sponsordata?.report_id);
    } else if (workerdata != undefined) {
      deactivetTr(workerdata.id);
    }

    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    getReportListForUser(
      limit,
      page,
      setCountPage,
      setUserReportList,
      search,
      setUserCount
    );
  }, [limit, page, search]);

  useEffect(() => {
    getReportListForSponsor(
      reportlimit,
      reportpage,
      setReportCountPage,
      setsponsorReportList,
      setReportCount
    );
  }, [reportlimit, reportpage]);

  useEffect(() => {
    getApprovedWorker(
      workerlimit,
      workerpage,
      setWorkerCountPage,
      setWorkerList,
      search,
      status,
      setworkerCount
    );
  }, [workerlimit, workerpage]);

  useEffect(() => {
    getAllDeductCoin(setDeducCreditList);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month
      }-${year}`;
  };
  function changeTab(tabName) {
    setUserData();
    switch (tabName) {
      case "user":
        if (userreportlist?.length === 0) {
          getReportListForUser(
            limit,
            page,
            setCountPage,
            setUserReportList,
            search
          );
        }
        break;
      case "sponsor":
        if (sponsorreportlist?.length === 0) {
          getReportListForSponsor(
            reportlimit,
            reportpage,
            setReportCountPage,
            setsponsorReportList
          );
        }
        break;
      case "worker":
        if (workerlist?.length === 0) {
          getApprovedWorker(
            workerlimit,
            workerpage,
            workercountPage,
            setWorkerList,
            search,
            status,
            setworkerCount
          );
        }
        break;

      default:
        break;
    }
  }

  function openWarningModal(item, type) {
    settype(type);
    if (type == 1) {
      setUserData(item);
      setName(item.user_name);
      activteTr(item.report_id);
      setSponsorData();
      setWorkerData();
    } else if (type == 2) {
      setName(item.sponser_name);
      activteTr(item.report_id);
      setSponsorData(item);
      setWorkerData();
      setUserData();
    } else {
      setName(item.display_name);
      activteTr(item.id);
      setWorkerData(item);
      setUserData();
      setSponsorData();
    }
    handleShow();
  }

  function sendwarning() {
    if (type == 1) {
      console.log(userdata);
      const data = {
        user_id:
          userdata?.user_id[0]?.id == undefined
            ? userdata?.user_id
            : userdata?.user_id[0]?.id,
        reason: warning,
        amount: userdata.user_total_warning >= 2 ? Math.floor(deductVal) : "",
        report_id: userdata.report_id,
      };
      // if (userdata.user_total_earn_coin >= Math.floor(deductVal)) {
      Warning(data, handleClose, getdata);
      // } else {
      //   toast.error("User Wallet Credit Is Less Than 200");
      // }
    } else if (type == 2) {
      const data = {
        user_id: sponsordata?.sponser_id,
        reason: warning,
        report_id: sponsordata?.report_id,
      };
      Warning(data, handleClose, getdata);
    } else {
      const data = {
        user_id: workerdata?.id,
        reason: warning,
      };
      Warning(data, handleClose, getdata);
    }
  }

  function getdata() {
    if (type == 1) {
      getReportListForUser(
        limit,
        page,
        setCountPage,
        setUserReportList,
        search,
        setUserCount
      );
    } else if (type == 2) {
      getReportListForSponsor(
        reportlimit,
        reportpage,
        setReportCountPage,
        setsponsorReportList,
        setReportCount
      );
    } else {
      getApprovedWorker(
        workerlimit,
        workerpage,
        setWorkerCountPage,
        setWorkerList,
        search,
        status,
        setworkerCount
      );
    }
  }

  function openViewModal(item) {
    setDetails(item);
    setShowViewModal(true);
    activteTr(item.report_id);
  }

  const setDateRange = (type) => {
    let date = type == 0 ? dates : type == 1 ? sponsordates : workerdates;
    const first = moment(date[0]).format("YYYY-MM-DD");
    const second = moment(date[1]).format("YYYY-MM-DD");
    if (first != "Invalid date" && second != "Invalid date") {
      if (type == 0) {
        getReportListForUser(
          limit,
          page,
          setCountPage,
          setUserReportList,
          search,
          setUserCount,
          first,
          second
        );
      } else if (type == 1) {
        getReportListForSponsor(
          reportlimit,
          reportpage,
          setReportCountPage,
          setsponsorReportList,
          setReportCount,
          first,
          second
        );
      } else {
        getApprovedWorker(
          workerlimit,
          workerpage,
          setWorkerCountPage,
          setWorkerList,
          search,
          status,
          setworkerCount,
          first,
          second
        );
      }
    } else {
      toast.warning("Please Enter Valid Date");
    }
  };

  const activteTr = (item) => {
    var myElement = document.getElementById("tr" + item);
    myElement.classList.add("acTive");
  };
  const deactivetTr = (item) => {
    var myElement = document.getElementById("tr" + item);
    myElement.classList.remove("acTive");
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("WARNING")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <Tabs
                defaultActiveKey="user"
                onSelect={(e) => changeTab(e)}
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="user" title={t("USER_REPORT")}>
                  <div className="">
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label> {t("SHOW_ENTRIES")}</label>
                        <select
                          className="form-control"
                          defaultValue="5"
                          onChange={(e) => {
                            e.stopPropagation();
                            setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="calenderd1">
                        <Calendar
                          value={dates}
                          onChange={(e) => setDates(e.value)}
                          selectionMode="range"
                          readOnlyInput
                          placeholder={t("SEARCH_WITH_DATE")}
                          minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                          maxDate={new Date()}
                        />

                        <button
                          className="btn btn_theme btn-sm"
                          disabled={dates == ""}
                          onClick={() => setDateRange(0)}
                        >
                          {t("FIND")}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("S_NO")}.</th>
                            <th className="orderd"> {t("REPORT_BY")}</th>
                            <th className="orderd"> {t("REPORT_DATE")}</th>
                            <th className="orderd"> {t("EMAIL")}</th>
                            <th className="orderd"> {t("COUNTRY")}</th>
                            <th className="orderd"> {t("AGAINST")}</th>
                            <th className="orderd"> {t("TOTAL_WARNING")}</th>
                            <th className="orderd" style={{ width: "500px" }}>
                              {t("COMPLAINT")}
                            </th>
                            <th>{t("ACTION")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userreportlist.map((item, index) => (
                            <tr key={index} id={"tr" + item.report_id}>
                              <td>
                                {page > 1
                                  ? limit * page - limit + index + 1
                                  : index + 1}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item?.worker_name}
                              </td>
                              <td>{formatDate(item?.create_datetime)}</td>
                              <td>{item?.worker_email}</td>
                              <td>
                                <img
                                  src={item.worker_country_flag_url}
                                  className="tab_flag"
                                />{" "}
                                {item?.worker_country_name}
                              </td>
                              <td
                                style={{
                                  color: "red",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item?.user_name != undefined
                                  ? item?.user_name
                                  : "-"}
                              </td>
                              <td>{item?.user_total_warning}</td>
                              <td style={{ textAlign: "left" }}>
                                {item?.content.length > 20
                                  ? item?.content.slice(0, 60) + "..."
                                  : item?.content}
                              </td>
                              <td style={{ width: "80px" }}>
                                <span className="btn_us1">
                                  <button
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("VIEW_DETAILS")}
                                    className="btn btn_clr"
                                    onClick={() => openViewModal(item)}
                                  >
                                    <img src="img/view.png" alt="" />
                                  </button>
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("SEND_WARNING")}
                                    onClick={() => openWarningModal(item, 1)}
                                  >
                                    <img src="img/notify_payment.png" />
                                  </button>
                                  <Tooltip id="view" />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {userreportlist.length == 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                      </div>
                    )}
                    {userreportlist.length != 0 && (
                      <div className="pag_botm">
                        <div className="left_neu">
                          <p>
                            {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                            {limit > usercount
                              ? usercount
                              : page * limit > usercount
                                ? usercount
                                : page * limit}{" "}
                            {t("OF")} {usercount} {t("ENTRIES")}
                          </p>
                        </div>
                        <div className="pagddination">
                          <button
                            className="btn prew_1"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (page > 1) {
                                setPage(page - 1);
                              }
                            }}
                          >
                            {t("PREVIOUS")}
                          </button>{" "}
                          <input
                            className="form-control"
                            type="text"
                            style={{ textAlign: "center" }}
                            // defaultValue={page}
                            value={page}
                            readOnly
                          />{" "}
                          <button
                            className="btn next_bth"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (countPage > page) {
                                setPage(page + 1);
                              }
                            }}
                          >
                            {t("NEXT")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="sponsor" title={t("SPONSOR_REPORT")}>
                  <div className="">
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label> {t("SHOW_ENTRIES")}</label>
                        <select
                          className="form-control"
                          defaultValue="5"
                          onChange={(e) => {
                            e.stopPropagation();
                            setReportLimit(e.target.value);
                            setReportPage(1);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="calenderd1">
                        <Calendar
                          value={sponsordates}
                          onChange={(e) => setSponsorDates(e.value)}
                          selectionMode="range"
                          readOnlyInput
                          placeholder={t("SEARCH_WITH_DATE")}
                          minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                          maxDate={new Date()}
                        />

                        <button
                          className="btn btn_theme btn-sm"
                          disabled={sponsordates == ""}
                          onClick={() => setDateRange(1)}
                        >
                          {t("FIND")}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd" style={{ width: "80px" }}>
                              {t("S_NO")}.
                            </th>
                            <th className="orderd"> {t("REPORT_BY")}</th>
                            <th className="orderd"> {t("REPORT_DATE")}</th>
                            <th className="orderd"> {t("EMAIL")}</th>
                            <th className="orderd"> {t("COUNTRY")}</th>
                            <th className="orderd"> {t("AGAINST")}</th>
                            <th className="orderd"> {t("TOTAL_WARNING")}</th>
                            <th className="orderd" style={{ width: "500px" }}>
                              {t("COMPLAINT")}
                            </th>
                            <th>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sponsorreportlist.map((item, index) => (
                            <tr key={index} id={"tr" + item.report_id}>
                              <td>
                                {reportpage > 1
                                  ? reportlimit * reportpage -
                                  reportlimit +
                                  index +
                                  1
                                  : index + 1}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item?.worker_name}
                              </td>
                              <td>{formatDate(item?.create_datetime)}</td>
                              <td>{item?.worker_email}</td>
                              <td>
                                <img
                                  src={item.worker_country_flag_url}
                                  className="tab_flag"
                                />{" "}
                                {item?.worker_country_name}
                              </td>
                              <td
                                style={{
                                  color: "red",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item?.sponser_name}
                              </td>
                              <td>{item?.sponser_total_warning}</td>
                              {/* <td>{item?.content}</td> */}
                              <td style={{ textAlign: "left" }}>
                                {item?.content.length > 20
                                  ? item?.content.slice(0, 60) + "..."
                                  : item?.content}
                              </td>
                              <td>
                                <span className="btn_us1">
                                  <button
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("VIEW_DETAILS")}
                                    className="btn btn_clr"
                                    onClick={() => openViewModal(item)}
                                  >
                                    <img src="img/view.png" alt="" />
                                  </button>
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("SEND_WARNING")}
                                    onClick={() => openWarningModal(item, 2)}
                                  >
                                    <img src="img/notify_payment.png" />
                                  </button>
                                  <Tooltip id="view" />
                                </span>

                                {/* <span className="btn_us3">
                                <button
                                  className="btn btn-sm btn_theme"
                                  onClick={() => openWarningModal(item, 2)}
                                >
                                  Send Warming
                                </button>
                              </span> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {sponsorreportlist.length == 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                      </div>
                    )}
                    {sponsorreportlist.length != 0 && (
                      <div className="pag_botm">
                        <div className="left_neu">
                          <p>
                            {t("SHOWING")}{" "}
                            {reportlimit * reportpage - reportlimit + 1}{" "}
                            {t("TO")}{" "}
                            {reportlimit > reportCount
                              ? reportCount
                              : reportpage * reportlimit > reportCount
                                ? reportCount
                                : reportpage * reportlimit}{" "}
                            {t("OF")} {reportCount}
                            {t("ENTRIES")}
                          </p>
                        </div>
                        <div className="pagddination">
                          <button
                            className="btn prew_1"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (reportpage > 1) {
                                setReportPage(reportpage - 1);
                              }
                            }}
                          >
                            {t("PREVIOUS")}
                          </button>{" "}
                          <input
                            className="form-control"
                            type="text"
                            style={{ textAlign: "center" }}
                            value={reportpage}
                            readOnly
                          />{" "}
                          <button
                            className="btn next_bth"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (reportcountPage > reportpage) {
                                setReportPage(reportpage + 1);
                              }
                            }}
                          >
                            {t("NEXT")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="worker" title={t("WORKER")}>
                  <div className="">
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label> {t("SHOW_ENTRIES")}</label>
                        <select
                          className="form-control"
                          defaultValue="5"
                          onChange={(e) => {
                            e.stopPropagation();
                            setWorkerLimit(e.target.value);
                            setWorkerPage(1);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="calenderd1">
                        <Calendar
                          value={workerdates}
                          onChange={(e) => setWorkerDates(e.value)}
                          selectionMode="range"
                          readOnlyInput
                          placeholder={t("SEARCH_WITH_DATE")}
                          minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                          maxDate={new Date()}
                        />

                        <button
                          className="btn btn_theme btn-sm"
                          disabled={workerdates == ""}
                          onClick={() => setDateRange(2)}
                        >
                          {t("FIND")}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd" style={{ width: "80px" }}>
                              {t("S_NO")}.
                            </th>
                            <th className="orderd">{t("WORKER_NAME")}</th>
                            <th className="orderd">{t("JOIN_DATE")}</th>
                            <th className="orderd">{t("EMAIL")}</th>
                            <th className="orderd">{t("COUNTRY")}</th>
                            <th className="orderd">{t("TOTAL_WARNING")}</th>
                            <th>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {workerlist.map((item, index) => (
                            <tr key={index} id={"tr" + item.id}>
                              <td>
                                {workerpage > 1
                                  ? workerlimit * workerpage -
                                  workerlimit +
                                  index +
                                  1
                                  : index + 1}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item?.display_name}
                              </td>
                              <td>{formatDate(item?.date_joined)}</td>
                              <td>{item?.email}</td>
                              <td>
                                <img
                                  src={item?.country_id[0].flag_url}
                                  className="tab_flag"
                                />{" "}
                                {item?.country_id[0].name}
                              </td>
                              <td>{item?.total_warning}</td>
                              <td>
                                <span className="btn_us1">
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("SEND_WARNING")}
                                    onClick={() => openWarningModal(item, 3)}
                                  >
                                    <img src="img/notify_payment.png" />
                                  </button>
                                  {/* <button
                                    className="btn btn-sm btn_theme"
                                    onClick={() => openWarningModal(item, 3)}
                                  >
                                    {t("SEND_WARNING")}
                                  </button> */}
                                  <Tooltip id="view" />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {workerlist.length == 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                      </div>
                    )}
                    {workerlist.length != 0 && (
                      <div className="pag_botm">
                        <div className="left_neu">
                          <p>
                            {t("SHOWING")}{" "}
                            {workerlimit * workerpage - workerlimit + 1}{" "}
                            {t("TO")}{" "}
                            {workerlimit > workerCount
                              ? workerCount
                              : workerpage * workerlimit > workerCount
                                ? workerCount
                                : workerpage * workerlimit}{" "}
                            {t("OF")} {workerCount}
                            {t("ENTRIES")}
                          </p>
                        </div>
                        <div className="pagddination">
                          <button
                            className="btn prew_1"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (workerpage > 1) {
                                setWorkerPage(workerpage - 1);
                              }
                            }}
                          >
                            {t("PREVIOUS")}
                          </button>{" "}
                          <input
                            className="form-control"
                            type="text"
                            style={{ textAlign: "center" }}
                            value={workerpage}
                            readOnly
                          />{" "}
                          <button
                            className="btn next_bth"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (workercountPage > workerpage) {
                                setWorkerPage(workerpage + 1);
                              }
                            }}
                          >
                            {t("NEXT")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showViewModal} onHide={closeViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("VIEW_ATTACHMENT_DETAILS_OF")} {details?.worker_name}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("REPORT_BY")} : </b>
                {details?.worker_name}
              </li>
              {details?.create_datetime != undefined ? (
                <li>
                  <b> {t("REPORT_DATE")} : </b>
                  {format(parseISO(details?.create_datetime), "dd MMM yyyy")}
                </li>
              ) : (
                ""
              )}
              <li>
                <b>{t("AGAINST")} : </b>
                {details?.user_name != undefined
                  ? details?.user_name
                  : details?.sponser_name}
              </li>
            </ul>
          </div>

          <div>
            <div className="form-group">
              <label htmlFor="Credit">{t("ATTACHMENT")}</label>
            </div>
            <img
              className="img_r img_atttec"
              src={
                details?.image_attachment_of_report != null
                  ? baseURL + details?.image_attachment_of_report
                  : "/img/image_n.png"
              }
              alt=""
            />
            <div className="form-group">
              <label htmlFor="Credit">{t("SUBJECT")}</label>
              <div className="faqs_modal">
                <p>{details?.subject_line}</p>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="Credit">{t("COMPLAINT")}</label>
              <div className="faqs_modal">
                <p>{details?.content}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>
              {" "}
              {t("SEND_WARNING_TO")} {name}{" "}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type == 1 && (
            <div className="list_desig">
              <ul className="ul_set">
                <li>
                  <b>{t("REPORT_BY")} : </b>
                  {userdata?.worker_name}
                </li>
                {userdata?.create_datetime != undefined ? (
                  <li>
                    <b> {t("REPORT_DATE")} : </b>
                    {format(parseISO(userdata?.create_datetime), "dd MMM yyyy")}
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <b>{t("AGAINST")} : </b>
                  {userdata?.user_name != undefined
                    ? userdata?.user_name
                    : userdata?.sponser_name}
                </li>
              </ul>
            </div>
          )}

          {type == 2 && (
            <div className="list_desig">
              <ul className="ul_set">
                <li>
                  <b>{t("REPORT_BY")} : </b>
                  {sponsordata?.worker_name}
                </li>
                {sponsordata?.create_datetime != undefined ? (
                  <li>
                    <b> {t("REPORT_DATE")} : </b>
                    {format(
                      parseISO(sponsordata?.create_datetime),
                      "dd MMM yyyy"
                    )}
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <b>{t("AGAINST")} : </b>
                  {sponsordata?.user_name != undefined
                    ? sponsordata?.user_name
                    : sponsordata?.sponser_name}
                </li>
              </ul>
            </div>
          )}

          {type == 3 && (
            <div className="list_desig">
              <ul className="ul_set">
                <li>
                  <b>{t("WORKER_NAME")} : </b>
                  {workerdata?.display_name}
                </li>
                <li>
                  <b>{t("PREVIOUS")} : </b>
                  {workerdata?.total_warning}
                </li>
                <li>
                  <b>{t("EMAIL")} : </b>
                  {workerdata?.email}
                </li>
              </ul>
            </div>
          )}

          {userdata != undefined && userdata.user_total_warning >= 2 ? (
            <div className="form-group">
              {/* <input
                type="number"
                className="form-control  hide-number-spinners"
                placeholder="Debit -200 credits"
                name="amount"
                readOnly             
              /> */}

              <select
                className="form-control"
                onChange={(e) => setDeductVal(e.target.value)}
              >
                {/* Using map to create options */}
                {deducCreditList.map((option, index) => (
                  <option key={index} value={option.amount}>
                    {option.amount}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          <div className="textarea">
            <textarea
              style={{ width: "101%" }}
              value={warning}
              onChange={(e) => setwarning(e.target.value)}
              rows={4} // specify the number of rows
              cols={50} // specify the number of columns
              placeholder={t("TYPE_SOMETHING")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={warning === ""}
            className="btn btn_theme"
            onClick={sendwarning}
          >
            {t("SEND_MESSAGE")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Warning;
