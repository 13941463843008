/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "../../Admin/CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { parseISO, format } from "date-fns";
function WorkerPaymentRequest() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [search, setSearch] = useState("");
  const { openside, getAllClient } = useContext(ApiContext);
  const [allClient, setAllClient] = useState([]);

  useEffect(() => {
    getAllClient(limit, page, setCountPage, setAllClient, search);
  }, [limit, page, search]);

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name="Send Payment Request" />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des">
                <div className="left_shiowp">
                  <label>Show Entries</label>
                  {/* pagination */}
                  <select
                    className="form-control"
                    defaultValue="10"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                      // getCustomer()
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {/* pagination */}
                </div>
                <div className="right_shiowp">
                  <label>Search</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd">Image</th>
                      <th className="orderd">Name</th>
                      {/* <th className="orderd">Email</th> */}
                      <th className="orderd">Country</th>
                      {/* <th className="orderd">Status</th> */}
                      <th className="orderd">Request date</th>
                      <th
                        style={{
                          width: "200px",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allClient.map((item, index) => (
                      <tr key={"1" + index}>
                        <td>
                          {" "}
                          <img
                            src={
                              item?.worker_profile_image != null
                                ? baseURL +
                                item?.worker_profile_image
                                : "img/woman_profile.jpg"
                            }
                            alt=""
                            className="tabl_pro_img"
                          />
                        </td>
                        <td>{item?.worker_name}</td>
                        <td>{item?.country_name}</td>
                        {/* <td>{item?.current_status}</td> */}
                        <td>
                          {format(parseISO(item.start_datetime), " dd MMM yyyy")}
                        </td>
                        <td>
                          <span className="btn_us1">
                            <button
                              className="btn btn_clr"
                            // onClick={() => accept("cancel", item.id)}
                            >
                              <img src="img/view.png" alt="" />
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pag_botm">
                <div className="left_neu">
                  <p>Showing of {countPage === 0 ? 1 : countPage}</p>
                </div>
                <div className="pagddination">
                  <button
                    className="btn prew_1"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (page > 1) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    Previous
                  </button>{" "}
                  <input
                    className="form-control"
                    type="text"
                    style={{ textAlign: "center" }}
                    defaultValue={page}
                    readOnly
                  />{" "}
                  <button
                    className="btn next_bth"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (countPage > page) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>

              {/* <DataTable
              columns={columns}
              data={recivedPayment}
              fixedHeader
              // pagination
            /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WorkerPaymentRequest;
