/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useEffect } from "react";
import { parseISO, format } from "date-fns";
import { Tooltip } from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
function Paymenthistory() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { paymentHistory, openside, DownlodImag } = useContext(ApiContext);
  let [paymenthistory, setPaymenthistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [search, setSearch] = useState("");
  const [datacount, setDataCount] = useState();
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState();
  const [dates, setDates] = useState("");
  const handleClose = () => {
    var myElement = document.getElementById("tr" + details.payment_request_id);
    myElement.classList.remove("acTive");
    setShow(false);
  };
  useEffect(() => {
    paymentHistory(
      limit,
      page,
      setLoader,
      setCountPage,
      setPaymenthistory,
      search,
      setDataCount
    );
  }, [page, limit, search]);
  function openModal(item) {
    var myElement = document.getElementById("tr" + item.payment_request_id);
    myElement.classList.add("acTive");
    setDetails(item);
    setShow(true);
  }
  const setDateRange = () => {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    if (first != "Invalid date" && second != "Invalid date") {
      paymentHistory(
        limit,
        page,
        setLoader,
        setCountPage,
        setPaymenthistory,
        search,
        setDataCount,
        first,
        second
      );
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  };
  const viewPage = (item) => {
    console.log(item);
    localStorage.setItem("data", JSON.stringify(item));
    navigate(
      `/Viewdetailspaymentbyid/${item.sponser_id?.id}/${item.payment_request_id}`
    );
  };

  const downloadImage = (imageURL) => {
    DownlodImag(imageURL);
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("PAYMENT_HISTORY")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des">
                <div className="left_shiowp">
                  <label> {t("SHOW_ENTRIES")}</label>
                  {/* pagination */}
                  <select
                    className="form-control"
                    defaultValue="10"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                      // getCustomer()
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {/* pagination */}
                </div>
                <div className="calenderd1">
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    placeholder={t("SEARCH_WITH_DATE")}
                    minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                    maxDate={new Date()}
                  />

                  <button
                    className="btn btn_theme btn-sm"
                    disabled={dates == ""}
                    onClick={setDateRange}
                  >
                    {t("FIND")}
                  </button>
                </div>
                <div className="right_shiowp">
                  <label>{t("SEARCH")}</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("SEARCH")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd">{t("S_NO")}.</th>
                      <th className="orderd">{t("USER_NAME")}</th>
                      <th className="orderd">{t("EMAIL")}</th>
                      <th className="orderd">{t("REQUEST_DATE")}</th>
                      <th className="orderd">{t("RECIVED_DATE")}</th>
                      <th className="orderd">{t("PAYMENT_ID")}</th>
                      <th className="orderd">{t("BINANCE_ID")}</th>
                      <th className="orderd">{t("COUNTRY")}</th>
                      <th className="orderd">{t("AMOUNT_SEARCH")}</th>
                      <th className="orderd">{t("SPONSOR_PERCENTAGE")}</th>
                      <th className="orderd">{t("TOTAL_PAY_AMOUNT")}</th>
                      <th
                        style={{
                          width: "80px",
                        }}
                      >
                        {t("ACTION")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymenthistory.map((item, index) => (
                      <tr
                        key={"TR" + index}
                        id={"tr" + item.payment_request_id}
                      >
                        <td>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.sponser_name}
                        </td>
                        <td>{item?.email}</td>
                        <td>
                          {" "}
                          {format(
                            parseISO(item.create_date_time),
                            "dd MMM yyyy, HH:mm"
                          )}
                        </td>
                        <td>
                          {" "}
                          {item.paid_date != undefined ? (
                            <div>
                              {format(
                                parseISO(item.paid_date),
                                "dd MMM yyyy, HH:mm"
                              )}
                            </div>
                          ) : (
                            "_"
                          )}
                        </td>
                        <td>{item?.payment_request_id}</td>
                        <td>{item?.sponser_binance_acc_id?.binance_pay_id}</td>
                        <td>
                          <img
                            src={
                              item?.country_id != undefined
                                ? item?.country_id[0].flag_url
                                : ""
                            }
                            className="tab_flag"
                          />{" "}
                          {item?.country_id === undefined
                            ? "-"
                            : item?.country_id[0]?.name}
                        </td>
                        <td>{"$" + item?.total_amount_request}</td>
                        <td>
                          {"$" + Math.floor(item?.total_amount_request * 0.1)}
                        </td>
                        <td>
                          {"$" +
                            (+item?.total_amount_request +
                              item?.total_amount_request * 0.1)}
                        </td>
                        <td>
                          <span className="btn_us1">
                            <button
                              data-tooltip-id="view"
                              data-tooltip-content={t("SEE_RECEIPT")}
                              className="btn btn_clr"
                              onClick={() => openModal(item)}
                            >
                              <img src="img/view.png" alt="" />
                            </button>
                            <Tooltip id="view" />

                            {/* <button className="btn btn-sm btn_theme2">
                            Payment Send
                          </button> */}
                          </span>
                          <span className="btn_us1">
                            <button
                              className="btn btn_clr"
                              data-tooltip-id="view"
                              data-tooltip-content={t("VIEW_DETAILS")}
                              onClick={() => viewPage(item)}
                            >
                              <img src="img/pay.png" alt="" />
                            </button>
                            <Tooltip id="view" />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {paymenthistory.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}

              {paymenthistory.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    <p>
                      {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                      {limit > datacount
                        ? datacount
                        : page * limit > datacount
                          ? datacount
                          : page * limit}{" "}
                      {t("OF")} {datacount}
                      {t("ENTRIES")}
                    </p>
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("VIEW_RECEIPT_OF")} {details?.sponser_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("NAME")} : </b>
                {details?.sponser_name}
              </li>
              <li>
                <b>{t("AMOUNT")} : </b>${details?.total_amount_request}
              </li>
              <li>
                <b>{t("EMAIL")} : </b>
                {details?.email}
              </li>
              {details?.create_date_time != undefined ? (
                <li>
                  <b> {t("REQUEST_DATE")} : </b>
                  {format(parseISO(details?.create_date_time), "dd MMM yyyy")}
                </li>
              ) : (
                ""
              )}

              {details?.paid_date != null ? (
                <li>
                  <b> {t("RECIVED_DATE")} : </b>
                  {format(parseISO(details?.paid_date), "dd MMM yyyy")}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="down_respt">
                <img
                  className="img_r"
                  src={
                    details?.receipts_image != undefined
                      ? baseURL + details?.receipts_image
                      : "./public/img/image_n.png"
                  }
                  alt=""
                />
                <h4>{t("RECEIPT")} </h4>
                <button
                  className="btn btn-sm btn_theme"
                  onClick={() => downloadImage(baseURL + details?.receipts_image)}
                >
                  <i class="uil uil-import"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default Paymenthistory;
