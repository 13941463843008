/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Tab, Tabs } from "react-bootstrap";
import ApiContext from "../../context/ApiContext";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
function Cardrate() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const {
    getCreditCoiinList,
    getGiftList,
    createGift,
    createCredit,
    updateCredit,
    openside,
    getAllDeductCoin,
    createDeductCoin,
    updateDeductCoin,
    deleteDebit,
    getWorkerwithdrawalCredits,
    deleteCoin,
    getCallCredit,
    createCallCredit,
  } = useContext(ApiContext);
  const namePattern = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
  const numberPattern = /^[1-9]\d*$/;

  const validationForCredit = Yup.object().shape({
    amount: Yup.string()
      .required(`${t("AMOUNT_IS_REQUIRED")}`)
      .matches(numberPattern, `${t("NUMBER_VALIDATION")}`),

    Credit: Yup.string()
      .required(`${t("CREDIT_IS_REQUIRED")}`)
      .matches(numberPattern, `${t("NUMBER_VALIDATION")}`),
  });
  const ValidationForGift = Yup.object({
    title: Yup.string()
      .matches(namePattern, `${t("NAME_VALIDATION")}`)
      .required(`${t("TITLE_REQUIRED")}`),

    Credit: Yup.string()
      .required(`${t("CREDIT_REQUIRED")}`)
      .matches(numberPattern, `${t("NUMBER_VALIDATION")}`),
  });
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [cointotalcount, setCoinTotalCount] = useState();
  const [giftlimit, setGiftLimit] = useState(5);
  const [giftpage, setGiftPage] = useState(1);
  const [gifttotalcount, setGiftTotalCount] = useState();
  const [giftcountPage, setGiftCountPage] = useState(1);
  // const [debitlimit, setDebitLimit] = useState(5);
  // const [debitpage, setDebitPage] = useState(1);
  // const [debittotalcount, setDebitTotalCount] = useState();
  // const [debitcountPage, setDebitCountPage] = useState(1);
  const [coinList, setcoinList] = useState([]);
  const [giftList, setGiftList] = useState([]);
  const [show, setShow] = useState(false);
  const [showgit, setShowgift] = useState(false);
  const [showDebit, setShowDebit] = useState(false);
  const [showCall, setShowCall] = useState(false);

  const [isupdate, setUpdate] = useState(0);
  const [isupdateDebit, setUpdateDebit] = useState(0);
  const [isupdateCall, setUpdateCall] = useState(0);
  const [isupdateGift, setUpdategift] = useState(0);
  const [giftImg, setGiftImg] = useState("");
  const [giftId, setGiftId] = useState("");
  const [creditId, setCreditId] = useState("");
  const [DebitList, setDebitList] = useState([]);
  const [debitVal, setDebitVal] = useState("");
  const [callVal, setCallVal] = useState("");
  const [WorkerwithdrawalList, setWorkerwithdrawalList] = useState([]);
  const [callCreditData, setcallCreditData] = useState([]);

  useEffect(() => {
    getCreditCoiinList(
      limit,
      page,
      setCountPage,
      setcoinList,
      setCoinTotalCount
    );
  }, [limit, page]);

  useEffect(() => {
    getGiftList(
      giftlimit,
      giftpage,
      setGiftCountPage,
      setGiftList,
      setGiftTotalCount
    );
  }, [giftlimit, giftpage]);

  const CreditModalClose = () => {
    formik.values.amount = "";
    formik.values.Credit = "";
    setShow(false);
    setCreditId();
  };
  const creditModalShow = (type) => {
    type === 0
      ? setUpdate(0)
      : type === 1
      ? setUpdate(1)
      : type === 2
      ? setUpdate(2)
      : setUpdate(3);
    setShow(true);
  };
  const openGiftModal = (type) => {
    type === 0 ? setUpdategift(0) : setUpdategift(1);
    setShowgift(true);
    setGiftId("");
  };
  const closeGiftModal = () => {
    creategift.resetForm();
    creategift.values.Credit = "";
    creategift.values.title = "";
    creategift.values.gift = "";
    setGiftImg("");
    setShowgift(false);
  };
  function openDebitModal(type) {
    setUpdateDebit(type);
    if (type != 0) {
      setDebitVal(type.amount);
    }
    setShowDebit(true);
  }

  function debitModalClose() {
    setDebitVal("");
    setShowDebit(false);
  }

  function creUpDebit() {
    let data = {
      amount: debitVal,
    };
    if (isupdateDebit == 0) {
      createDeductCoin(data, afterCrUpDebit);
    } else {
      //update Code here!!
      updateDeductCoin(isupdateDebit.id, data, afterCrUpDebit);
    }
  }
  function afterCrUpDebit() {
    getAllDeductCoin(setDebitList);
    setShowDebit(false);
  }

  const formik = useFormik({
    initialValues: {
      amount: "",
      Credit: "",
    },
    validationSchema: validationForCredit,
    onSubmit: (item) => {
      let data = {
        credit_coin: item.Credit,
        amount: item.amount,
        is_customer:
          isupdate === 0 ? 1 : isupdate === 1 ? 1 : isupdate === 2 ? 0 : 0,
      };
      if (isupdate === 0) {
        createCredit(data, afterCreateUpdate);
      } else if (isupdate === 1) {
        updateCredit(data, creditId, afterCreateUpdate);
      } else if (isupdate === 2) {
        createCredit(data, afterCreateUpdate);
      } else if (isupdate === 3) {
        updateCredit(data, creditId, afterCreateUpdate);
      }

      setPage(1);
    },
  });
  function afterCreateUpdate() {
    CreditModalClose();
    if (isupdate == 0 || isupdate == 1) {
      getCreditCoiinList(
        limit,
        page,
        setCountPage,
        setcoinList,
        setCoinTotalCount
      );
    } else if (isupdate == 2 || isupdate == 3) {
      getWorkerwithdrawalCredits(setWorkerwithdrawalList);
    }
  }

  const creategift = useFormik({
    initialValues: {
      gift: "",
      title: "",
      Credit: "",
    },
    validationSchema: ValidationForGift,
    onSubmit: (data) => {
      createGift(data, isupdateGift, giftId, afterCreateUpdateGift);
      setGiftPage(1);
    },
  });

  function afterCreateUpdateGift() {
    closeGiftModal();
    getGiftList(
      giftlimit,
      giftpage,
      setGiftCountPage,
      setGiftList,
      setGiftTotalCount
    );
  }

  function openUpdateCreditModal(value, type) {
    type == 0 ? creditModalShow(1) : creditModalShow(3);
    setCreditId(value.id);
    formik.values.amount = value.amount;
    formik.values.Credit = value.credit_coin;
  }

  function openUpdateGiftModal(value) {
    openGiftModal(1);
    creategift.values.title = value.name;
    creategift.values.Credit = value.coin;
    setGiftId(value.id);
    setGiftImg(value.gift_image);
  }

  function uploadImage(e) {
    creategift.values.gift = e.target.files;
    setGiftImg(URL.createObjectURL(e.target.files[0]));
  }

  function changeTab(tabName) {
    switch (tabName) {
      case "rate":
        if (coinList?.length === 0) {
          getCreditCoiinList(limit, page, setCountPage, setcoinList);
        }
        break;
      case "gift":
        if (giftList?.length === 0) {
          getGiftList(giftlimit, giftpage, setGiftCountPage, setGiftList);
        }
        break;
      case "debitcoin":
        if (DebitList?.length === 0) {
          getAllDeductCoin(setDebitList);
        }
        break;
      case "worker_credits":
        if (WorkerwithdrawalList?.length === 0) {
          getWorkerwithdrawalCredits(setWorkerwithdrawalList);
        }
        break;
      case "call_credit":
        if (callCreditData?.coin == undefined) {
          getCallCredit(setcallCreditData);
        }
        break;

      default:
        break;
    }
  }

  const deleteDebitCoin = (id, type) => {
    type == 1 ? setUpdate(0) : setUpdate(2);
    Swal.fire({
      title: t("ARE_YOU_SURE"),
      text: t("DELETE_MSG"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      cancelButtonColor: "#d33",
      confirmButtonText: t("YES") + "," + t("DELETE_IT") + "!",
      cancelButtonText: t("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        if (type == 0) {
          deleteDebit(id, afterCrUpDebit);
        } else {
          deleteCoin(id, afterCreateUpdate);
        }
      }
    });
  };

  const opencallCreditModal = (type) => {
    setShowCall(true);
    if (type == 0) {
      setUpdateCall(0);
    } else {
      setCallVal(callCreditData?.coin);
      setUpdateCall(1);
    }
  };

  function callModalClose() {
    setCallVal("");
    setShowCall(false);
  }

  function creUpCall() {
    let data = {
      CoinAmount: callVal,
    };
    if (isupdateCall == 1) {
      data.CoinId = callCreditData.id;
    }
    createCallCredit(data, aftercrup);
  }

  function aftercrup() {
    setCallVal("");
    setShowCall(false);
    getCallCredit(setcallCreditData);
  }
  const handleKeyDown = (event) => {
    console.log(event.target.value);
    if (event.key === "0" && event.target.value === "0") {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("CARD_RATE")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <Tooltip id="view" />
              <Tabs
                defaultActiveKey="rate"
                onSelect={(e) => changeTab(e)}
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="rate" title={t("USER_CREDITS")}>
                  <div className="">
                    <h4 className="head_card">
                      <button
                        className="btn btn_theme "
                        onClick={() => creditModalShow(0)}
                      >
                        {t("CREATE_CREDIT")}
                      </button>
                    </h4>
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label>{t("SHOW_ENTRIES")} </label>
                        <select
                          className="form-control"
                          defaultValue="5"
                          onChange={(e) => {
                            e.stopPropagation();
                            setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd">{t("S_NO")}.</th>
                            <th className="orderd">{t("AMOUNT")}</th>
                            <th className="orderd">{t("CREDIT")}</th>
                            <th>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coinList.map((item, index) => (
                            <tr key={"1" + index}>
                              <td style={{ width: "70px" }}>
                                {page > 1
                                  ? limit * page - limit + index + 1
                                  : index + 1}
                              </td>
                              <td>{"$" + item.amount}</td>
                              <td>{item.credit_coin}</td>

                              <td>
                                <span className="btn_us3">
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("UPDATE_CREDIT")}
                                  >
                                    <img
                                      src="img/edit.png"
                                      onClick={() =>
                                        openUpdateCreditModal(item, 0)
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <button
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("DELETE")}
                                    className="btn btn_clr"
                                    onClick={() => deleteDebitCoin(item.id, 2)}
                                  >
                                    <img src="img/delete.png" alt="" />{" "}
                                  </button>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="pag_botm">
                      <div className="left_neu">
                        {t("SHOWING")} {limit * page - limit + 1}
                        {t("TO")}{" "}
                        {limit > cointotalcount
                          ? cointotalcount
                          : page * limit > cointotalcount
                          ? cointotalcount
                          : page * limit}{" "}
                        {t("OF")} {cointotalcount} {t("ENTRIES")}
                      </div>
                      <div className="pagddination">
                        <button
                          className="btn prew_1"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (page > 1) {
                              setPage(page - 1);
                            }
                          }}
                        >
                          {t("PREVIOUS")}
                        </button>{" "}
                        <input
                          className="form-control"
                          type="text"
                          style={{ textAlign: "center" }}
                          // defaultValue={page}
                          value={page}
                          readOnly
                        />{" "}
                        <button
                          className="btn next_bth"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (countPage > page) {
                              setPage(page + 1);
                            }
                          }}
                        >
                          {t("NEXT")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="gift" title={t("GIFT")}>
                  <div className="">
                    <h4 className="head_card">
                      <button
                        className="btn btn_theme"
                        onClick={() => openGiftModal(0)}
                      >
                        {t("CREATE_GIFT")}
                      </button>
                    </h4>
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label>{t("SHOW_ENTRIES")} </label>
                        <select
                          className="form-control"
                          defaultValue="5"
                          onChange={(e) => {
                            e.stopPropagation();
                            setGiftLimit(e.target.value);
                            setGiftPage(1);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd" style={{ width: "80px" }}>
                              {t("S_NO")} .
                            </th>
                            <th className="orderd">{t("NAME")} </th>
                            <th className="orderd">{t("GIFT_IMAGE")} </th>
                            <th className="orderd">{t("CREDITS")} </th>
                            <th>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {giftList.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {giftpage > 1
                                  ? giftlimit * giftpage - giftlimit + index + 1
                                  : index + 1}
                              </td>
                              <td>{item.name}</td>
                              <td>
                                <img
                                  className="img_tb"
                                  src={baseURL + item.gift_image}
                                />
                              </td>
                              <td>{item.coin}</td>
                              <td>
                                <span className="btn_us3">
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={
                                      `${t("UPDATE_GIFT")} ` + item.name
                                    }
                                  >
                                    <img
                                      src="img/edit.png"
                                      onClick={() => openUpdateGiftModal(item)}
                                      alt=""
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="pag_botm">
                      <div className="left_neu">
                        <p>
                          {t("SHOWING")} {giftlimit * giftpage - giftlimit + 1}{" "}
                          {t("TO")}{" "}
                          {giftlimit > gifttotalcount
                            ? gifttotalcount
                            : giftpage * giftlimit > gifttotalcount
                            ? gifttotalcount
                            : giftpage * giftlimit}{" "}
                          {t("OF")} {gifttotalcount} {t("ENTRIES")}
                        </p>
                      </div>
                      <div className="pagddination">
                        <button
                          className="btn prew_1"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (giftpage > 1) {
                              setGiftPage(giftpage - 1);
                            }
                          }}
                        >
                          {t("PREVIOUS")}
                        </button>{" "}
                        <input
                          className="form-control"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={giftpage}
                          readOnly
                        />{" "}
                        <button
                          className="btn next_bth"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (giftcountPage > giftpage) {
                              setGiftPage(giftpage + 1);
                            }
                          }}
                        >
                          {t("NEXT")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="debitcoin" title={t("DEBIT_COIN")}>
                  <div className="">
                    {DebitList.length == 0 && (
                      <h4 className="head_card">
                        <button
                          className="btn btn_theme"
                          onClick={() => openDebitModal(0)}
                        >
                          {t("CREATE_DEBIT_COIN")}
                        </button>
                      </h4>
                    )}
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd" style={{ width: "80px" }}>
                              {t("S_NO")}.
                            </th>
                            <th className="orderd"> {t("DEBIT_COIN")}</th>
                            <th> {t("ACTION")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          {DebitList.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.amount}</td>
                              <td>
                                <span className="btn_us3">
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={
                                      `${t("Update_Debit_Coin")} ` + item.amount
                                    }
                                  >
                                    <img
                                      src="img/edit.png"
                                      onClick={() => openDebitModal(item)}
                                      alt=""
                                    />
                                  </button>
                                  {/* <button
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("DELETE")}
                                    className="btn btn_clr"
                                    onClick={() => deleteDebitCoin(item.id, 0)}
                                  >
                                    <img src="img/delete.png" alt="" />{" "}
                                  </button> */}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="worker_credits" title={t("WORKER_CREDITS")}>
                  <div className="">
                    <h4 className="head_card">
                      <button
                        className="btn btn_theme "
                        onClick={() => creditModalShow(2)}
                      >
                        {t("CREATE_WITHDRAWAL")}
                      </button>
                    </h4>
                    <div className="saech_des">
                      {/* <div className="left_shiowp">
                        <label>{t("SHOW_ENTRIES")} </label>
                        <select
                          className="form-control"
                          defaultValue="5"
                          onChange={(e) => {
                            e.stopPropagation();
                            setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div> */}
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd">{t("S_NO")}.</th>
                            <th className="orderd">{t("AMOUNT")}</th>
                            <th className="orderd">{t("CREDIT")}</th>
                            <th>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {WorkerwithdrawalList.map((item, index) => (
                            <tr key={"1" + index}>
                              <td style={{ width: "70px" }}>
                                {page > 1
                                  ? limit * page - limit + index + 1
                                  : index + 1}
                              </td>
                              <td>{"$" + item.amount}</td>
                              <td>{item.credit_coin}</td>

                              <td>
                                <span className="btn_us3">
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("UPDATE_CREDIT")}
                                  >
                                    <img
                                      src="img/edit.png"
                                      onClick={() =>
                                        openUpdateCreditModal(item, 1)
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <button
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("DELETE")}
                                    className="btn btn_clr"
                                    onClick={() => deleteDebitCoin(item.id, 1)}
                                  >
                                    <img src="img/delete.png" alt="" />{" "}
                                  </button>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="pag_botm">
                      <div className="left_neu">
                        {t("SHOWING")} {limit * page - limit + 1}
                        {t("TO")}{" "}
                        {limit > cointotalcount
                          ? cointotalcount
                          : page * limit > cointotalcount
                          ? cointotalcount
                          : page * limit}{" "}
                        {t("OF")} {cointotalcount} {t("ENTRIES")}
                      </div>
                      <div className="pagddination">
                        <button
                          className="btn prew_1"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (page > 1) {
                              setPage(page - 1);
                            }
                          }}
                        >
                          {t("PREVIOUS")}
                        </button>{" "}
                        <input
                          className="form-control"
                          type="text"
                          style={{ textAlign: "center" }}
                          defaultValue={page}
                          value={page}
                          readOnly
                        />{" "}
                        <button
                          className="btn next_bth"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (countPage > page) {
                              setPage(page + 1);
                            }
                          }}
                        >
                          {t("NEXT")}
                        </button>
                      </div>
                    </div> */}
                  </div>
                </Tab>
                <Tab eventKey="call_credit" title={t("CALL_CREDIT")}>
                  <div className="">
                    {callCreditData.coin == undefined && (
                      <h4 className="head_card">
                        <button
                          className="btn btn_theme"
                          onClick={() => opencallCreditModal(0)}
                        >
                          {t("CREATE_DEBIT_COIN")}
                        </button>
                      </h4>
                    )}
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd" style={{ width: "80px" }}>
                              {t("S_NO")}.
                            </th>
                            <th className="orderd"> {t("CALL_CREDIT")}</th>
                            <th> {t("ACTION")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          {callCreditData.coin != undefined && (
                            <tr>
                              <td>{1}</td>
                              <td>{callCreditData.coin}</td>
                              <td>
                                <span className="btn_us3">
                                  <button
                                    className="btn btn_clr"
                                    data-tooltip-id="view"
                                    data-tooltip-content={
                                      `${t("UPDATE_CALL_CREDIT")} ` +
                                      callCreditData.coin
                                    }
                                  >
                                    <img
                                      src="img/edit.png"
                                      onClick={() => opencallCreditModal(1)}
                                      alt=""
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {callCreditData.coin == undefined && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                      </div>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      {/* card modal start */}
      <Modal
        show={show}
        onHide={CreditModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {isupdate === 0
              ? `${t("CREATE_CREDIT")}`
              : isupdate === 1
              ? `${t("UPDATE_CREDIT")}`
              : isupdate === 2
              ? `${t("CREATE_WITHDRAWAL")}`
              : `${t("UPDATE_WITHDRAWAL")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="Credit">{t("AMOUNT")}</label>
                <input
                  type="text"
                  maxLength={8}
                  className={
                    "form-control hide-number-spinners" +
                    (formik.errors.amount && formik.touched.amount
                      ? " is-invalid"
                      : "")
                  }
                  placeholder={t("ENTER_AMOUNT")}
                  onChange={formik.handleChange}
                  onKeyDown={(event) => {
                    if (event.key === "0" && event.target.value === "0") {
                      formik.handleChange({
                        target: {
                          name: "amount",
                          value: "",
                        },
                      });
                      event.preventDefault();
                    }
                  }}
                  name="amount"
                  value={formik.values.amount}
                />
                <div className="invalid-feedback">
                  {formik.errors.amount && formik.touched.amount
                    ? formik.errors.amount
                    : null}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="Credit">{t("CREDIT")}</label>
                <input
                  type="text"
                  maxLength={8}
                  name="Credit"
                  className={
                    "form-control hide-number-spinners" +
                    (formik.errors.Credit && formik.touched.Credit
                      ? " is-invalid"
                      : "")
                  }
                  placeholder={t("ENTER_CREDIT")}
                  onChange={formik.handleChange}
                  onKeyDown={(event) => {
                    if (event.key === "0" && event.target.value === "0") {
                      formik.handleChange({
                        target: {
                          name: "Credit",
                          value: "",
                        },
                      });
                      event.preventDefault();
                    }
                  }}
                  value={formik.values.Credit}
                />
                <div className="invalid-feedback">
                  {formik.errors.Credit && formik.touched.Credit
                    ? formik.errors.Credit
                    : null}
                </div>
              </div>
              <div className="mt-3">
                <button
                  style={{ float: "right" }}
                  type="submit"
                  className="btn btn_theme"
                >
                  {isupdate === 0
                    ? `${t("CREATE")}`
                    : isupdate === 1
                    ? `${t("UPDATE")}`
                    : isupdate === 2
                    ? `${t("CREATE")}`
                    : `${t("UPDATE")}`}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* card modal End */}

      {/* Gift modal start */}
      <Modal
        show={showgit}
        onHide={closeGiftModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {isupdateGift === 0 ? `${t("CREATE_GIFT")}` : `${t("UPDATE_GIFT")}`}
            {/* Create Gift */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={creategift.handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">{t("TITLE")}</label>
                <input
                  maxLength={20}
                  type="text"
                  className={
                    "form-control" +
                    (creategift.errors.title && creategift.touched.title
                      ? " is-invalid"
                      : "")
                  }
                  placeholder={t("ENTER_TITLE")}
                  onChange={creategift.handleChange}
                  onKeyDown={(event) => {
                    if (event.key === "0" && event.target.value === "0") {
                      creategift.handleChange({
                        target: {
                          name: "title",
                          value: "",
                        },
                      });
                      event.preventDefault();
                    }
                  }}
                  name="title"
                  value={creategift.values.title}
                />
                <div className="invalid-feedback">
                  {creategift.errors.title && creategift.touched.title
                    ? creategift.errors.title
                    : null}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="Credit">{t("CREDIT")}</label>
                <input
                  type="text"
                  name="Credit"
                  className={
                    "form-control hide-number-spinners" +
                    (creategift.errors.Credit && creategift.touched.Credit
                      ? " is-invalid"
                      : "")
                  }
                  maxLength="10"
                  placeholder={t("ENTER_CREDIT")}
                  onChange={creategift.handleChange}
                  value={creategift.values.Credit}
                  onKeyDown={(event) => {
                    if (event.key === "0" && event.target.value === "0") {
                      creategift.handleChange({
                        target: {
                          name: "Credit",
                          value: "",
                        },
                      });
                      event.preventDefault();
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {creategift.errors.Credit && creategift.touched.Credit
                    ? creategift.errors.Credit
                    : null}
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="Credit">{t("UPLOAD_GIFT_IMAGE")}</label>
                <input
                  type="file"
                  id="giftupload"
                  onChange={uploadImage}
                  className={
                    "form-control" +
                    (creategift.errors.gift && creategift.touched.gift
                      ? " is-invalid"
                      : "")
                  }
                />
                <div className="invalid-feedback">
                  {creategift.errors.gift && creategift.touched.gift
                    ? creategift.errors.gift
                    : null}
                </div>
                {giftImg !== "" ? (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <img
                      style={{ height: "120px", width: "120px" }}
                      src={baseURL + giftImg}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-3">
                <button
                  style={{ float: "right" }}
                  type="submit"
                  className="btn btn-primary"
                >
                  {isupdateGift === 0
                    ? `${t("CREATE_GIFT")}`
                    : `${t("UPDATE_GIFT")}`}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* gif modal End */}

      {/* Debit modal start */}
      <Modal
        show={showDebit}
        onHide={debitModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {isupdateDebit === 0
              ? `${t("CREATE_DEBIT_CREDIT")}`
              : `${t("UPDATE_DEBIT_CREDIT")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label htmlFor="Credit">{t("DEBIT")}</label>
              <input
                type="number"
                name="Credit"
                className={"form-control hide-number-spinners"}
                placeholder={t("ENTER_DEBIT")}
                onChange={(e) => {
                  if (e.target.value.length >= "10") {
                  } else if (e.target.value != "0") {
                    setDebitVal(e.target.value);
                  }
                }}
                onKeyDown={handleKeyDown}
                value={debitVal}
              />
            </div>
            <div className="mt-3">
              <button
                style={{ float: "right" }}
                type="submit"
                className="btn btn_theme"
                onClick={creUpDebit}
                disabled={debitVal == "" || debitVal.length < 1}
              >
                {isupdateDebit === 0 ? `${t("CREATE")}` : `${t("UPDATE")}`}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Debit modal End */}

      {/* call modal start */}
      <Modal
        show={showCall}
        onHide={callModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {isupdateCall === 0
              ? `${t("CREATE_CALL_CREDIT")}`
              : `${t("UPDATE_CALL_CREDIT")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label htmlFor="Credit">{t("CREDIT")}</label>
              <input
                type="number"
                name="Credit"
                className={"form-control hide-number-spinners"}
                placeholder={t("ENTER_CREDIT")}
                onChange={(e) => {
                  if (e.target.value.length >= "10") {
                  } else if (e.target.value != "0") {
                    setCallVal(e.target.value);
                  }
                }}
                // onKeyDown={handleKeyDown}
                value={callVal}
              />
            </div>
            <div className="mt-3">
              <button
                style={{ float: "right" }}
                type="submit"
                className="btn btn_theme"
                onClick={creUpCall}
                disabled={callVal == "" || callVal.length < 1}
              >
                {isupdateCall === 0 ? `${t("CREATE")}` : `${t("UPDATE")}`}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* call modal End */}
    </>
  );
}
export default Cardrate;
