import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { getCookie } from "../utils/utils";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
const useAxios = () => {
  const { t } = useTranslation();
  const csrftoken = getCookie("csrftoken");
  const { authTokens, setUser, setAuthTokens, logoutUser } =
    useContext(AuthContext);
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authTokens?.access}`,
      "X-CSRFToken": csrftoken,
    },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    // alert();
    const user = jwt_decode(authTokens?.access);
    //
    const isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 25000;
    //
    if (!isExpired) return req;
    try {
      const response = await axios.post(`${baseURL}/vid_user/refresh_token`, {
        refresh_token: authTokens.refresh,
      });

      let data = {
        refresh: authTokens.refresh,
        access: response.data.access,
      };
      const user = jwt_decode(data.access);
      localStorage.setItem("VidsocialAppAuthTokens", JSON.stringify(data));
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      req.headers.Authorization = `Bearer ${data.access}`;
      return req;
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;
      if (data?.detail === "Subscription expired please contact system admin") {
        alert("Contract Expired");
      } else if (data?.code === "ALREADY_LOGGED_IN") {
        alert("Logout from other device and try again");
      }
      logoutUser();
    }
  });
  // useEffect(() => {
  const socket = new WebSocket("ws://192.168.1.94:8001/ws/status_update/");
  socket.onopen = () => {
    // console.log("WebSocket connected");
  };

  if (localStorage.getItem("VidsocialAppAuthTokens") != null) {
    const deCodedData = jwt_decode(
      localStorage.getItem("VidsocialAppAuthTokens")
    );
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("onmessage", data);
      if (
        data.message.ActivateStatus === false &&
        deCodedData.user_id === data.message.SponserId
      ) {
        Swal.fire({
          title: t("DEACTIVAT"),
          text: t("YOUR_ACCOUNT_IS_DEACTIVATED"),
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#5cb85c",
          confirmButtonText: t("OK"),
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            window.location.reload();
          }
        });
      }
      if (
        data.message.DeleteStatus === true &&
        deCodedData.user_id === data.message.SponserId
      ) {
        Swal.fire({
          title: t("DELETED"),
          text: t("YOUR_ACCOUNT_IS_DELETED"),
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#5cb85c",
          confirmButtonText: t("OK"),
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            window.location.reload();
          }
        });
      }
    };
  } else {
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log("onmessage", data);
    };
  }
  socket.onclose = () => {
    console.log("WebSocket disconnected");
    return () => {
      socket.close();
      console.log("Component unmounted");
    };
  };

  return axiosInstance;
};

export default useAxios;
