/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
function Prohibitedbank() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { openside, getAllProhibitedBank } = useContext(ApiContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [search, setSearch] = useState("");
  const [record, setRecord] = useState([]);
  const [datacount, setDataCount] = useState();

  useEffect(() => {
    getAllProhibitedBank(
      limit,
      page,
      setCountPage,
      setRecord,
      search,
      setDataCount
    );
  }, [limit, page, search]);

  function routeChange() {
    navigate("/Create_Prohibited_bank");
  }

  function updateSponsor(data) {
    navigate(`/Update_Prohibited_bank/${data?.countryDetail?.country_id}`);
  }

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("PROHIBITED_BANK_LIST")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <h4 className="head_card">
                {" "}
                <button className="btn btn_theme" onClick={routeChange}>
                  {t("CREATE")}
                </button>
              </h4>
              <div className="saech_des">
                <div className="left_shiowp">
                  <label>{t("SHOW_ENTRIES")}</label>
                  <select
                    className="form-control"
                    defaultValue="5"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="right_shiowp">
                  <label> {t("SEARCH")}</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("SEARCH")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd" style={{ width: "80px" }}>
                        {t("S_NO")}.
                      </th>
                      <th className="orderd" style={{ width: "80px" }}>
                        {t("NAME")}
                      </th>
                      <th className="orderd" style={{ width: "500px" }}>
                        {t("CODE")}
                      </th>

                      <th>{t("ACTION")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record.map((item, index) => (
                      <tr key={"1cc" + index}>
                        <td>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {" "}
                          {item.countryDetail.name}
                        </td>
                        <td>
                          {item.prohibitedbankcodes.map((item, index) => (
                            <span key={"v" + index} className="me-2">
                              {item},
                            </span>
                          ))}
                        </td>
                        <td>
                          <span className="btn_us3">
                            <span className="btn_us1">
                              <button
                                className="btn btn_clr"
                                data-tooltip-id="view"
                                data-tooltip-content={t(
                                  "UPDATE_PROHIBITED_BANK"
                                )}
                              >
                                <img
                                  src="img/edit.png"
                                  onClick={() => updateSponsor(item)}
                                  alt=""
                                />
                              </button>

                              <Tooltip id="view" />
                            </span>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {record.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}

              {record.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    {/* <p>Showing of {countPage === 0 ? 1 : countPage}</p> */}
                    {t("SHOWING")} {limit * page - limit + 1}
                    {t("TO")}{" "}
                    {limit > datacount
                      ? datacount
                      : page * limit > datacount
                      ? datacount
                      : page * limit}{" "}
                    {t("OF")} {datacount}
                    {t("ENTRIES")}
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p> Send Message To {userdata?.user_name}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textarea">
            <textarea
              style={{ width: "101%" }}
              value={sendvalue}
              onChange={(e) => setSendValue(e.target.value)}
              rows={4} // specify the number of rows
              cols={50} // specify the number of columns
              placeholder="Type something..."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={sendvalue === ""}
            className="btn btn_theme"
            // onClick={sendMsg}
          >
            Send Message
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
export default Prohibitedbank;
