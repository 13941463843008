/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../../Admin/CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { parseISO, format } from "date-fns";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
function MyWorkers() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [dataCount, setDataCount] = useState(1);
  const [search, setSearch] = useState("");
  const { openside, getAllClient } = useContext(ApiContext);
  const [allClient, setAllClient] = useState([]);
  const [dates, setDates] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getAllClient(
      limit,
      page,
      setLoader,
      setCountPage,
      setAllClient,
      search,
      setDataCount
    );
  }, [limit, page, search]);

  function viewWorker(item) {
    localStorage.setItem("data", JSON.stringify(item));
    navigate("/myWorkersDetails/" + item.worker_id);
  }
  const setDateRange = () => {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    if (first != "Invalid date" && second != "Invalid date") {
      getAllClient(
        limit,
        page,
        setLoader,
        setCountPage,
        setAllClient,
        search,
        setDataCount,
        first,
        second
      );
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("MY_WORKERS")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des">
                <div className="left_shiowp">
                  <label>{t("SHOW_ENTRIES")}</label>
                  <select
                    className="form-control"
                    defaultValue="10"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="calenderd1">
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    placeholder={t("SEARCH_WITH_DATE")}
                    minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                    maxDate={new Date()}
                  />

                  <button
                    className="btn btn_theme btn-sm"
                    disabled={dates == ""}
                    onClick={setDateRange}
                  >
                    {t("FIND")}
                  </button>
                </div>
                <div className="right_shiowp">
                  <label>{t("SEARCH")}</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("SEARCH")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd" style={{ width: "80px" }}>
                        {t("S_NO")}.
                      </th>
                      <th className="orderd">{t("IMAGE")}</th>
                      <th className="orderd">{t("NAME")}</th>
                      <th className="orderd">{t("WARNING")}</th>
                      <th className="orderd">{t("COUNTRY")}</th>
                      <th className="orderd">{t("REQUEST_DATE")}</th>
                      <th
                        style={{
                          width: "200px",
                        }}
                      >
                        {t("ACTION")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allClient.map((item, index) => (
                      <tr key={"1" + index}>
                        <td>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={
                              item?.worker_profile_image != null
                                ? baseURL + item?.worker_profile_image
                                : "img/woman_profile.jpg"
                            }
                            alt=""
                            className="tabl_pro_img"
                          />
                        </td>
                        <td>{item?.worker_name}</td>
                        <td>{item?.worker_total_warning}</td>
                        <td>
                          <img
                            src={
                              item?.country_flag_url != null
                                ? item?.country_flag_url
                                : ""
                            }
                            className="tab_flag"
                          />{" "}
                          {item?.country_name}
                        </td>
                        <td>
                          {format(
                            parseISO(item.start_datetime),
                            " dd MMM yyyy"
                          )}
                        </td>
                        <td>
                          <span className="btn_us1">
                            <button
                              data-tooltip-id="view"
                              data-tooltip-content={t("VIEW_DETAILS")}
                              className="btn btn_clr"
                              onClick={() => viewWorker(item)}
                            >
                              <img src="img/view.png" alt="" />
                            </button>
                            <Tooltip id="view" />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {allClient.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}

              {allClient.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    <p>
                      {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                      {limit > dataCount
                        ? dataCount
                        : page * limit > dataCount
                          ? dataCount
                          : page * limit}{" "}
                      {t("OF")} {dataCount} {t("ENTRIES")}
                    </p>
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MyWorkers;
