/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import ApiContext from "../../context/ApiContext";
import { useTranslation } from "react-i18next";
import Header from "../../Admin/CommonPage/Header";
import ReactCrop from "react-image-crop";
function Cropper() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const { openside } = useContext(ApiContext);
  const [src, setSrc] = useState(null);
  const [img, setImg] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 16 / 9,
  });
  const [fileName, setFileName] = useState("");
  const imageRef = useRef(null);
  function selectFile(e) {
    let filename = e.target.files[0].name;
    setFileName(filename);
    setSrc(URL.createObjectURL(e.target.files[0]));
  }

  function onImageLoaded() {
    setImage(imageRef.current);
    // alert("cbvjcb");
  }

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };
  async function makeClientCrop(crop) {
    if (src && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(image, crop, fileName);
      setImg(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        const croppedImageUrl = canvas.toDataURL("image/jpeg");

        resolve(croppedImageUrl);
      }, "image/jpeg");
    });
  }
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={"Cropper"} />
        <div className="container">
          <div className="row">
            <div className="col-6">
              <input type="file" accept="image/*" onChange={selectFile} />
            </div>
            <div className="col-6">
              {src && (
                <ReactCrop
                  crop={crop}
                  onComplete={onCropComplete}
                  onChange={setCrop}
                >
                  <img
                    ref={imageRef}
                    src={baseURL + src}
                    onLoad={() => onImageLoaded()}
                  />
                </ReactCrop>
              )}

              {/* <button className="btn btn-danger" onClick={saveCrop}>
                save
              </button> */}
            </div>
          </div>
          <img src={baseURL + img} />
        </div>
      </div>
    </>
  );
}
export default Cropper;
