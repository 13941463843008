/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useState } from "react";
import ApiContext from "../../context/ApiContext";
import { useEffect } from "react";
import { parseISO, format } from "date-fns";
import Header from "../../Admin/CommonPage/Header";
import { Modal, Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import $ from "jquery";
function Sponsorpaymenthistory() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    sponsorPaymentHistory,
    openside,
    getPaymentHistoryAdminToSponsor,
    DownlodImag,
  } = useContext(ApiContext);
  let [paymenthistory, setPaymenthistory] = useState([]);
  let [admHistory, setAdmHistory] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [search, setSearch] = useState("");
  const [datacount, setDataCount] = useState(0);
  // -----------------------------------------------------
  const [admlimit, setAdmLimit] = useState(10);
  const [admpage, setAdmPage] = useState(1);
  const [admcountPage, setAdmCountPage] = useState(1);
  const [admsearch, setAdmSearch] = useState("");
  const [admdatacount, setAdmDataCount] = useState(0);
  // -----------------------------------------------------
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState();
  const [dates, setDates] = useState("");
  const [admindates, setAdminDates] = useState("");
  const handleClose = () => {
    if (details.request_id != undefined) {
      $("#tb" + details.request_id).removeClass("acTive");
    } else {
      $("#tb" + details.payment_request_id).removeClass("acTive");
    }
    setShow(false);
  };
  const [active, setActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState(0);
  const handleClick = () => {
    setActive(!active);
  };
  localStorage.removeItem("data");
  useEffect(() => {
    sponsorPaymentHistory(
      limit,
      page,
      setLoader,
      setCountPage,
      setPaymenthistory,
      search,
      setDataCount
    );
  }, [page, limit, search]);

  useEffect(() => {
    getPaymentHistoryAdminToSponsor(
      admlimit,
      admpage,
      setAdmCountPage,
      setAdmHistory,
      admsearch,
      setAdmDataCount
    );
  }, [admpage, admlimit, admsearch]);

  const seeReceipt = (item, type) => {
    if (type == 0) {
      $("#tb" + item.request_id).addClass("acTive");
    } else {
      $("#tb" + item.payment_request_id).addClass("acTive");
    }
    setDetails(item);
    setShow(true);
  };

  function viewPage(params) {
    let data = JSON.stringify(params);
    localStorage.setItem("data", data);
    navigate(
      `/sponsorPaymentDetails/${params.sponser_id}/${params.payment_request_id}`
    );
  }

  const setDateRange = (type) => {
    const first = moment(type == 0 ? dates[0] : admindates[0]).format(
      "YYYY-MM-DD"
    );
    const second = moment(type == 0 ? dates[1] : admindates[1]).format(
      "YYYY-MM-DD"
    );
    if (first != "Invalid date" && second != "Invalid date") {
      if (type == 0) {
        sponsorPaymentHistory(
          limit,
          page,
          setLoader,
          setCountPage,
          setPaymenthistory,
          search,
          setDataCount,
          first,
          second
        );
      } else {
        getPaymentHistoryAdminToSponsor(
          admlimit,
          admpage,
          setAdmCountPage,
          setAdmHistory,
          admsearch,
          setAdmDataCount,
          first,
          second
        );
      }
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  };

  const changeTab = (e) => {
    if (e == "AdminPaymentHistory") {
      setType(1);
    } else {
      setType(0);
    }
  };

  const downloadImage = (imageURL) => {
    DownlodImag(imageURL);
  };
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("PAYMENT_HISTORY")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <Tabs
              defaultActiveKey="WorkerPaymentHistory"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e) => changeTab(e)}
            >
              <Tab
                eventKey="WorkerPaymentHistory"
                title={t("WORKER_PAYMENT_HISTORY")}
              >
                <div className="card_body">
                  <div className="saech_des">
                    <div className="left_shiowp">
                      <label>{t("SHOW_ENTRIES")}</label>
                      {/* pagination */}
                      <select
                        className="form-control"
                        defaultValue="10"
                        onChange={(e) => {
                          e.stopPropagation();
                          setLimit(e.target.value);
                          setPage(1);
                          // getCustomer()
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      {/* pagination */}
                    </div>
                    <div className="calenderd1">
                      <Calendar
                        value={dates}
                        onChange={(e) => setDates(e.value)}
                        selectionMode="range"
                        readOnlyInput
                        placeholder={t("SEARCH_WITH_DATE")}
                        minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                        maxDate={new Date()}
                      />

                      <button
                        className="btn btn_theme btn-sm"
                        disabled={dates == ""}
                        onClick={() => setDateRange(0)}
                      >
                        {t("FIND")}
                      </button>
                    </div>
                    <div className="right_shiowp">
                      <label>{t("SEARCH")}</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("SEARCH")}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="orderd">{t("S_NO")}.</th>
                          <th className="orderd">{t("WORKER_NAME")}</th>
                          <th className="orderd">{t("EMAIL")}</th>
                          <th className="orderd">{t("DATE")}</th>
                          <th className="orderd">{t("BANK_ACCOUNT_NO")}</th>
                          <th className="orderd">{t("BINANCE_ID")}.</th>
                          <th className="orderd">{t("COUNTRY")}</th>
                          <th className="orderd">{t("PAYMENT_METHED")}</th>
                          {/* <th className="orderd">{t("SEARCH")}Binance Wallet Id</th> */}
                          <th className="orderd">{t("AMOUNT")}</th>
                          <th className="orderd">{t("ACTION")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymenthistory.map((item, index) => (
                          <tr key={"TR" + index} id={"tb" + item.request_id}>
                            <td>
                              {page > 1
                                ? limit * page - limit + index + 1
                                : index + 1}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {item?.worker_name}
                            </td>
                            <td>{item?.worker_email}</td>
                            <td>
                              {" "}
                              {format(
                                parseISO(item.request_date),
                                "dd MMM yyyy,HH:mm"
                              )}
                              {/* {item.request_date} */}
                            </td>
                            <td>
                              {item?.bank_account_id != undefined
                                ? item?.bank_account_id
                                : "-"}
                            </td>
                            <td>
                              {item?.binance_account_id != undefined
                                ? item?.binance_account_id
                                : "-"}
                            </td>
                            <td>
                              <img
                                src={
                                  item?.worker_country_flag_url != undefined
                                    ? item?.worker_country_flag_url
                                    : ""
                                }
                                className="tab_flag"
                              />{" "}
                              {item?.worker_country_name === undefined
                                ? "-"
                                : item?.worker_country_name}
                            </td>
                            <td>{item.active_payment_method}</td>
                            <td>{"$" + item?.amount}</td>
                            <td>
                              <span className="btn_us1">
                                <button
                                  className="btn btn_clr"
                                  data-tooltip-id="view"
                                  data-tooltip-content={t("VIEW_RECEIPT")}
                                  onClick={() => seeReceipt(item, 0)}
                                >
                                  <img src="img/view.png" alt="" />
                                </button>
                              </span>
                              <Tooltip id="view" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {paymenthistory.length == 0 && (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "15px",
                      }}
                    >
                      <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                    </div>
                  )}

                  {paymenthistory.length != 0 && (
                    <div className="pag_botm">
                      <div className="left_neu">
                        <p>
                          {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                          {limit > datacount
                            ? datacount
                            : page * limit > datacount
                              ? datacount
                              : page * limit}{" "}
                          {t("OF")} {datacount}
                          {t("ENTRIES")}
                        </p>
                      </div>
                      <div className="pagddination">
                        <button
                          className="btn prew_1"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (page > 1) {
                              setPage(page - 1);
                            }
                          }}
                        >
                          {t("PREVIOUS")}
                        </button>{" "}
                        <input
                          className="form-control"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={page}
                          readOnly
                        />{" "}
                        <button
                          className="btn next_bth"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (countPage > page) {
                              setPage(page + 1);
                            }
                          }}
                        >
                          {t("NEXT")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="AdminPaymentHistory"
                title={t("ADMIN_PAYMENT_HISTORY")}
              >
                <div className="card_body">
                  <div className="saech_des">
                    <div className="left_shiowp">
                      <label>{t("SHOW_ENTRIES")}</label>
                      {/* pagination */}
                      <select
                        className="form-control"
                        defaultValue="10"
                        onChange={(e) => {
                          e.stopPropagation();
                          setAdmLimit(e.target.value);
                          setAdmPage(1);
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      {/* pagination */}
                    </div>
                    <div className="calenderd1">
                      <Calendar
                        value={admindates}
                        onChange={(e) => setAdminDates(e.value)}
                        selectionMode="range"
                        readOnlyInput
                        placeholder={t("SEARCH_WITH_DATE")}
                        minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                        maxDate={new Date()}
                      />

                      <button
                        className="btn btn_theme btn-sm"
                        disabled={admindates == ""}
                        onClick={() => setDateRange(1)}
                      >
                        {t("FIND")}
                      </button>
                    </div>
                    <div className="right_shiowp">
                      <label>{t("SEARCH")}</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("SEARCH")}
                        onChange={(e) => setAdmSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="orderd">{t("S_NO")}.</th>
                          <th className="orderd">{t("REQUEST_ID")}</th>
                          <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                          <th className="orderd">{t("SPONSOR_PERCENTAGE")}</th>
                          <th className="orderd">
                            {t("TOTAL_RECIVED_AMOUNT")}
                          </th>
                          {/* <th className="orderd">{t("SHOW_ENTRIES")}Bank Account Id</th>
                          <th className="orderd">{t("SHOW_ENTRIES")}Binance Id</th> */}
                          <th className="orderd">{t("DATE")}</th>
                          <th className="orderd">{t("STATUS")}</th>
                          <th className="orderd">{t("ACTION")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admHistory.map((item, index) => (
                          <tr
                            key={"TR" + index}
                            onClick={handleClick}
                            id={"tb" + item.payment_request_id}
                          >
                            <td>
                              {admpage > 1
                                ? admlimit * admpage - admlimit + index + 1
                                : index + 1}
                            </td>
                            <td>{item.payment_request_id}</td>
                            <td>{"$" + item?.total_amount_request}</td>
                            <td>
                              {"$" +
                                Math.floor(item?.total_amount_request * 0.1)}
                            </td>
                            <td>
                              {"$" +
                                Math.floor(
                                  +item?.total_amount_request +
                                  item?.total_amount_request * 0.1
                                )}
                            </td>
                            {/* <td>
                              {item?.sponser_bank_acc_id != null
                                ? item?.sponser_bank_acc_id
                                : "-"}
                            </td> */}
                            {/* <td>{item.sponser_binance_acc_id}</td> */}
                            <td>
                              {format(
                                parseISO(item.create_date_time),
                                "dd MMM yyyy,HH:mm"
                              )}
                            </td>
                            <td
                              style={{
                                color: "green",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.request_status}
                            </td>
                            <td>
                              <span className="btn_us1">
                                <button
                                  className="btn btn_clr"
                                  data-tooltip-id="view"
                                  data-tooltip-content={t("VIEW_RECEIPT")}
                                  onClick={() => seeReceipt(item, 1)}
                                >
                                  <img src="img/view.png" alt="" />
                                </button>
                              </span>
                              <span className="btn_us1">
                                <button
                                  className="btn btn_clr"
                                  data-tooltip-id="view"
                                  data-tooltip-content={t("VIEW_DETAILS")}
                                  onClick={() => viewPage(item)}
                                >
                                  <img src="img/pay.png" alt="" />
                                </button>
                              </span>
                              <Tooltip id="view" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {admHistory.length == 0 && (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "15px",
                      }}
                    >
                      <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                    </div>
                  )}

                  {admHistory.length != 0 && (
                    <div className="pag_botm">
                      <div className="left_neu">
                        <p>
                          {t("SHOWING")} {admlimit * admpage - admlimit + 1}{" "}
                          {t("TO")}{" "}
                          {admlimit > admdatacount
                            ? admdatacount
                            : admpage * admlimit > admdatacount
                              ? admdatacount
                              : admpage * admlimit}{" "}
                          {t("OF")} {admdatacount}
                          {t("ENTRIES")}
                        </p>
                      </div>
                      <div className="pagddination">
                        <button
                          className="btn prew_1"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (admpage > 1) {
                              setAdmPage(admpage - 1);
                            }
                          }}
                        >
                          {t("PREVIOUS")}
                        </button>{" "}
                        <input
                          className="form-control"
                          type="text"
                          style={{ textAlign: "center" }}
                          value={admpage}
                          readOnly
                        />{" "}
                        <button
                          className="btn next_bth"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (admcountPage > admpage) {
                              setAdmPage(admpage + 1);
                            }
                          }}
                        >
                          {t("NEXT")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("VIEW_RECEIPT_OF")}{" "}
            {type == 0
              ? details?.worker_name
              : t("REQUEST_ID") + " (" + details?.payment_request_id + ")"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type == 0 && (
            <div className="list_desig">
              <ul className="ul_set">
                <li>
                  <b>{t("NAME")} : </b>
                  {details?.worker_name}
                </li>
                <li>
                  <b>{t("METHOD")} : </b>
                  {details?.active_payment_method}
                </li>

                {details?.request_date != undefined ? (
                  <li>
                    <b> {t("REQUEST_DATE")} : </b>
                    {format(parseISO(details?.request_date), "dd MMM yyyy")}
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <b>{t("EMAIL")} : </b>
                  {details?.worker_email}
                </li>
                <li>
                  <b>{t("AMOUNT")} : </b>${details?.amount}
                </li>
                {details?.paid_date != undefined ? (
                  <li>
                    <b> {t("RECIVED_DATE")} : </b>
                    {format(parseISO(details?.paid_date), "dd MMM yyyy")}
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          )}
          {type == 1 && (
            <div className="list_desig">
              <ul className="ul_set">
                <li>
                  <b>{t("REQUEST_ID")} : </b>
                  {details?.payment_request_id}
                </li>
                <li>
                  <b>{t("METHOD")} : </b>
                  {details?.active_payment_method}
                </li>

                {details?.create_date_time != undefined ? (
                  <li>
                    <b> {t("REQUEST_DATE")} : </b>
                    {format(parseISO(details?.create_date_time), "dd MMM yyyy")}
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <b>{t("AMOUNT")} : </b>${details?.total_amount_request}
                </li>
                {details?.paid_date != undefined ? (
                  <li>
                    <b> {t("RECIVED_DATE")} : </b>
                    {format(parseISO(details?.paid_date), "dd MMM yyyy")}
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="down_respt">
                <img
                  className="img_r"
                  src={
                    details?.receipts_image != undefined
                      ? baseURL + details?.receipts_image
                      : "./public/img/image_n.png"
                  }
                  alt=""
                />

                {type == 0 && <h4>{t("SPONSOR_RECEIPT")} </h4>}
                {type == 1 && <h4>{t("RECEIPT")} </h4>}
                <button
                  className="btn"
                  onClick={() => downloadImage(baseURL + details?.receipts_image)}
                >
                  <i class="uil uil-import"></i>
                </button>
              </div>
            </div>
            {type == 0 && (
              <div className="col-md-6">
                <div className="down_respt">
                  <img
                    className="img_r"
                    src={
                      details?.admin_receipt != undefined
                        ? baseURL + details?.admin_receipt
                        : "./public/img/image_n.png"
                    }
                    alt=""
                  />

                  <h4>{t("ADMIN_RECEIPT")} </h4>

                  <button
                    className="btn"
                    onClick={() => downloadImage(baseURL + details?.admin_receipt)}
                  >
                    <i class="uil uil-import"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default Sponsorpaymenthistory;
