/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import ApiContext from "../../context/ApiContext";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Viewworkrdetails() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routeId = useParams();
  const { workerDetailsById, varifyWorker, openside } = useContext(ApiContext);
  const [worderDetails, setworderDetails] = useState([]);
  // const [sideid, setSideId] = useState("");
  const [isccheck, setIscheck] = useState(false);
  const [reson, setReson] = useState("");
  const [workerid, setWorkerId] = useState({});
  const [show, setShow] = useState(false);
  const [viewImage, setviewImage] = useState(false);

  const handleClose = () => {
    setReson("");
    setShow(false);
  };
  const modalClose = () => {
    setviewImage(false);
  };

  useEffect(() => {
    workerDetailsById(routeId.id, setworderDetails);
    // setSideId(localStorage.getItem("sideId"));
  }, []);

  function verifiy(type, id) {
    let data = { worker_id: id, status: type };
    Swal.fire({
      title: t("ARE_YOU_SURE"),
      text: t("YOU_WANT_TO_APPROVED_THIS_WORKER"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      cancelButtonColor: "#d33",
      confirmButtonText: t("YES"),
      cancelButtonText: t("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        varifyWorker(data, "", afterApporveDecline);
      }
    });
  }
  function declineWorker() {
    let data = {
      worker_id: workerid,
      status: "decline",
      reason_of_declination_identity: reson,
    };
    varifyWorker(data, handleClose, afterApporveDecline);
  }

  function openResonModal(type, id) {
    setWorkerId(id);
    setShow(true);
  }

  function afterApporveDecline() {
    workerDetailsById(routeId.id, setworderDetails);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
  };
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("VIEW_WORKER_REQUEST_DETAILS")} />
        <div className="wrap_us conten_set">
          <div className="profile_d1 virefied">
            <div className="row">
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="vide_img"
                      onClick={() => setviewImage(true)}
                    >
                      {/* <h3>Image</h3> */}
                      <h3>{t("CLICK_TO_VIEW_IMAGE")}</h3>
                      {worderDetails.id_image ? (
                        <img src={baseURL + worderDetails.id_image} />
                      ) : (
                        <img src={"/img/woman_profile.jpg"} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="vide_img">
                      <h3>{t("VIDEO")}</h3>
                      {worderDetails.video_link ? (
                        <ReactPlayer
                          url={baseURL + worderDetails.video_link}
                          controls={true}
                        />
                      ) : (
                        <img src={"/img/video_n.png"} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="card_me">
                  <div className="card_body">
                    <ul className="ul_set">
                      <li>
                        <span>{t("WORKER_NAME")} </span>{" "}
                        {worderDetails.first_name}{" "}
                      </li>
                      <li>
                        <span>{t("EMAIL")}</span> {worderDetails.email}{" "}
                      </li>
                      <li>
                        <span>{t("PHONE")}</span> {worderDetails.phone}{" "}
                      </li>
                      <li>
                        <span>{t("GENDER")}</span> {worderDetails.gender}{" "}
                      </li>
                      <li>
                        <span>{t("IDENTITY_NO")}.</span>{" "}
                        {worderDetails.identity_no}{" "}
                      </li>
                      <li>
                        <span>{t("DOB")}</span>
                        {formatDate(worderDetails?.date_of_birth)}{" "}
                      </li>
                      <li>
                        <span>{t("REQUEST_DATE")}</span>
                        {formatDate(worderDetails?.date_joined)}{" "}
                      </li>
                      <li style={{ textTransform: "capitalize" }}>
                        <span> {t("STATUS")} </span>{" "}
                        {worderDetails.account_approval_state == "none"
                          ? "pending"
                          : worderDetails.account_approval_state}{" "}
                      </li>

                      {worderDetails.reason_of_declination_identity != null && (
                        <li style={{ textTransform: "capitalize" }}>
                          <span>{t("DECLINE_REASON")} </span>{" "}
                          <p>{worderDetails.reason_of_declination_identity} </p>
                        </li>
                      )}
                    </ul>
                    <br></br>
                    {worderDetails.account_approval_state == "none" ? (
                      <div className="btn_btn4 text-end">
                        <button
                          className="btn btn_theme"
                          onClick={() => verifiy("approve", routeId.id)}
                        >
                          {t("VERIFY")}
                        </button>
                        <button
                          className="btn btn_theme3"
                          onClick={() => openResonModal("decline", routeId.id)}
                          // onClick={() => verifiy("decline", routeId.id)}
                        >
                          {t("DECLINE")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("RESON_FOR_DECLINE")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textarea">
            <textarea
              style={{ width: "101%" }}
              value={reson}
              onChange={(e) => setReson(e.target.value)}
              rows={4}
              cols={50}
              placeholder={t("TYPE_RESON")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn_theme"
            disabled={reson == ""}
            onClick={declineWorker}
          >
            {t("DECLINE")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={viewImage} onHide={modalClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>View Receipt of {details?.sponser_name}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{ height: " 497px", width: "433px", maxHeight: "450px" }}
              className="img_r"
              src={
                worderDetails?.id_image != undefined
                  ? baseURL + worderDetails?.id_image
                  : "/img/woman_profile.jpg"
              }
              alt=""
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default Viewworkrdetails;
