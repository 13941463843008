/* eslint-disable react-hooks/rules-of-hooks */
import React, { Suspense, lazy, useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, json } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { AppProvider } from "../context/AppContext";
import AuthContext, { AuthProvider } from "../context/AuthContext";
import Dashboard from "../Admin/Dashboard/Dashboard";
import Login from "../Admin/login/Login";
import NotFoundComponent from "../Admin/not-found/NotFoundComponent";
import Sponsor from "../Admin/Sponsor/Sponsor";
import Createsponsor from "../Admin/Sponsor/Createsponsor";
import Updatesponsor from "../Admin/Sponsor/Updatesponsor";
import Warning from "../Admin/Report/Warning";
import Reports from "../Admin/Report/Reports";
import Paymentrequest from "../Admin/Payment/Paymentrequest";
import Paymentrecived from "../Admin/Payment/Paymentrecived";
import Paymenthistory from "../Admin/Payment/Paymenthistory";
import Cardrate from "../Admin/CardRate/Cardrate";
import Bestworker from "../Admin/BestWorker/Bestworker";
// import Warning from "../Admin/Warning/Warning";
import Supportandhelp from "../Admin/SupportAndHelp/Supportandhelp";
import { ApiProvider } from "../context/ApiContext";
import Viewsponsordetails from "../Admin/Sponsor/Viewsponsordetails";
import Workerlist from "../Admin/BestWorker/WorkerList";
import Viewworkrdetails from "../Admin/BestWorker/Viewworkrdetails";
import Faq from "../Admin/FAQ/Faq";
import Createfaq from "../Admin/FAQ/Createfaq";
import Updatefaq from "../Admin/FAQ/Updatefaq";

// Sponsor Routes
import MyWorkers from "../Sponsor/MyWorkers/MyWorkers";
import SponsorDashboard from "../Sponsor/SponsorDashboard/SponsorDashboard";
import Sponsorshiprequest from "../Sponsor/SponsorshipRequest/Sponsorshiprequest";
import Users from "../Admin/Users/Users";
import SendPaymentRequest from "../Sponsor/SendPaymentRequest/SendPaymentRequest";
import WorkerPaymentRequest from "../Sponsor/WorkerPaymentRequest/WorkerPaymentRequest";
import Viewdetailspayment from "../Admin/Payment/Viewdetailspayment";
import Prohibitedbank from "../Admin/ProhibitedBank/Prohibitedbank";
import Createprohibited from "../Admin/ProhibitedBank/Createprohibited";
import Updateprohibited from "../Admin/ProhibitedBank/Updateprohibited";
import Viewuser from "../Admin/Users/Viewuser";
import Viewrorker from "../Admin/Users/Viewrorker";
import Sponsorpaymenthistory from "../Sponsor/SendPaymentRequest/Sponsorpaymenthistory";
import Myworkerdetails from "../Sponsor/MyWorkers/Myworkerdetails";
import Viewpaymentdetails from "../Sponsor/SendPaymentRequest/Viewpaymentdetails";
import Sponsorreport from "../Sponsor/SponsorReports/Sponsorreport";
import Binance from "../Sponsor/BinanceAccount/Binance";
import Sponsorwarning from "../Sponsor/MyWarning/Sponsorwarning";
import Cropper from "../Sponsor/BinanceAccount/Cropper";

const index = () => {
  let check = localStorage.getItem("authenticationrol");
  check = JSON.parse(check);

  return (
    <Router>
      <AuthProvider>
        <AppProvider>
          {/* <FormProvider> */}
          <ApiProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Login title="Login" />
                  </Suspense>
                }
              />
              <Route
                path="/dashboard_Admin"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Dashboard title="Dashboard" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/sponsor"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Sponsor title="Sponsor" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/createsponsor"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Createsponsor title="Createsponsor" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/updatesponsor/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Updatesponsor title="updatesponsor" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Warning"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Warning title="Warning" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/paymentrequest"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Paymentrequest title="Payment Request" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Users title="Users" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/paymentreceived"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Paymentrecived title=" Payment Received" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/paymenthistory"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Paymenthistory title=" Payment History" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/cardrate"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Cardrate title=" Card Rate" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/bestworker"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Bestworker title="bestworker" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/Supportandhelp"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Supportandhelp title="Support and help" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/view_user_Details/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Viewuser title="View User" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/view_Worker_Details/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Viewrorker title="View User" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Viewsponsordetails/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Viewsponsordetails title="Viewsponsor Details" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Workerlist"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Workerlist title="Workerlist" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/viewworkrdetails/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Viewworkrdetails title="View workr details" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Faq"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Faq title="FAQ" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Create_faq"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Createfaq title="Create Faq" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Update_faq/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Updatefaq title="Update Faq" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/Prohibited_bank"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Prohibitedbank title=" Prohibited bank" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Create_Prohibited_bank"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Createprohibited title="Create Prohibited bank" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Update_Prohibited_bank/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Updateprohibited title="Create Prohibited bank" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Viewdetailspaymentbyid/:id/:id1"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {/* {check === 1 || check == null ? ( */}
                      <Viewdetailspayment title="View Details Payment" />
                      {/* ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )} */}
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Reports"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      {check === 1 || check == null ? (
                        <Reports title="View Details Payment" />
                      ) : (
                        <SponsorDashboard title="Sponsor Dashboard" />
                      )}
                    </Suspense>
                  </PrivateRoute>
                }
              />

              {/* sponsor page routing */}
              <Route
                path="/SponsorDashboard"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <SponsorDashboard title="Sponsor Dashboard" />
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/myWorkers"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MyWorkers title="My Workers" />
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/myWorkersDetails/:id"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Myworkerdetails title="My Workers" />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/Sponsorshiprequest"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Sponsorshiprequest title="Sponsorship Request" />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/SendPaymentRequest"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <SendPaymentRequest title="Send Payment Request" />
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/WorkerPaymentRequest"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <WorkerPaymentRequest title="Worker Payment Request" />
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/sponsorPaymentHistory"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Sponsorpaymenthistory title="Worker Payment History" />
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/sponsorPaymentDetails/:id/:id1"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Viewpaymentdetails title="View Payment Details" />
                    </Suspense>
                  </PrivateRoute>
                }
              />

              <Route
                path="/sponsor_reports"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Sponsorreport title="Sponsor Reports" />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/sponsor_warning"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Sponsorwarning title="Sponsor Warning" />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/binance_Account"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Binance title="Binance" />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="/image"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Cropper title="bhbjhb" />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFoundComponent />} />
            </Routes>
          </ApiProvider>
          {/* </FormProvider> */}
        </AppProvider>
      </AuthProvider>
    </Router>
  );
};

export default index;
