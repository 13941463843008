/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
// import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
function Updatefaq() {
  const { t } = useTranslation();
  const params = useParams();
  const { openside, updateFaq, getFAQById } = useContext(ApiContext);
  const [faqDetails, setFaqDetails] = useState();
  useEffect(() => {
    getFAQById(setFaqDetails, params.id);
    formik.setFieldValue("question", faqDetails?.question);
    formik.setFieldValue("answer", faqDetails?.answer);
  }, [faqDetails === undefined]);
  const validationSchema = () =>
    Yup.object().shape({
      question: Yup.string().required(`${t("QUESTION_IS_REQUIRED")}`),
      answer: Yup.string().required(`${t("ANSWER_IS_REQUIRED")}`),
    });
  const quesTion = (value) => {
    value === "<p><br></p>"
      ? formik.setFieldValue("question", "")
      : formik.setFieldValue("question", value);
  };
  const anSwer = (value) => {
    value === "<p><br></p>"
      ? formik.setFieldValue("answer", "")
      : formik.setFieldValue("answer", value);
  };
  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema,
    onSubmit: (data) => {
      updateFaq(params.id, data);
    },
  });

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("UPDATE_FAQ")} />
        <div className="wrap_us conten_set">
          <div className="row">
            <div className="col-md-10">
              <form onSubmit={formik.handleSubmit}>
                <div className="card_me">
                  <div className="card_body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label>{t("QUESTION")}*</label>
                          <ReactQuill
                            theme="snow"
                            name="question"
                            placeholder={t("ENTER_QUESTION")}
                            className={
                              formik.errors.question && formik.touched.question
                                ? " is-invalid"
                                : ""
                            }
                            value={formik.values.question}
                            onChange={quesTion}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.question && formik.touched.question
                              ? formik.errors.question
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label>{t("ANSWER")}*</label>
                          <ReactQuill
                            theme="snow"
                            name="answer"
                            placeholder={t("ENTER_ANSWER")}
                            className={
                              formik.errors.answer && formik.touched.answer
                                ? " is-invalid"
                                : ""
                            }
                            value={formik.values.answer}
                            onChange={anSwer}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.answer && formik.touched.answer
                              ? formik.errors.answer
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn_theme btn-lg">
                        {t("UPDATE_FAQ")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Updatefaq;
