/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import { Tooltip } from "react-tooltip";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";

function Sponsor() {
  const { getAllSponsor, activeDeactivaeSponsor, openside } =
    useContext(ApiContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [datacount, setDataCount] = useState();
  useEffect(() => {
    localStorage.removeItem("data");
  }, []);
  useEffect(() => {
    getAllSponsor(
      limit,
      page,
      setDataCount,
      setCountPage,
      setRecords,
      search,
      setLoader
    );
  }, [limit, page, search]);

  function create() {
    navigate("/createsponsor");
  }

  function updateSponsor(id, data) {
    localStorage.setItem("data", JSON.stringify(data));
    navigate("/updatesponsor/" + id);
  }

  function activate(status, id, i) {
    let msg =
      status === false
        ? t("SPONSOR_DEACTIVATED_SUCCESSFULLY")
        : t("SPONSOR_ACTIVATED_SUCCESSFULLY");

    activeDeactivaeSponsor(msg, id);
  }

  function viewPage(item) {
    localStorage.setItem("data", JSON.stringify(item));
    navigate("/Viewsponsordetails/" + item.id);
  }

  return (
    <div className={`main_slide ${openside ? "open_slider" : ""}`}>
      {loader && (
        <div className="loaderr2">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Header name={t("SPONSOR_LIST")} />
      <div className="wrap_us conten_set">
        <div className="card_me table_des1">
          <div className="card_body">
            <h4 className="head_card">
              {" "}
              <button className="btn btn_theme" onClick={create}>
                {t("CREATE_SPONSOR")}
              </button>
            </h4>
            <div className="saech_des">
              <div className="left_shiowp">
                <label>{t("SHOW_ENTRIES")}</label>
                {/* pagination */}
                <select
                  className="form-control"
                  defaultValue="10"
                  onChange={(e) => {
                    e.stopPropagation();
                    setLimit(e.target.value);
                    setPage(1);
                    // getCustomer()
                  }}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>

                {/* pagination */}
              </div>
              <div className="right_shiowp">
                <label>{t("SEARCH")}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("SEARCH")}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="ttt">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd">{t("S_NO")}.</th>
                      <th className="orderd">{t("NAME")}</th>
                      <th className="orderd text-center">{t("EMAIL")}</th>
                      <th className="orderd text-center">{t("COUNTRY")}</th>
                      <th className="orderd">{t("LANGUAGE")}</th>
                      <th
                        className="orderd text-center"
                        style={{
                          width: "200px",
                        }}
                      >
                        {t("ACTIVATE_DEACTIVATE")}
                      </th>
                      <th>{t("JOINING_DATE")}</th>
                      <th>{t("ACTION")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item, index) => (
                      <tr key={"te" + index}>
                        <td>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td>{item?.display_name}</td>
                        <td>{item?.email}</td>
                        <td>
                          <img
                            src={item.country_flag_url}
                            className="tab_flag"
                          />{" "}
                          {item?.country_name}
                        </td>
                        <td>
                          {" "}
                          {item.language == "en" ? "English" : "Spanish"}
                        </td>
                        <td>
                          <div className="switchh">
                            <input
                              data-tooltip-id="view"
                              data-tooltip-content={
                                item.login_permission
                                  ? `${t("DEACTIVAT")}`
                                  : `${t("ACTIVATED")}`
                              }
                              type="checkbox"
                              defaultChecked={item.login_permission === true}
                              onChange={(e) =>
                                activate(e.target.checked, item.id, index)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          {" "}
                          {format(parseISO(item.date_joined), "dd MMM yyyy")}
                        </td>
                        <td>
                          <span className="btn_us1">
                            <button
                              className="btn btn_clr"
                              data-tooltip-id="view"
                              data-tooltip-content={t("UPDATE_SPONSOR")}
                            >
                              <img
                                src="img/edit.png"
                                onClick={() => updateSponsor(item.id, item)}
                                alt=""
                              />
                            </button>
                            <button
                              data-tooltip-id="view"
                              data-tooltip-content={t("VIEW_DETAILS")}
                              className="btn btn_clr"
                              onClick={() => viewPage(item)}
                            >
                              <img src="img/view.png" alt="" />
                            </button>
                            <Tooltip id="view" />
                          </span>
                        </td>
                      </tr>
                    ))}

                    {/* {records.length === 0 ?
                   (<span>Data not avilable</span>  ) :( "")} */}
                  </tbody>
                </table>
              </div>
              {records.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}
              {records.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    <p>
                      {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                      {limit > datacount
                        ? datacount
                        : page * limit > datacount
                        ? datacount
                        : page * limit}{" "}
                      {t("OF")} {datacount} {t("ENTRIES")}
                    </p>
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      value={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Sponsor;
