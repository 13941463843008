/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { toast } from "react-toastify";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
function Reports() {
  const { t } = useTranslation();
  const { openside, adminReports, DownloadExl } = useContext(ApiContext);
  const [initialized, setInitialized] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(1);
  const [search, setSearch] = useState("");
  const [reportName, setReportName] = useState("");
  const [dates, setDates] = useState("");
  const [record, setRecord] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // This function will be called after count state updates
    if (initialized) {
      loadData();
    } else {
      // Set initialized to true after the initial render to avoid calling the function
      setInitialized(true);
    }
  }, [limit, page]);

  function loadData() {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    let data = {
      start_date: first,
      end_date: second,
      key: reportName,
      page: page,
      limit: limit,
    };
    if (first != "Invalid date" && second != "Invalid date") {
      adminReports(
        data,
        limit,
        page,
        setLoader,
        setRecord,
        setCountPage,
        setCountData,
        search
      );
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  }

  function downloadData() {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    let data = {
      start_date: first,
      end_date: second,
      key: reportName,
    };
    if (first != "Invalid date" && second != "Invalid date") {
      DownloadExl(data);
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  }
  const chnageReport = (value) => {
    setReportName(value);
    setRecord([]);
    setLimit(10);
    setPage(1);
    setCountData(1);
    setCountPage(1);
  };
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("REPORTS")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des reports_search">
                <div>
                  <select
                    style={{
                      width: "250px",
                    }}
                    value={reportName}
                    className="form-control"
                    onChange={(e) => chnageReport(e.target.value)}
                  >
                    <option value="" disabled>
                      {t("SELECT_OPTION")}
                    </option>
                    <option value="revenue">{t("REVENUE")}</option>
                    <option value="worker_request">
                      {t("WORKER_REQUEST")}
                    </option>
                    <option value="payment_request">
                      {t("PAYMENT_REQUEST")}
                    </option>
                  </select>
                </div>
                <div className="calenderd1">
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    placeholder={t("SEARCH_WITH_DATE")}
                    minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                    maxDate={new Date()}
                  />
                  <button
                    className="btn btn_theme"
                    disabled={dates == "" || reportName == ""}
                    onClick={loadData}
                  >
                    {t("LOAD")}
                  </button>
                </div>
                <div className="">
                  {""}
                  <button
                    className="btn btn_theme"
                    onClick={downloadData}
                    disabled={dates == "" || reportName == ""}
                  >
                    {t("DOWNLOAD")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {reportName != "" && (
          <div className="wrap_us conten_set">
            <div className="card_me table_des1">
              <div className="card_body">
                <div className="saech_des">
                  <div className="left_shiowp">
                    <label>{t("SHOW_ENTRIES")}</label>
                    <select
                      className="form-control"
                      defaultValue="5"
                      onChange={(e) => {
                        e.stopPropagation();
                        setLimit(e.target.value);
                        setPage(1);
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="right_shiowp">
                    <label>{t("SEARCH")}</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("SEARCH")}
                      onChange={(e) => setSearch(e.target.value)}
                    />{" "}
                  </div>
                </div>

                {reportName == "revenue" && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="orderd">{t("S_NO")}.</th>
                          <th className="orderd">{t("USER_NAME")}</th>
                          <th className="orderd">{t("EMAIL")}</th>
                          <th className="orderd">{t("RECIVED_DATE")}</th>
                          <th className="orderd">{t("PAYMENT_ID")}</th>
                          <th className="orderd">{t("COUNTRY")}</th>
                          <th className="orderd">{t("AMOUNT")}</th>
                          <th className="orderd">{t("PURCHASE_CREDIT")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {record.map((item, index) => (
                          <tr key={"1" + index}>
                            <td>
                              {page > 1
                                ? limit * page - limit + index + 1
                                : index + 1}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {item?.user_name != undefined
                                ? item?.user_name
                                : "-"}
                            </td>
                            <td>{item?.user_email}</td>
                            <td>
                              {" "}
                              {format(
                                parseISO(item.create_date_time),
                                "dd MMM yyyy"
                              )}
                            </td>
                            <td>
                              {item?.payment_transaction_id != null
                                ? item?.payment_transaction_id
                                : "-"}
                            </td>
                            <td>
                              <img
                                src={
                                  item?.user_country_flag != undefined
                                    ? item?.user_country_flag
                                    : ""
                                }
                                className="tab_flag"
                              />{" "}
                              {item?.user_country_name === undefined
                                ? "-"
                                : item?.user_country_name}
                            </td>
                            <td>{"$" + item?.amount}</td>
                            <td>{item.coin_alloted}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {reportName == "worker_request" && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="orderd">{t("S_NO")}.</th>
                          <th className="orderd">{t("NAME")}</th>
                          <th className="orderd">{t("EMAIL")}</th>
                          <th className="orderd">{t("COUNTRY")}</th>
                          <th className="orderd">{t("JOINING_DATE")}</th>
                          <th className="orderd">{t("STATUS")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {record.map((item, index) => (
                          <tr key={"1" + index}>
                            <td>
                              {page > 1
                                ? limit * page - limit + index + 1
                                : index + 1}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {" "}
                              {item.display_name}
                            </td>
                            <td>{item.email}</td>
                            <td>
                              <img
                                src={
                                  item?.country_flag != undefined
                                    ? item?.country_flag
                                    : ""
                                }
                                className="tab_flag"
                              />{" "}
                              {item?.country_name === undefined
                                ? "-"
                                : item?.country_name}
                            </td>
                            <td>
                              {format(
                                parseISO(item.date_joined),
                                "dd MMM yyyy"
                              )}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {item.account_approval_state == "none"
                                ? "Pending"
                                : item.account_approval_state}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {reportName == "payment_request" && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="orderd">{t("S_NO")}.</th>
                          <th className="orderd">{t("SPONSOR_NAME")}</th>
                          <th className="orderd">{t("EMAIL")}</th>
                          <th className="orderd">
                            {t("PAYMENT_REQUEST_DATE")}
                          </th>
                          <th className="orderd">{t("WALLET_ADDRESS")}</th>
                          <th className="orderd">{t("COUNTRY")}</th>
                          <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {record.map((item, index) => (
                          <tr key={"1" + index}>
                            <td>
                              {page > 1
                                ? limit * page - limit + index + 1
                                : index + 1}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {" "}
                              {item?.sponser_name}
                            </td>
                            <td>{item?.email}</td>
                            <td>
                              {format(
                                parseISO(item.create_date_time),
                                "dd MMM yyyy"
                              )}
                            </td>

                            <td>{item?.sponser_binance_acc_id}</td>
                            <td>
                              <img
                                src={
                                  item?.country_flag != undefined
                                    ? item?.country_flag
                                    : ""
                                }
                                className="tab_flag"
                              />{" "}
                              {item?.country_name === undefined
                                ? "NA"
                                : item?.country_name}
                            </td>
                            <td>{"$" + item?.total_amount_request}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {record.length == 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "15px",
                    }}
                  >
                    <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
                {record.length != 0 && (
                  <div className="pag_botm">
                    <div className="left_neu">
                      {t("SHOWING")} {limit * page - limit + 1}
                      {t("TO")}{" "}
                      {limit > countData
                        ? countData
                        : page * limit > countData
                        ? countData
                        : page * limit}{" "}
                      {t("OF")} {countData} {t("ENTRIES")}
                    </div>
                    <div className="pagddination">
                      <button
                        className="btn prew_1"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (page > 1) {
                            setPage(page - 1);
                          }
                        }}
                      >
                        {t("PREVIOUS")}
                      </button>{" "}
                      <input
                        className="form-control"
                        type="text"
                        style={{ textAlign: "center" }}
                        defaultValue={page}
                        readOnly
                      />{" "}
                      <button
                        className="btn next_bth"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (countPage > page) {
                            setPage(page + 1);
                          }
                        }}
                      >
                        {t("NEXT")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Reports;
