/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useEffect } from "react";
import { parseISO, format } from "date-fns";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
function Paymentrecived() {
  const { t } = useTranslation();
  const { paymentRecevied, openside } = useContext(ApiContext);
  let [recivedPayment, setRecivedPayment] = useState([]);
  const [loader, setLoader] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [search, setSearch] = useState("");
  const [datacount, setDataCount] = useState();
  // const [startDate, setstartDate] = useState();
  // const [endDate, setendDate] = useState();
  const [dates, setDates] = useState("");

  useEffect(() => {
    paymentRecevied(
      limit,
      page,
      setLoader,
      setCountPage,
      setRecivedPayment,
      search,
      setDataCount
    );
  }, [page, limit, search]);

  const setDateRange = () => {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    if (first != "Invalid date" && second != "Invalid date") {
      paymentRecevied(
        limit,
        page,
        setLoader,
        setCountPage,
        setRecivedPayment,
        search,
        setDataCount,
        first,
        second
      );
    } else {
      toast.warning("Please Enter Valid Date");
    }
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("PAYMENT_RECEIVED")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des">
                <div className="left_shiowp">
                  <label> {t("SHOW_ENTRIES")}</label>
                  <select
                    className="form-control"
                    defaultValue="10"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="calenderd1">
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    placeholder={t("SEARCH_WITH_DATE")}
                    minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                    maxDate={new Date()}
                  />

                  <button
                    className="btn btn_theme "
                    disabled={dates == ""}
                    onClick={setDateRange}
                  >
                    {t("FIND")}
                  </button>
                </div>
                <div className="right_shiowp">
                  <label>{t("SEARCH")}</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd">{t("S_NO")}.</th>
                      <th className="orderd">{t("USER_NAME")}</th>
                      <th className="orderd">{t("EMAIL")}</th>
                      <th className="orderd">{t("RECIVED_DATE")}</th>
                      {/* <th className="orderd">{t("PAYMENT_ID")}</th> */}
                      <th className="orderd">{t("COUNTRY")}</th>
                      <th className="orderd">{t("AMOUNT")}</th>
                      <th className="orderd">{t("PURCHASE_CREDIT")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recivedPayment.map((item, index) => (
                      <tr key={"tr" + index}>
                        <td style={{ width: "80px" }}>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.user_name != undefined ? item?.user_name : "-"}
                        </td>
                        <td>{item?.user_email}</td>
                        <td>
                          {" "}
                          {format(
                            parseISO(item.create_date_time),
                            "dd MMM yyyy, HH:mm"
                          )}
                        </td>
                        {/* <td>
                              {item?.payment_transaction_id != null
                                ? item?.payment_transaction_id
                                : "-"}
                            </td> */}
                        <td>
                          <img
                            src={
                              item?.user_country_id != undefined
                                ? item?.user_country_id[0].flag_url
                                : ""
                            }
                            className="tab_flag"
                          />{" "}
                          {item?.user_country_id === undefined
                            ? "-"
                            : item?.user_country_id[0]?.name}
                        </td>
                        <td>{"$" + item?.amount}</td>
                        <td>{item.coin_alloted}</td>
                        {/* <td>
                        <span className="btn_us1">
                          <button className="btn btn_clr">See Receipt</button>
                          <button className="btn btn_clr">Payment Send</button>
                        </span>
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {recivedPayment.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}> {t("NO_RECORD_FOUND")}</p>
                </div>
              )}
              {recivedPayment.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    <p>
                      {t("SHOWING")} {limit * page - limit + 1}
                      {t("TO")}{" "}
                      {limit > datacount
                        ? datacount
                        : page * limit > datacount
                        ? datacount
                        : page * limit}{" "}
                      {t("OF")} {datacount} {t("ENTRIES")}
                    </p>
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Paymentrecived;
