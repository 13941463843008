import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "./locales/en/english.json";

import esTranslation from "./locales/spani/spanish.json";
let language = localStorage.getItem("lng");
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    es: { translation: esTranslation },
  },
  lng: language == null ? "en" : language, // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
