import { createContext, useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import { useNavigate } from "react-router-dom";
// import { use } from "i18next";

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
  const api = useAxios();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("activeLink") || "Home"
  );
  const [activePrevLink, setPrevActiveLink] = useState("Home");
  const [showDropdown, setShowDropdown] = useState("");

  let [language, setLanguage] = useState(() =>
    localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en"
  );
  const langObj = {
    en: { nativeName: "English" },
    hi: { nativeName: "Hindi " },
  };

  useEffect(() => {
    localStorage.setItem("activePrevLink", activePrevLink);
    localStorage.setItem("activeLink", activeLink);
    const pathname = window.location.pathname;
    const isNavigate = pathname.includes("/document-preview/");
    if (!isNavigate) {
      if (activeLink == "Workflows") {
        navigate("/apply-signature/select-workflows");
      }
    }
    setPrevActiveLink(activeLink);
  }, [activeLink]);

  const contextData = {
    activeLink,
    setActiveLink,
    activePrevLink,
    setPrevActiveLink,
    showDropdown,
    setShowDropdown,
    language,
    setLanguage,
    langObj,
  };

  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};
