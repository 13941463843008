// import logo from './logo.svg';
import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "react-tooltip/dist/react-tooltip.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-image-crop/dist/ReactCrop.css";

import "./App.css";

function App() {
  return (
    <div>
      <Routes />
      {/* <app-chatbot></app-chatbot> */}
    </div>
  );
}

export default App;
