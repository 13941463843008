/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { appName } from "../../constants/constants";
import { useFormik } from "formik";
import { validationSchema } from "../../utils/utils";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
const Login = ({ title }) => {
  const { t } = useTranslation();
  const { loginUser, user, changePassword, logOutKey, setLogOutKey } =
    useContext(AuthContext);

  const [isforgate, setIsForgate] = useState(true);
  const [loader, setLoader] = useState(false);
  const [email, setemail] = useState("");
  const [error, setError] = useState("");
  const hasReloaded = sessionStorage.getItem("hasReloaded");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (data) => {
      loginUser(data?.email, data?.password);
    },
  });

  useEffect(() => {
    if (logOutKey) {
      setLogOutKey(false);
      window.location.reload();
    }
    document.title = `${appName} | ${title}`;
  }, []);

  function forgotPassword() {
    let data = { email: email };

    changePassword(data, showAlert, setLoader);
  }

  function showAlert() {
    Swal.fire({
      text: t("FORGETMESSAGE"),
      icon: "success",
      confirmButtonText: "OK",
    });
    setemail("");
    setIsForgate(true);
  }

  if (user) {
    if (user.role_id === "admin") {
      if (!hasReloaded) {
        sessionStorage.setItem("hasReloaded", "true");
        window.location.reload();
      }
      return <Navigate to="/dashboard_Admin" />;
    } else {
      return <Navigate to="/SponsorDashboard" />;
    }
  }

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!input.match(emailRegex)) {
      setError("Invalid email address");
      return false;
    }
    setError("");
    return true;
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setemail(inputValue);
    validateEmail(inputValue);
  };
  return (
    <div className="login">
      {loader && (
        <div class="loaderr2">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="shapp_login"></div>
      <div className="login_box1 card_me">
        {isforgate ? (
          <div className="login_box2 conten_set">
            <div className="text-center">
              <img src="img/logo.png" alt="" className="img_r logo_login" />
            </div>
            <h2>
              {/* <!-- Welcome Back ! --> */}
              {t("SIGN_IN_TO_ACCOUNT")}
              <span>
                {t("SIGNIN_WITH_YOUR_EMAIL")}. <br />
                {/* use your account{" "} */}
              </span>
            </h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <input
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  className={
                    "form-control" +
                    (formik.errors.email && formik.touched.email
                      ? " is-invalid"
                      : "")
                  }
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <div className="invalid-feedback">
                  {formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : null}
                </div>
              </div>
              <div className="mb-3">
                <input
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className={
                    "form-control" +
                    (formik.errors.password && formik.touched.password
                      ? " is-invalid"
                      : "")
                  }
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <div className="invalid-feedback">
                  {formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : null}
                </div>
                <p
                  className="forgot_link"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsForgate(false)}
                >
                  {" "}
                  {t("FORGOT_PASSWORD")}
                </p>
              </div>
              <div className="form-group1">
                <button
                  className="btn btn_theme btn-lg btn-block text_u"
                  type="submit"
                >
                  {t("LOGIN")}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="login_box2 conten_set">
            <div className="text-center">
              <img src="img/logo.png" alt="" className="img_r logo_login" />
            </div>
            <h2>
              {t("FORGOT_PASSWORD")}
              <span>
                {t("LOGIN_MESSAGE")} <br />
                {t("LOGIN_MESSAGE1")}.
              </span>
            </h2>

            <div className="mb-3">
              <input
                name="email"
                type="email"
                className="form-control"
                placeholder="Enter Email"
                value={email}
                onChange={handleChange}
              />
              {error && <div style={{ color: "red" }}>{error}</div>}
            </div>
            <div className="form-group1">
              <button
                disabled={email == ""}
                className="btn btn_theme btn-lg btn-block text_u"
                type="submit"
                onClick={forgotPassword}
              >
                {t("SUBMIT")}
              </button>
            </div>
            <p
              className="back_logi"
              style={{ cursor: "pointer" }}
              onClick={() => setIsForgate(true)}
            >
              {t("RETURN_TO")} <a>{t("LOGIN")}</a>
            </p>
          </div>
        )}
      </div>
      <div>
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
      </div>
    </div>
  );
};

export default Login;
