import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
// import { autoCloseTimer, delay } from "../constants/constants";
// import { useTranslation } from "react-i18next";

const AuthContext = createContext();
const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

export default AuthContext;
export const AuthProvider = ({ children }) => {
  const [logOutKey, setLogOutKey] = useState("");

  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("VidsocialAppAuthTokens")
      ? JSON.parse(localStorage.getItem("VidsocialAppAuthTokens"))
      : null
  );

  let [user, setUser] = useState(() =>
    localStorage.getItem("VidsocialAppAuthTokens")
      ? jwt_decode(
          JSON.parse(localStorage.getItem("VidsocialAppAuthTokens")).access
        )
      : null
  );

  const navigate = useNavigate();

  const loginUser = async (email, password) => {
    // setLoading(true);
    try {
      const response = await axios.post(
        `${baseURL}/vid_user/login`,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response?.data;
      if (response?.data?.code === 200) {
        setAuthTokens(data);
        const userData = jwt_decode(data?.access);

        localStorage.setItem(
          "authenticationrol",
          userData.role_id == "admin"
            ? 1
            : JSON.stringify({ name: userData?.display_name })
        );
        setUser(userData);
        localStorage.setItem("VidsocialAppAuthTokens", JSON.stringify(data));
        localStorage.setItem("S_TO", userData.user_id);
        // setLoading(false);
        // toast.success(userData?.role_id);

        if (userData?.role_id == "admin") {
          navigate("/dashboard_Admin");
        } else if (userData?.role_id == "sponser") {
          navigate("/SponsorDashboard");
        } else {
          alert("User not authorized");
        }
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      toast.error(error?.message);
      console.error(error);
    }
  };

  const logoutUser = async () => {
    setAuthTokens(null);
    setUser(null);
    // localStorage.clear();
    localStorage.removeItem("VidsocialAppAuthTokens");
    localStorage.removeItem("authenticationrol");
    localStorage.removeItem("data");
    setLogOutKey(true);
    navigate("/");
  };

  async function changePassword(data, showAlert, setLoader) {
    setLoader(true);
    try {
      const response = await axios.post(
        `${baseURL}/vid_user/forget_password`,
        data
      );
      console.log(response);
      if (response.data.code === 200) {
        showAlert();
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(response.data.error.email[0]);
      }
    } catch (error) {
      setLoader(false);

      toast.error(error.message);
    }
  }

  const contextData = {
    user,
    authTokens,
    setAuthTokens,
    setUser,
    loginUser,
    logoutUser,
    changePassword,
    setLogOutKey,
    logOutKey,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
  }, [authTokens]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );

  // try {
  //       const response = await axios.post(
  //         `${baseURL}/vid_user/login`,
  //         JSON.stringify({
  //           email,
  //           password
  //         }),
  //         {
  //           headers: {
  //             'Content-Type': 'application/json'
  //           }
  //         }
  //       )

  //       const data = await response.data
  //       setAuthTokens(data)
  //       const userData = jwt_decode(data?.access)
  //       localStorage.setItem(
  //         'authenticationrol',
  //         userData.role_id === 'admin' ? 1 : 0
  //       )
  //       setUser(userData)
  //       localStorage.setItem('VidsocialAppAuthTokens', JSON.stringify(data))
  //       setLoading(false)
  //       if (userData.role_id === 'admin') {
  //         navigate('/dashboard')
  //       } else if (userData.role_id === 'sponser') {
  //         navigate('/SponsorDashboard')
  //       } else {
  //         alert('User not authorized')
  //       }
  //     } catch (error) {
  //
  //       // error.response.data.code==401
  //       alert(error.response.data.error)

  //       // let data;
  //       // if (error && error.response) data = error.response.data;
  //       // if (data?.code === "INVALID_EMAIL") {
  //       // toast.error(error.response.data.error, {
  //       //   delay,
  //       //   autoClose: autoCloseTimer,
  //       //   onClose: () => setLoading(false),
  //       // });
  //       // } else {
  //       //   toast.error(t("common.Something went wrong"), {
  //       //     delay,
  //       //     autoClose: autoCloseTimer,
  //       //     onClose: () => setLoading(false),
  //       //   });
  //       // }
  //     }
};
