/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ApiContext from "../../context/ApiContext";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Tooltip } from "react-tooltip";
function Viewsponsordetails() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const {
    getWorkerBySponsorID,
    getPaymentRequestBySponsorId,
    getPaymentHistoryBySponsorId,
    openside,
    deleteSponsor,
    getAllWarning,
    DownlodImag,
  } = useContext(ApiContext);
  const routeId = useParams();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [datacount, setDataCount] = useState();
  const [search, setSearch] = useState("");

  const [worker, setWorker] = useState([]);
  const [warningData, setWarningData] = useState([]);

  const [paymentrequest, setPaymentRequest] = useState([]);
  const [requestlimit, setRequestLimit] = useState(5);
  const [requestpage, setRequestPage] = useState(1);
  const [requestcountpage, setRequestCountPage] = useState(1);
  const [requestdatacount, setRequestDataCount] = useState();

  const [paymenthistory, setPaymentHistory] = useState([]);
  const [historylimit, setHistoryLimit] = useState(5);
  const [historypage, setHistoryPage] = useState(1);
  const [historycountPage, setHistoryCountPage] = useState(1);
  const [historydatacount, setHistoryDataCount] = useState();

  const [warninglimit, setWarningLimit] = useState(5);
  const [warningpage, setWarningPage] = useState(1);
  const [warningcountpage, setWarningCountPage] = useState(1);
  const [warningdatacount, setWarningDataCount] = useState();

  const [sponsorData, setSponsorData] = useState();
  const [details, setDetails] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    let data = localStorage.getItem("data");
    setSponsorData(JSON.parse(data));
  }, []);

  useEffect(() => {
    getWorkerBySponsorID(
      routeId.id,
      setWorker,
      page,
      limit,
      search,
      setDataCount,
      setCountPage
    );
  }, [page, limit, search]);

  useEffect(() => {
    getAllWarning(
      routeId.id,
      warningpage,
      warninglimit,
      setWarningCountPage,
      setWarningDataCount,
      setWarningData
    );
  }, [warninglimit, warningpage]);

  useEffect(() => {
    getPaymentHistoryBySponsorId(
      routeId.id,
      setPaymentHistory,
      warningpage,
      warninglimit,
      setWarningCountPage,
      setWarningDataCount
    );
  }, [historypage, historylimit]);

  useEffect(() => {
    getPaymentRequestBySponsorId(
      routeId.id,
      setPaymentRequest,
      requestlimit,
      requestpage,
      setRequestCountPage,
      setRequestDataCount
    );
  }, [requestlimit, requestpage]);

  function deleteSpon() {
    Swal.fire({
      title: t("ARE_YOU_SURE"),
      text: t("DELETE_CONFORMATION"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      cancelButtonColor: "#d33",
      confirmButtonText: t("YES") + "," + t("DELETE_IT") + "!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSponsor(routeId.id);
      }
    });
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month
      }-${year}`;
  };

  function changeTab(key) {
    switch (key) {
      case "worker":
        if (worker.length === 0) {
          getWorkerBySponsorID(routeId.id, setWorker);
        }
        break;

      case "request":
        if (paymentrequest.length === 0) {
          getPaymentRequestBySponsorId(
            routeId.id,
            setPaymentRequest,
            warningpage,
            warninglimit,
            setWarningCountPage,
            setWarningDataCount
          );
        }
        break;
      case "history":
        if (paymenthistory.length === 0) {
          getPaymentHistoryBySponsorId(
            routeId.id,
            setPaymentHistory,
            warningpage,
            warninglimit,
            setWarningCountPage,
            setWarningDataCount
          );
        }
        break;
      case "warning":
        if (warningData.length === 0) {
          getAllWarning(
            routeId.id,
            warningpage,
            warninglimit,
            setWarningCountPage,
            setWarningDataCount,
            setWarningData
          );
        }
        break;

      default:
        break;
    }
  }

  function openModal(item) {
    var myElement = document.getElementById("tr" + item.payment_request_id);
    myElement.classList.add("acTive");
    setDetails(item);
    setShow(true);
  }

  const handleClose = () => {
    var myElement = document.getElementById("tr" + details.payment_request_id);
    myElement.classList.remove("acTive");
    setShow(false);
  };
  const downloadImage = (imageURL) => {
    DownlodImag(imageURL);
  };
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("VIEW_SPONSOR_DETAILS")} />
        <div className="wrap_us conten_set">
          {/* <div className="back_btn1 mb-3">
            <a className="btn btn-dark">
              <i className="uil uil-arrow-left"></i> Back
            </a>
          </div> */}
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="ttt">
                <div className="view_sponsor">
                  <div className="deatail_us3">
                    <div className="grid-container">
                      <div className="grid-item">
                        <span>
                          {" "}
                          <b>{t("SPONSOR_NAME")} :</b>{" "}
                          {sponsorData?.display_name}
                        </span>
                      </div>
                      <div className="grid-item">
                        <b> {t("EMAIL")} :</b> {sponsorData?.email}
                      </div>
                      {/* <div className="grid-item">
                        <b> {t("PHONE")} :</b>{" "}
                        {sponsorData?.phone == null ? "NA" : sponsorData?.phone}
                      </div> */}

                      <div className="grid-item">
                        <b>{t("JOIN_DATE")} :</b>
                        {formatDate(sponsorData?.date_joined)}
                      </div>
                      <div className="grid-item">
                        <b> {t("TOTAL_WORKER")} :</b>{" "}
                        {sponsorData?.total_workers_for_sponsor == null
                          ? "NA"
                          : sponsorData?.total_workers_for_sponsor}
                      </div>
                      <div className="grid-item">
                        <b> {t("TOTAL_WARNING")} :</b>{" "}
                        {sponsorData?.total_warning == null
                          ? "NA"
                          : sponsorData?.total_warning}
                      </div>
                    </div>
                  </div>
                  <div className="detete_box">
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteSpon()}
                    >
                      {t("DELETE")}
                    </button>{" "}
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="worker"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(e) => changeTab(e)}
                >
                  <Tab eventKey="worker" title={t("WORKERS")}>
                    <div className="">
                      <div style={{ float: "right" }}></div>
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label>{t("SHOW_ENTRIES")}</label>
                          {/* pagination */}
                          <select
                            className="form-control"
                            defaultValue="10"
                            onChange={(e) => {
                              e.stopPropagation();
                              setLimit(e.target.value);
                              setPage(1);
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>

                          {/* pagination */}
                        </div>
                        <div className="right_shiowp">
                          <label>{t("SEARCH")}</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={t("SEARCH")}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "80px" }}>{t("S_NO")}.</th>
                              <th>{t("WORKER_NAME")}</th>
                              <th> {t("EMAIL")}</th>
                              <th> {t("CONTECT")}</th>
                              <th> {t("COUNTRY")}</th>
                              <th style={{ width: "140px" }}>
                                {" "}
                                {t("TOTAL_WARNING")}
                              </th>
                              <th> {t("REGISTER_DATE")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {worker.map((row, index) => (
                              <tr key={row.id}>
                                <td>{index + 1}</td>
                                <td>{row.display_name}</td>
                                <td>{row.email}</td>
                                <td>{row.phone}</td>
                                <td>
                                  <img
                                    src={row?.country_id[0]?.flag_url}
                                    className="tab_flag"
                                  />{" "}
                                  {row?.country_id[0]?.name}
                                </td>
                                <td>{row.total_warning}</td>
                                <td>{formatDate(row.date_joined)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {worker.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")} {limit * page - limit + 1}{" "}
                              {t("TO")}{" "}
                              {limit > datacount
                                ? datacount
                                : page * limit > datacount
                                  ? datacount
                                  : page * limit}{" "}
                              {t("OF")} {datacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (page > 1) {
                                  setPage(page - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={page}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (countPage > page) {
                                  setPage(page + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                      {worker.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="request" title={t("PAYMENT_REQUEST")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label> {t("SHOW_ENTRIES")} </label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setRequestLimit(e.target.value);
                              setRequestPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>{t("S_NO")}.</th>
                              <th>{t("NAME")}</th>
                              <th>{t("EMAIL")}</th>
                              <th>{t("REQUEST_AMOUNT")}</th>
                              <th>{t("DATE")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentrequest.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.sponser_name}</td>
                                <td>{row.email}</td>
                                <td>{"$" + row.total_amount_request}</td>
                                <td>{formatDate(row.create_date_time)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {paymentrequest.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("Showing")}{" "}
                              {requestlimit * requestpage - requestlimit + 1}{" "}
                              {t("TO")}{" "}
                              {requestlimit > requestdatacount
                                ? requestdatacount
                                : requestpage * requestlimit > requestdatacount
                                  ? requestdatacount
                                  : requestpage * requestlimit}{" "}
                              {t("OF")} {requestdatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (requestpage > 1) {
                                  setRequestPage(requestpage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              // defaultValue={requestpage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={requestpage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (requestcountpage > requestpage) {
                                  setRequestPage(requestpage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                      {paymentrequest.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="history" title={t("PAYMENT_HISTORY")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label> {t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setHistoryLimit(e.target.value);
                              setHistoryPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "80px" }}>{t("S_NO")}.</th>
                              <th>{t("NAME")}</th>
                              <th>{t("EMAIL")}</th>
                              <th>{t("REQUEST_AMOUNT")}</th>
                              <th>{t("DATE")}</th>
                              <th>{t("ACTION")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymenthistory.map((row, index) => (
                              <tr
                                key={index}
                                id={"tr" + row.payment_request_id}
                              >
                                <td>{index + 1}</td>
                                <td>{row?.sponser_name}</td>
                                <td>{row?.email}</td>
                                <td>{"$" + row?.total_amount_request}</td>
                                <td>{formatDate(row?.create_date_time)}</td>
                                <td>
                                  <span className="btn_us1">
                                    <button
                                      data-tooltip-id="view"
                                      data-tooltip-content={t("SEE_RECEIPT")}
                                      className="btn btn_clr"
                                      onClick={() => openModal(row)}
                                    >
                                      <img src="/img/view.png" alt="" />
                                    </button>
                                    <Tooltip id="view" />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {paymenthistory.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")}{" "}
                              {historylimit * historypage - historylimit + 1}
                              {t("TO")}{" "}
                              {historylimit > historydatacount
                                ? historydatacount
                                : historypage * historylimit > historydatacount
                                  ? historydatacount
                                  : historypage * historylimit}{" "}
                              {t("OF")} {historydatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (historypage > 1) {
                                  setHistoryPage(historypage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              // defaultValue={historypage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={historypage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (historycountPage > historypage) {
                                  setHistoryPage(historypage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                      {paymenthistory.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="warning" title={t("WARNING")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label>{t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setWarningLimit(e.target.value);
                              setWarningPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "80px" }}>{t("S_NO")}.</th>
                              {/* <th>{t("NAME")}</th> */}
                              <th>{t("PREVIOUS")}</th>
                              <th>{t("WARNING")}</th>
                              <th style={{ width: "250px" }}>
                                {t("WARNING_DATE")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {warningData.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                {/* <td>{row.sponser_name}</td> */}
                                <td>{row.previous_warning}</td>
                                <td>{row.reason}</td>
                                <td>
                                  {format(
                                    parseISO(row.create_date_time),
                                    "dd MMM yyyy, HH:mm"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {warningData.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                      {warningData.length > 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")}{" "}
                              {warninglimit * warningpage - warninglimit + 1}{" "}
                              {t("TO")}{" "}
                              {warninglimit > warningdatacount
                                ? warningdatacount
                                : warningpage * warninglimit > warningdatacount
                                  ? warningdatacount
                                  : warningpage * warninglimit}{" "}
                              {t("OF")} {warningdatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (warningpage > 1) {
                                  setWarningPage(warningpage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              // defaultValue={warningpage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={warningpage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (warningcountpage > warningpage) {
                                  setWarningPage(warningpage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("VIEW_RECEIPT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("NAME")} : </b>
                {details?.sponser_name}
              </li>
              <li>
                <b>{t("AMOUNT")} : </b>${details?.total_amount_request}
              </li>
              <li>
                <b>{t("EMAIL")} : </b>
                {details?.email}
              </li>
              {details?.create_date_time != undefined ? (
                <li>
                  <b> {t("REQUEST_DATE")} : </b>
                  {format(parseISO(details?.create_date_time), "dd MMM yyyy")}
                </li>
              ) : (
                ""
              )}

              {details?.paid_date != null ? (
                <li>
                  <b> {t("RECIVED_DATE")} : </b>
                  {format(parseISO(details?.paid_date), "dd MMM yyyy")}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="down_respt">
                <img
                  className="img_r"
                  src={
                    details?.receipts_image != undefined
                      ? baseURL + details?.receipts_image
                      : "./public/img/image_n.png"
                  }
                  alt=""
                />
                <h4>{t("RECEIPT")} </h4>
                <button
                  className="btn btn-sm btn_theme"
                  onClick={() => downloadImage(baseURL + details?.receipts_image)}
                >
                  <i class="uil uil-import"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default Viewsponsordetails;
