/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
function Supportandhelp() {
  const { t } = useTranslation();
  const { openside, getsupportList, replaySupport } = useContext(ApiContext);
  const [show, setShow] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const closeViewModal = () => {
    deactivetTr(details.id);
    setShowViewModal(false);
  };
  const [details, setDetails] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [search, setSearch] = useState("");
  const [suportlist, setSuportList] = useState([]);
  const [userdata, setUserData] = useState();
  const [sendvalue, setSendValue] = useState("");
  const [datacount, setDataCount] = useState();
  const [dates, setDates] = useState("");
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    deactivetTr(userdata.id);
    setSendValue("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    getsupportList(
      limit,
      page,
      setLoader,
      setCountPage,
      setSuportList,
      search,
      setDataCount
    );
  }, [limit, page, search]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
  };
  const setDateRange = () => {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    if (first != "Invalid date" && second != "Invalid date") {
      getsupportList(
        limit,
        page,
        setLoader,
        setCountPage,
        setSuportList,
        search,
        setDataCount,
        first,
        second
      );
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  };

  function openModal(item) {
    activteTr(item.id);
    setUserData(item);
    handleShow();
  }
  function sendMsg() {
    let data = {
      request_id: userdata.id,
      reply_message: sendvalue,
    };
    replaySupport(data, handleClose);
  }

  function seeDetails(data) {
    activteTr(data.id);
    setDetails(data);
    setShowViewModal(true);
  }

  const activteTr = (item) => {
    var myElement = document.getElementById("tr" + item);
    myElement.classList.add("acTive");
  };
  const deactivetTr = (item) => {
    var myElement = document.getElementById("tr" + item);
    myElement.classList.remove("acTive");
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("SUPPORT_AND_HELP")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des">
                <div className="left_shiowp">
                  <label>{t("SHOW_ENTRIES")}</label>
                  <select
                    className="form-control"
                    defaultValue="5"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="calenderd1">
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    placeholder={t("SEARCH_WITH_DATE")}
                    minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                    maxDate={new Date()}
                  />

                  <button
                    className="btn btn_theme btn-sm"
                    disabled={dates == ""}
                    onClick={setDateRange}
                  >
                    {t("FIND")}
                  </button>
                </div>
                <div className="right_shiowp">
                  <label> {t("SEARCH")}</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("SEARCH")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd">{t("S_NO")}.</th>
                      <th className="orderd">{t("NAME")}</th>
                      <th className="orderd">{t("ROLL")}</th>
                      <th className="orderd">{t("EMAIL")}</th>
                      <th className="orderd">{t("SUPPORT_DATE")}</th>
                      <th className="orderd">{t("ISSUE")}</th>
                      <th>{t("ACTION")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {suportlist.map((item, index) => (
                      <tr key={"1" + index} id={"tr" + item.id}>
                        <td>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {" "}
                          {item.user_name}
                        </td>
                        <td style={{ fontWeight: "bold", color: "black" }}>
                          {item.role}
                        </td>
                        <td>{item.email}</td>
                        <td>{formatDate(item.created_date_time)}</td>
                        <td>
                          {item.message.length > 80
                            ? item.message.slice(0, 80) + "..."
                            : item.message}
                        </td>

                        <td>
                          <span className="btn_us1">
                            <button
                              className="btn btn_clr"
                              data-tooltip-id="view"
                              data-tooltip-content={t("VIEW_DETAILS")}
                              onClick={() => seeDetails(item)}
                            >
                              <img src="img/view.png" alt="" />
                            </button>
                          </span>
                          <span className="btn_us1">
                            <button
                              className="btn btn_clr"
                              data-tooltip-id="view"
                              data-tooltip-content={t("SEND_MESSAGE")}
                              onClick={() => openModal(item)}
                            >
                              <img src="img/pay.png" alt="" />
                            </button>
                          </span>
                          <Tooltip id="view" />

                          {/* <span className="btn_us3">
                            <button
                              className="btn btn-sm btn_theme"
                              onClick={() => openModal(item)}
                            >
                              {t("SEND_MESSAGE")}
                            </button>
                          </span> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {suportlist.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}> {t("NO_RECORD_FOUND")}</p>
                </div>
              )}

              {suportlist.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                    {limit > datacount
                      ? datacount
                      : page * limit > datacount
                      ? datacount
                      : page * limit}{" "}
                    {t("OF")} {datacount} {t("ENTRIES")}
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>
              {" "}
              {t("SEND_MESSAGE_TO")} {userdata?.user_name}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("NAME")} : </b>
                {userdata?.user_name}
              </li>

              <li>
                <b>{t("ROLL")} : </b>
                {userdata?.role}
              </li>
              <li>
                <b>{t("EMAIL")} : </b>
                {userdata?.email}
              </li>
            </ul>
          </div>

          <div className="textarea">
            <textarea
              style={{ width: "100%" }}
              value={sendvalue}
              onChange={(e) => setSendValue(e.target.value)}
              rows={4} // specify the number of rows
              cols={50} // specify the number of columns
              placeholder={t("TYPE_SOMETHING")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={sendvalue === ""}
            className="btn btn_theme"
            onClick={sendMsg}
          >
            {t("SEND_MESSAGE")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showViewModal} onHide={closeViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("VIEW_DETAILS_OF")}
            {details?.user_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("NAME")} : </b>
                {details?.user_name}
              </li>

              <li>
                <b>{t("ROLL")} : </b>
                {details?.role}
              </li>
              <li>
                <b>{t("EMAIL")} : </b>
                {details?.email}
              </li>
            </ul>
          </div>

          <p>
            <b>{t("ISSUE")}</b>
          </p>
          <div className="faqs_modal">
            {/* <div>
              <p>
                <b>Ussue</b>
              </p>
            </div> */}
            <div>
              <p>{details?.message}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Supportandhelp;
