/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useContext, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import AuthContext from "../../context/AuthContext";
import ApiContext from "../../context/ApiContext";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Header = ({ name }) => {
  const { t } = useTranslation();
  const { logoutUser, user } = useContext(AuthContext);
  const { setOpenSide, chnageLanguage, getSelectedLanguage } =
    useContext(ApiContext);
  const [rol, setRol] = useState();

  useEffect(() => {
    let rol = localStorage.getItem("authenticationrol");
    rol = JSON.parse(rol);
    setRol(rol);
  }, []);
  useEffect(() => {
    let id = localStorage.getItem("S_TO");
    let data = { Id: id };
    getSelectedLanguage(data);
  }, []);

  function logOut() {
    logoutUser();
  }

  function openSideBar() {
    setOpenSide((prevCheck) => !prevCheck);
  }

  const changeLanguage = (lng) => {
    let id = localStorage.getItem("S_TO");
    let data = { Id: id, language: lng };
    chnageLanguage(data);
  };

  return (
    <>
      {/* <div className="mobil_blaock" onClick={() => openSideBar()}></div> */}
      <div className="header">
        <div className="left_lu">
          <h4 className="nameee">
            <div className="menu_uus" onClick={() => openSideBar()}>
              <div className="men_side ">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            {name}
          </h4>
        </div>
        <div className="right_mennu">
          <ul className="ul_set">
            <li className="dropdown user_profile">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdownMenuLink"
                  className="dropdown-toggle"
                  style={{ background: "none", border: "none", color: "black" }}
                >
                  <span className="user_img">
                    {rol == 1 ? "A" : rol?.name.charAt(0).toUpperCase()}
                  </span>
                  {rol == 1 ? `${t("ADMINISTOR")}` : rol?.name}{" "}
                  <span className="email_h">
                    {rol == 1 ? `${t("ADMIN")}` : `${t("SPONSOR")}`}{" "}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="mt-3">
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    {t("ENGLISH")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("es")}>
                    {t("SPANISH")}
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={logOut}>{t("LOGOUT")}</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
      {/* //SideBar Section */}
      <div className="slidebar">
        <div className="logo_side">
          <img src="/img/logo.png" alt="" />
        </div>
        {rol === 1 ? (
          <ul className="ul_set">
            <li>
              <NavLink to="/dashboard_Admin">
                <i className="uil uil-create-dashboard"></i>{" "}
                <span> {t("DASHBOARD")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/sponsor">
                <i className="uil uil-clipboard-notes"></i>{" "}
                <span>{t("SPONSORS")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/users">
                <i className="uil uil-users-alt"></i>
                <span>{t("USERS")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Workerlist">
                <i className="uil uil-user-plus"></i>
                <span>{t("WORKERS_REQUEST")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/paymentrequest">
                <i className="uil uil-bill"></i>{" "}
                <span>{t("PAYMENT_REQUEST")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/paymentreceived">
                <i className="uil uil-transaction"></i>
                <span>{t("PAYMENT_RECEIVED")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/paymenthistory">
                <i className="uil uil-invoice"></i>
                <span> {t("SPONSOR_PAYMENT_HISTORY")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/cardrate">
                <i className="uil uil-credit-card"></i>
                <span>{t("RATE_CARD_OF_CREDITS")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/bestworker">
                <i className="uil uil-game-structure"></i>
                <span>{t("TOP_3_PERFORMERS")}</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/Warning">
                <i className="uil uil-exclamation-triangle"></i>
                <span>{t("WARNING")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Prohibited_bank">
                <i className="uil uil-university"></i>
                <span>{t("PROHIBITED_BANK")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Reports">
                <i className="uil uil-download-alt"></i>
                <span>{t("REPORTS")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Faq">
                <i className="uil uil-question-circle"></i>
                <span>{t("FAQ")}</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/Supportandhelp">
                <i className="uil uil-comment-question"></i>
                <span>{t("SUPPORT_AND_HELP")}</span>
              </NavLink>
            </li>

            <li className="log_out" onClick={logOut}>
              <a>
                <i className="uil uil-signout"></i> <span>{t("LOGOUT")}</span>
              </a>
            </li>
          </ul>
        ) : (
          <ul className="ul_set">
            {/* Sponsor nave bar */}
            <li>
              <NavLink to="/SponsorDashboard">
                <i className="uil uil-dashboard"></i>{" "}
                <span>{t("DASHBOARD")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/myWorkers">
                <i className="uil uil-user-plus"></i>
                <span>{t("MY_WORKERS")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Sponsorshiprequest">
                <i className="uil uil-package"></i>
                <span>{t("SPONSORSHIP_REQUEST")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/SendPaymentRequest">
                <i className="uil uil-package"></i>
                <span>{t("PAYMENT_REQUEST")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/sponsorPaymentHistory">
                <i className="uil uil-invoice"></i>
                <span>{t("PAYMENT_HISTORY")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/binance_Account">
                <i className="uil uil-bitcoin-sign"></i>
                <span>{t("Binance_Account")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/sponsor_warning">
                <i className="uil uil-exclamation-triangle"></i>
                <span>{t("WARNING")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/sponsor_reports">
                <i className="uil uil-invoice"></i>
                <span>{t("REPORTS")}</span>
              </NavLink>
            </li>
            <li className="log_out" onClick={logOut}>
              <a>
                <i className="uil uil-signout"></i> <span>{t("LOGOUT")}</span>
              </a>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Header;
