/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ApiContext from "../../context/ApiContext";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";

function Viewuser() {
  const { t } = useTranslation();
  const { userCallHistory, userCreditsHistory, userWarningHistory, openside } =
    useContext(ApiContext);
  const routeId = useParams();
  //   -----------------------------------------
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [datacount, setDataCount] = useState();
  // -------------------------------------------
  const [creditlimit, setCreditLimit] = useState(5);
  const [creditpage, setCreditPage] = useState(1);
  const [creditcountPage, setCreditCountPage] = useState(1);
  const [creditdatacount, setCreditDataCount] = useState();
  // -------------------------------------------
  const [warninglimit, setWarningLimit] = useState(5);
  const [warningpage, setWarningPage] = useState(1);
  const [warningcountPage, setWarningCountPage] = useState(1);
  const [warningdatacount, setWarningDataCount] = useState();
  // -------------------------------------------
  const [callhistory, setCallhistory] = useState([]);
  const [credithistory, setCredithistory] = useState([]);
  const [warningthistory, setWarningtHistory] = useState([]);
  const [userData, setUserData] = useState();
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  useEffect(() => {
    let data = localStorage.getItem("data");
    setUserData(JSON.parse(data));
  }, []);

  useEffect(() => {
    userCallHistory(
      routeId.id,
      setCallhistory,
      page,
      limit,
      setCountPage,
      setDataCount
    );
  }, [limit, page]);

  useEffect(() => {
    userCreditsHistory(
      routeId.id,
      setCredithistory,
      creditpage,
      creditlimit,
      setCreditCountPage,
      setCreditDataCount
    );
  }, [creditpage, creditlimit]);

  useEffect(() => {
    userWarningHistory(
      routeId.id,
      setWarningtHistory,
      warninglimit,
      warningpage,
      setWarningCountPage,
      setWarningDataCount
    );
  }, [warninglimit, warningpage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month
      }-${year}`;
  };

  function changeTab(key) {
    switch (key) {
      case "callhistory":
        if (callhistory.length === 0) {
          userCallHistory(
            routeId.id,
            setCallhistory,
            page,
            limit,
            setCountPage,
            setDataCount
          );
        }
        break;
      case "credithistory":
        if (credithistory.length === 0) {
          userCreditsHistory(
            routeId.id,
            setCredithistory,
            creditpage,
            creditlimit,
            setCreditCountPage,
            setCreditDataCount
          );
        }
        break;
      case "Warning":
        if (warningthistory.length === 0) {
          userWarningHistory(
            routeId.id,
            setWarningtHistory,
            warninglimit,
            warningpage,
            setWarningCountPage,
            setWarningDataCount
          );
        }
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header
          name={`${t("VIEW_USER_DETALIS_OF")} ( ${userData?.display_name} )`}
        />
        {/* <Header name={`${t("VIEW_USER_DETALIS_OF")} `} /> */}
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="ttt">
                <div className=" mb-4 ">
                  <div className=" row view_user2 ">
                    <div className="col-md-3">
                      <div className="vide_img card">
                        {/* <h3>Image</h3> */}
                        {userData?.profile_image ? (
                          <img src={baseURL + userData?.profile_image} />
                        ) : (
                          <img
                            style={{
                              objectFit: "contain",
                            }}
                            src={"/img/image_n.png"}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="deatail_us3">
                        <div className="grid-container">
                          <div className="grid-item">
                            <span style={{ textTransform: "capitalize" }}>
                              {" "}
                              <b>{t("USER_NAME")} : </b>{" "}
                              {userData?.display_name}
                            </span>
                          </div>
                          <div className="grid-item">
                            <b> {t("EMAIL")} : </b> {userData?.email}
                          </div>
                          <div className="grid-item">
                            <b> {t("PHONE")} : </b>{" "}
                            {userData?.phone == null ? "NA" : userData?.phone}
                          </div>

                          <div className="grid-item">
                            <b>{t("JOIN_DATE")} : </b>
                            {formatDate(userData?.date_joined)}
                          </div>

                          <div className="grid-item">
                            <b> {t("TOTAL_WARNING")} : </b>{" "}
                            {userData?.total_warning == null
                              ? "NA"
                              : userData?.total_warning}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="callhistory"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(e) => changeTab(e)}
                >
                  <Tab eventKey="callhistory" title={t("CALL_HISTORY")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label>{t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setLimit(e.target.value);
                              setPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div style={{ float: "right" }}></div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "80px" }}>{t("S_NO")}.</th>
                              <th>{t("WORKER_NAME")}</th>
                              <th>{t("COUNTRY")}</th>
                              <th>{t("START_TIME")}</th>
                              <th>{t("END_TIME")}</th>
                              <th>{t("DURATION")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {callhistory.map((row, index) => (
                              <tr key={row.id}>
                                <td>
                                  {page > 1
                                    ? limit * page - limit + index + 1
                                    : index + 1}
                                </td>
                                <td>{row?.worker_name}</td>
                                <td>
                                  <img
                                    src={row.worker_country_flag}
                                    className="tab_flag"
                                  />{" "}
                                  {row.worker_country_name}
                                </td>
                                <th>
                                  {format(
                                    parseISO(row.start_datetime),
                                    "ccc, dd MMM yyyy, HH:mm"
                                  )}
                                </th>
                                <th>
                                  {row.end_datetime != undefined ? (
                                    <div>
                                      {format(
                                        parseISO(row.end_datetime),
                                        "ccc, dd MMM yyyy, HH:mm"
                                      )}
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </th>
                                <th>{row.duration}</th>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {callhistory.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                      {callhistory.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")} {limit * page - limit + 1}{" "}
                              {t("TO")}{" "}
                              {limit > datacount
                                ? datacount
                                : page * limit > datacount
                                  ? datacount
                                  : page * limit}{" "}
                              {t("OF")} {datacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (page > 1) {
                                  setPage(page - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              defaultValue={page}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={page}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (countPage > page) {
                                  setPage(page + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="credithistory" title={t("CREDIT_HISTORY")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label> {t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setCreditLimit(e.target.value);
                              setCreditPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>{t("S_NO")}.</th>
                              <th>{t("AMOUNT")}</th>
                              <th> {t("CREDITS")}</th>
                              <th> {t("PAYMENT_ID")}</th>
                              <th> {t("BUYING_DATE")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {credithistory.map((row, index) => (
                              <tr key={row.id}>
                                <td style={{ width: "80px" }}>
                                  {creditpage > 1
                                    ? creditlimit * creditpage -
                                    creditlimit +
                                    index +
                                    1
                                    : index + 1}
                                </td>
                                <td>{"$" + row.amount}</td>
                                <td style={{ width: "180px" }}>
                                  {row.coin_alloted}
                                </td>
                                <td>
                                  {row.payment_transaction_id != null
                                    ? row.payment_transaction_id
                                    : "-"}
                                </td>
                                <td style={{ width: "250px" }}>
                                  {format(
                                    parseISO(row.create_date_time),
                                    "ccc, dd MMM yyyy, HH:mm"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {credithistory.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>
                            {" "}
                            {t("NO_RECORD_FOUND")}
                          </p>
                        </div>
                      )}
                      {callhistory.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")}{" "}
                              {creditlimit * creditpage - creditlimit + 1}{" "}
                              {t("TO")}{" "}
                              {creditlimit > creditdatacount
                                ? creditdatacount
                                : creditpage * creditlimit > creditdatacount
                                  ? creditdatacount
                                  : creditpage * creditlimit}{" "}
                              {t("OF")} {creditdatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (creditpage > 1) {
                                  setCreditPage(creditpage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              defaultValue={creditpage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={creditpage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (creditcountPage > creditpage) {
                                  setCreditPage(creditpage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="Warning" title={t("WARNING_HISTORY")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label>{t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setWarningLimit(e.target.value);
                              setWarningPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "80px" }} className="orderd">
                                {t("S_NO")}.
                              </th>
                              <th className="orderd">{t("WARNING")}</th>
                              <th
                                style={{ width: "500px" }}
                                className="orderd "
                              >
                                {t("PENALTY")}
                              </th>
                              <th style={{ width: "280px" }} className="orderd">
                                {t("WARNING_DATE")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {warningthistory.map((row, index) => (
                              <tr key={row.id}>
                                <td style={{ width: "80px" }}>
                                  {warningpage > 1
                                    ? warninglimit * warningpage -
                                    warninglimit +
                                    index +
                                    1
                                    : index + 1}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row.reason}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row.user_warning_action}
                                </td>
                                <td style={{ width: "250px" }}>
                                  {format(
                                    parseISO(row.create_date_time),
                                    " dd MMM yyyy"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {warningthistory.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>
                            {t("NO_RECORD_FOUND")}{" "}
                          </p>
                        </div>
                      )}
                      {callhistory.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")}{" "}
                              {warninglimit * warningpage - warninglimit + 1}
                              {t("TO")}{" "}
                              {warninglimit > warningdatacount
                                ? warningdatacount
                                : warningpage * warninglimit > warningdatacount
                                  ? warningdatacount
                                  : warningpage * warninglimit}{" "}
                              {t("OF")} {warningdatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (warningpage > 1) {
                                  setWarningPage(warningpage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              defaultValue={warningpage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={warningpage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (warningcountPage > warningpage) {
                                  setWarningPage(warningpage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Viewuser;
