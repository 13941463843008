import { useContext, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
function Createfaq() {
  const { t } = useTranslation();
  const { openside, createFaq } = useContext(ApiContext);
  // const [content, setContent] = useState("");
  const validationSchema = () =>
    Yup.object().shape({
      question: Yup.string().required(`${t("QUESTION_IS_REQUIRED")}`),
      answer: Yup.string().required(`${t("ANSWER_IS_REQUIRED")}`),
    });
  const quesTion = (value) => {
    value === "<p><br></p>"
      ? formik.setFieldValue("question", "")
      : formik.setFieldValue("question", value);
  };
  const anSwer = (value) => {
    value === "<p><br></p>"
      ? formik.setFieldValue("answer", "")
      : formik.setFieldValue("answer", value);
  };
  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema,
    onSubmit: (data) => {
      createFaq(data);
    },
  });

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("CREATE_FAQ")} />
        <div class="wrap_us conten_set">
          <div class="row">
            <div class="col-md-10">
              <form onSubmit={formik.handleSubmit}>
                <div class="card_me">
                  <div class="card_body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label>{t("QUESTION")}*</label>
                          <ReactQuill
                            theme="snow"
                            name="question"
                            placeholder={t("ENTER_QUESTION")}
                            className={
                              formik.errors.question && formik.touched.question
                                ? " is-invalid"
                                : ""
                            }
                            value={formik.values.question}
                            onChange={quesTion}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.question && formik.touched.question
                              ? formik.errors.question
                              : null}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-3">
                          <label>{t("ANSWER")}*</label>
                          <ReactQuill
                            theme="snow"
                            name="answer"
                            placeholder={t("ENTER_ANSWER")}
                            className={
                              formik.errors.answer && formik.touched.answer
                                ? " is-invalid"
                                : ""
                            }
                            value={formik.values.answer}
                            onChange={anSwer}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.answer && formik.touched.answer
                              ? formik.errors.answer
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 text-end">
                      <button type="submit" class="btn btn_theme btn-lg">
                        {t("CREATE_FAQ")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Createfaq;
