/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
function Faq() {
  const { t } = useTranslation();
  const { openside, getAllFaq, chnageFaqOrder, deleteFaq } =
    useContext(ApiContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [faqlist, setFaqList] = useState([]);
  const [search, setSearch] = useState("");
  const [datacount, setDataCount] = useState();
  const [selectedData, setSelectedData] = useState();
  const [show, setShow] = useState(false);
  const modalClose = () => {
    var myElement = document.getElementById("tr" + selectedData.id);
    myElement.classList.remove("acTive");
    setShow(false);
  };
  const modalShow = () => setShow(true);
  useEffect(() => {
    getAllFaq(limit, page, setCountPage, setFaqList, search, setDataCount);
  }, [limit, page, search]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newRows = Array.from(faqlist);
    const [reorderedRow] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, reorderedRow);
    let data = { sequence: result.destination.index + 1 };
    chnageFaqOrder(result.draggableId, data);
    setFaqList(newRows);
  };

  // const onDragEnd = (result) => {
  //   if (!result.destination) return;

  //   const newData = Array.from(faqlist);
  //   const [movedItem] = newData.splice(result.source.index, 1);
  //   newData.splice(result.destination.index, 0, movedItem);

  //   setFaqList(newData);
  // };

  function deleteFAQ(id) {
    Swal.fire({
      title: t("ARE_YOU_SURE"),
      text: t("YOU_WANT_TO_DELETE_THIS_FAQ"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      cancelButtonColor: "#d33",
      confirmButtonText: t("YES") + " " + t("DELETE_IT") + "!",
      cancelButtonText: t("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFaq(id, afterDelete);
      }
    });
  }
  function afterDelete(id) {
    const data = faqlist.filter((x) => x.id !== id);
    setFaqList(data);
  }

  function viewdetails(data) {
    setSelectedData(data);
    var myElement = document.getElementById("tr" + data.id);
    myElement.classList.add("acTive");
    modalShow();
  }
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("FAQ_LIST")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <h4 className="head_card">
                {" "}
                <button className="btn btn_theme">
                  <Link style={{ color: "white" }} to="/Create_faq">
                    {" "}
                    {t("CREATE_FAQ")}{" "}
                  </Link>
                </button>
              </h4>
              <div className="saech_des">
                <div className="left_shiowp">
                  <label>{t("SHOW_ENTRIES")}</label>
                  <select
                    className="form-control"
                    defaultValue="5"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                {/* <div className="right_shiowp">
                  <label>{t("SEARCH")}</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("SEARCH")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div> */}
              </div>

              {/* ----------------------------- */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="table">
                  {(provided) => (
                    <div className="table-responsive">
                      <table
                        className="table"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <thead>
                          <tr>
                            <th className="orderd" style={{ width: "70px" }}>
                              {t("S_NO")}.
                            </th>
                            <th className="orderd">{t("QUESTION")}</th>
                            <th className="orderd">{t("ANSWER")}</th>
                            <th>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {faqlist.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  id={"tr" + item.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td>
                                    {page > 1
                                      ? limit * page - limit + index + 1
                                      : index + 1}
                                  </td>
                                  <td
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.question.length > 50
                                          ? item.question.slice(0, 49) + "..."
                                          : item.question,
                                    }}
                                  ></td>
                                  <td
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.answer.length > 200
                                          ? item.answer.slice(0, 199) + "..."
                                          : item.answer,
                                    }}
                                  ></td>
                                  <td>
                                    <span className="btn_us1">
                                      <button
                                        className="btn btn_clr"
                                        data-tooltip-id="view"
                                        data-tooltip-content={t("UPDATE_FAQ")}
                                      >
                                        <Link to={"/Update_faq/" + item.id}>
                                          <img src="img/edit.png" alt="" />{" "}
                                        </Link>
                                      </button>

                                      <button
                                        data-tooltip-id="view"
                                        data-tooltip-content={t("DELETE_FAQ")}
                                        className="btn btn_clr"
                                        onClick={() => deleteFAQ(item.id)}
                                      >
                                        <img src="img/delete.png" alt="" />{" "}
                                      </button>

                                      <button
                                        data-tooltip-id="view"
                                        data-tooltip-content={t("VIEW_DETAILS")}
                                        className="btn btn_clr"
                                        onClick={() => viewdetails(item)}
                                      >
                                        <img
                                          style={{ height: "20px" }}
                                          src="img/view.png"
                                          alt=""
                                        />
                                      </button>
                                      <Tooltip id="view" />
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {/* ----------------------------- */}

              {faqlist.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}> {t("NO_RECORD_FOUND")}</p>
                </div>
              )}
              {faqlist.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    {t("SHOWING")} {limit * page - limit + 1}
                    {t("TO")}{" "}
                    {limit > datacount
                      ? datacount
                      : page * limit > datacount
                      ? datacount
                      : page * limit}{" "}
                    {t("OF")} {datacount} {t("ENTRIES")}
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal show={show} onHide={modalClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="faqs_modal">
              <div>
                <p>
                  <b
                    dangerouslySetInnerHTML={{
                      __html: selectedData?.question,
                    }}
                  ></b>
                </p>
              </div>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedData?.answer,
                  }}
                ></p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Faq;
