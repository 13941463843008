/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../../Admin/CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Worker's",
    },
  },
};
export const options1 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Revenue",
    },
  },
};

function SponsorDashboard() {
  const [t] = useTranslation();
  const {
    openside,
    getSponsorDashboardDetails,
    sponsorWorkersGraph,
    sponsoRevenueGraph,
  } = useContext(ApiContext);
  const [dashboardCount, setDashboardCount] = useState([]);
  const [revenueGraphData, setRevenueGraphData] = useState({
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Money",
        data: Array(12).fill(0),
        backgroundColor: "rgba(255, 165, 0)",
      },
    ],
  });

  const [workerGraphData, setWorkerGraphData] = useState({
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Worker",
        data: Array(12).fill(0),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });
  const [workerDate, setWorkerDate] = useState(new Date());
  const [revenueDate, setRevenueDate] = useState(new Date());
  useEffect(() => {
    getSponsorDashboardDetails(setDashboardCount);
  }, []);

  useEffect(() => {
    const obj = {
      year: Number(moment(workerDate).format("YYYY")),
    };
    sponsorWorkersGraph(obj, setWorkerGraphData);
  }, [workerDate]);

  useEffect(() => {
    const obj = {
      year: Number(moment(revenueDate).format("YYYY")),
    };
    sponsoRevenueGraph(obj, setRevenueGraphData);
  }, [revenueDate]);

  const findGraphData = (type, year) => {
    if (type == 0) {
      setWorkerDate(year);
    } else {
      setRevenueDate(year);
    }
  };

  return (
    <div className={`main_slide ${openside ? "open_slider" : ""}`}>
      <Header name={t("SPONSOR_DASHBOARD")} />
      <div className="wrap_us conten_set">
        <div className="row">
          <div className="col-md-3">
            <NavLink
              to={
                dashboardCount.today_sponsorship_request != 0 &&
                "/Sponsorshiprequest"
              }
            >
              <div className="card_me grap_cont mb-3">
                <span
                  className="icon_das"
                  style={{
                    background: "#3478F614",
                    color: "#3478F6",
                  }}
                >
                  <i className="uil uil-user-plus"></i>
                </span>
                <h5>{t("TODAY'S_SPONSORSHIP_REQUEST")}</h5>
                <h6>{dashboardCount.today_sponsorship_request}</h6>
              </div>
            </NavLink>
          </div>
          <div className="col-md-3">
            <NavLink
              to={
                dashboardCount.pending_sponsorship_request != 0 &&
                "/Sponsorshiprequest"
              }
            >
              <div className="card_me grap_cont mb-3">
                <span
                  className="icon_das"
                  style={{
                    background: "#3478F614",
                    color: "#3478F6",
                  }}
                >
                  <i className="uil uil-user-plus"></i>
                </span>
                <h5>{t("PENDING_SPONSORSHIP_REQUEST")}</h5>
                <h6>{dashboardCount.pending_sponsorship_request}</h6>
              </div>
            </NavLink>
          </div>
          <div className="col-md-3">
            <NavLink
              to={dashboardCount.today_payments != 0 && "/SendPaymentRequest"}
            >
              <div className="card_me grap_cont mb-3">
                <span
                  className="icon_das"
                  style={{
                    background: "#4bc25a14",
                    color: "#4bc25a",
                  }}
                >
                  <i className="uil uil-bill"></i>
                </span>
                <h5>{t("TODAY_PAYMENT_REQUEST")}</h5>
                <h6>{dashboardCount.today_payments}</h6>
              </div>
            </NavLink>
          </div>{" "}
          <div className="col-md-3">
            <NavLink
              to={dashboardCount.pending_payments != 0 && "/SendPaymentRequest"}
            >
              <div className="card_me grap_cont mb-3">
                <span
                  className="icon_das"
                  style={{
                    background: "#4bc25a14",
                    color: "#4bc25a",
                  }}
                >
                  <i className="uil uil-bill"></i>
                </span>
                <h5>{t("PENDING_PAYMENT_REQUEST")}</h5>
                <h6>{dashboardCount.pending_payments}</h6>
              </div>
            </NavLink>
          </div>{" "}
        </div>
        <div className="card_me table_des1">
          <div className="card_body">
            <div className="ttt">
              <div className="col-md-12  mb-6 row">
                <div className="col-md-6 ">
                  <div className="chart">
                    <div className="calenderd1">
                      <Calendar
                        value={workerDate}
                        onChange={(e) => findGraphData("0", e.value)}
                        view="year"
                        dateFormat="yy"
                        minDate={new Date(2023, 11, 31)} // Restrict to current date onwards
                        maxDate={new Date()}
                      />

                      <button className="btn btn_theme btn-sm">
                        {t("YEAR")}
                      </button>
                    </div>
                    <Bar options={options} data={workerGraphData} />
                  </div>
                </div>{" "}
                <div className="col-md-6 ">
                  <div className="chart">
                    <div className="calenderd1">
                      <Calendar
                        value={revenueDate}
                        onChange={(e) => findGraphData("1", e.value)}
                        view="year"
                        dateFormat="yy"
                        minDate={new Date(2023, 11, 31)} // Restrict to current date onwards
                        maxDate={new Date()}
                      />

                      <button className="btn btn_theme btn-sm">
                        {t("YEAR")}
                      </button>
                    </div>

                    <Bar options={options1} data={revenueGraphData} />
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SponsorDashboard;
