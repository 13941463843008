/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import ApiContext from "../../context/ApiContext";
import { useParams } from "react-router-dom";
import Header from "../../Admin/CommonPage/Header";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
function Viewpaymentdetails() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const routeId = useParams();
  const { openside, paymentDetailsBySponsor, DownlodImag } =
    useContext(ApiContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [record, setRecord] = useState([]);
  const [datacount, setDataCount] = useState();
  const [totalamount, setTotalAmount] = useState("");
  const [details, setDetails] = useState([]);
  useEffect(() => {
    let data = localStorage.getItem("data");

    setDetails(JSON.parse(data));
    paymentDetailsBySponsor(
      limit,
      page,
      setCountPage,
      setRecord,
      setDataCount,
      routeId.id,
      routeId.id1,
      setTotalAmount
    );
  }, [limit, page]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month
      }-${year}`;
  };
  const downloadImage = (image) => {
    DownlodImag(image);
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name="View Payment Details" />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="view_sponsor">
                <div className="deatail_us3">
                  <div className="grid-container">
                    <div className="grid-item">
                      <span>
                        {" "}
                        <b>{t("REQUEST_ID")} :</b> {details?.payment_request_id}
                      </span>
                    </div>
                    <div className="grid-item">
                      <b> {t("METHOD")} :</b> {details?.active_payment_method}
                    </div>
                    {/* <div className="grid-item">
                        <b> {t("PHONE")} :</b>{" "}
                        {sponsorData?.phone == null ? "NA" : sponsorData?.phone}
                      </div> */}

                    <div className="grid-item">
                      <b>{t("REQUEST_DATE")} :</b>
                      {details?.create_date_time != undefined ? (
                        <div>
                          {format(
                            parseISO(details?.create_date_time),
                            "dd MMM yyyy"
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="grid-item">
                      <b> {t("RECIVED_DATE")} :</b>{" "}
                      {details?.paid_date != null ? (
                        <div>
                          {format(parseISO(details?.paid_date), "dd MMM yyyy")}
                        </div>
                      ) : (
                        "NA"
                      )}
                    </div>
                    <div className="grid-item">
                      <b> {t("REQUEST_AMOUNT")} :</b> $
                      {details?.total_amount_request}
                    </div>
                    <div
                      className="grid-item"
                      style={{ textTransform: "capitalize" }}
                    >
                      <b> {t("REQUEST_STATUS")} :</b> {details?.request_status}
                    </div>
                  </div>
                </div>
              </div>
              <div className="view_desyt">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="orderd" style={{ width: "80px" }}>
                          {t("S_NO")}.
                        </th>
                        <th className="orderd">{t("WORKER_NAME")}</th>
                        <th className="orderd">{t("EMAIL")}</th>
                        <th className="orderd">{t("COUNTRY")}</th>
                        <th className="orderd">{t("REQUEST_DATE")}</th>
                        <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.map((item, index) => (
                        <tr key={"1" + index}>
                          <td>{index + 1}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {" "}
                            {item.worker_name}
                          </td>
                          <td>{item.worker_email}</td>
                          <td>
                            <img
                              src={
                                item?.worker_country_id != undefined
                                  ? item?.worker_country_id[0].flag_url
                                  : ""
                              }
                              className="tab_flag"
                            />{" "}
                            {item.worker_country_id[0].name}
                          </td>
                          {/* <td>{item.request_date}</td> */}
                          <td>
                            {format(
                              parseISO(item.request_date),
                              " dd MMM yyyy"
                            )}
                          </td>
                          <td>{"$" + item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="view_car1">
                {/* <div className="img_views_rep">
                  <img className="img_r" src={details?.receipts_image} />
                </div> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="down_respt my-4" style={{ width: 230 }}>
                      <img
                        className="img_r"
                        src={
                          details?.receipts_image != undefined
                            ? baseURL + details?.receipts_image
                            : "./public/img/image_n.png"
                        }
                        alt=""
                      />

                      <h4>{t("RECEIPT")} </h4>
                      <button
                        className="btn"
                        onClick={() => downloadImage(baseURL + details?.receipts_image)}
                      >
                        <i class="uil uil-import"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="view_car2">
                  <h4>
                    <span className="na1">{t("TOTAL_WORKER")} : </span>
                    <span className="na2">{record.length}</span>
                  </h4>
                  {/* <h4 className="tottt1">
                    <span className="na1">Total request amount : </span>
                    <span className="na2">${totalamount}</span>
                  </h4> */}
                  <h4>
                    <span className="na1">{t("REQUEST_AMOUNT")} : </span>
                    <span className="na2">${totalamount}</span>
                  </h4>
                  <h4>
                    <span className="na1">{t("SPONSOR_PERCENTAGE")} : </span>
                    <span className="na2">
                      $ {Math.floor(totalamount * 0.1)}
                    </span>
                  </h4>
                  <h4 className="tottt1">
                    <span className="na1">{t("TOTAL_REQUEST_AMOUNT")} : </span>
                    <span className="na2">
                      ${Math.floor(totalamount + totalamount * 0.1)}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Viewpaymentdetails;
