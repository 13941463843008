/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
function Viewdetailspayment() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const routeId = useParams();
  const { openside, getPaymentRecivedDetails, DownlodImag } =
    useContext(ApiContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [record, setRecord] = useState([]);
  const [datacount, setDataCount] = useState();
  const [totalamount, setTotalAmount] = useState("");
  const [sponsorData, setSponsorData] = useState();
  useEffect(() => {
    let data = localStorage.getItem("data");
    console.log(data?.receipts_image);
    setSponsorData(JSON.parse(data));
    getPaymentRecivedDetails(
      limit,
      page,
      setCountPage,
      setRecord,
      setDataCount,
      routeId.id,
      routeId.id1,
      setTotalAmount
    );
  }, [limit, page]);

  const downloadImage = (image) => {
    DownlodImag(image);
  };
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("VIEW_PAYMENT_DETAILS")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="view_desyt">
                <div className=" mb-4 ">
                  <div className="deatail_us3">
                    <div className="grid-container">
                      <div className="grid-item">
                        <span>
                          {" "}
                          <b>{t("SPONSOR_NAME")} :</b>{" "}
                          {sponsorData?.sponser_name}
                        </span>
                      </div>
                      <div className="grid-item">
                        <b>{t("EMAIL")} :</b> {sponsorData?.email}
                      </div>
                      <div className="grid-item">
                        <b>{t("REQUEST_ID")} :</b>{" "}
                        {sponsorData?.payment_request_id == null
                          ? "NA"
                          : sponsorData?.payment_request_id}
                      </div>
                      <div className="grid-item">
                        <b>{t("WALLET_ADDRESS")} :</b>{" "}
                        {sponsorData?.sponser_binance_acc_id?.binance_pay_id ==
                          null
                          ? "NA"
                          : sponsorData?.sponser_binance_acc_id?.binance_pay_id}
                      </div>

                      <div className="grid-item">
                        <b>{t("TOTAL_WORKER")} :</b>{" "}
                        {record.length == null ? "NA" : record.length}
                      </div>
                      <div className="grid-item">
                        <b>{t("REQUEST_AMOUNT")} :</b>{" "}
                        {"$" + sponsorData?.total_amount_request == null
                          ? "NA"
                          : "$" + sponsorData?.total_amount_request}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="orderd" style={{ width: "80px" }}>
                          {t("S_NO")}.
                        </th>
                        <th className="orderd">{t("WORKER_NAME")}</th>
                        <th className="orderd">{t("EMAIL")}</th>
                        <th className="orderd">{t("COUNTRY")}</th>
                        <th className="orderd">{t("REQUEST_DATE")}</th>
                        <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.map((item, index) => (
                        <tr key={"1" + index}>
                          <td>{index + 1}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {" "}
                            {item.worker_name}
                          </td>
                          <td>{item.worker_email}</td>
                          <td>
                            <img
                              src={
                                item?.worker_country_id != undefined
                                  ? item?.worker_country_id[0].flag_url
                                  : ""
                              }
                              className="tab_flag"
                            />{" "}
                            {item.worker_country_id[0].name}
                          </td>
                          <td>
                            {" "}
                            {format(
                              parseISO(item.request_date),
                              "dd MMM yyyy, HH:mm"
                            )}
                          </td>
                          <td>{"$" + item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="view_car1">
                {sponsorData?.receipts_image != undefined && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="down_respt my-4" style={{ width: 230 }}>
                        <img
                          className="img_r"
                          src={
                            sponsorData?.receipts_image != undefined
                              ? baseURL + sponsorData?.receipts_image
                              : "./public/img/image_n.png"
                          }
                          alt=""
                        />

                        <h4>{t("RECEIPT")} </h4>
                        <button
                          className="btn"
                          onClick={() =>
                            downloadImage(baseURL + sponsorData?.receipts_image)
                          }
                        >
                          <i class="uil uil-import"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="view_car2">
                  <h4>
                    <span className="na1">{t("TOTAL_WORKER")} : </span>
                    <span className="na2">{record.length}</span>
                  </h4>
                  <h4>
                    <span className="na1">{t("REQUEST_AMOUNT")} : </span>
                    <span className="na2">${totalamount}</span>
                  </h4>
                  <h4>
                    <span className="na1">{t("SPONSOR_PERCENTAGE")} : </span>
                    <span className="na2">
                      $ {Math.floor(totalamount * 0.1)}
                    </span>
                  </h4>
                  <h4 className="tottt1">
                    <span className="na1">{t("TOTAL_REQUEST_AMOUNT")} : </span>
                    <span className="na2">
                      ${Math.floor(totalamount + totalamount * 0.1)}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Viewdetailspayment;
