/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ApiContext from "../../context/ApiContext";
import { Calendar } from "primereact/calendar";
import { toast } from "react-toastify";
import Header from "../../Admin/CommonPage/Header";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
function Binance() {
  const { t } = useTranslation();
  const { openside, getBinanceAccount, createBinanceAccount } =
    useContext(ApiContext);
  const [binanceData, setBinanceData] = useState([]);
  const [type, setType] = useState(0);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const namePattern = /^[a-zA-Z][a-zA-Z0-9 ]*$/;

  useEffect(() => {
    getBinanceAccount(setBinanceData, setType);
  }, [0]);
  const validationSchema = Yup.object().shape({
    BinanceEmailId: Yup.string()
      .matches(emailPattern, `${t("INVALID_EMAIL_FORMAT")}`)
      .required(`${t("BINANCE_EMAIL_IS_REQUIRED")}`),
    BinancePayId: Yup.string()
      .matches(namePattern, `${t("NAME_VALIDATION")}`)
      .required(`${t("BINANCE_PAY_ID_IS_REQUIRED")}`),
  });

  const formik = useFormik({
    initialValues: {
      BinanceEmailId: "",
      BinancePayId: "",
    },
    validationSchema,
    onSubmit: (data) => {
      console.log(data);
      if (type == 2) {
        data.BinanceId = binanceData.binance_acc_id;
      }
      createBinanceAccount(data, afterCreate);
      // loginUser(data?.email, data?.password);
    },
  });

  const afterCreate = () => {
    getBinanceAccount(setBinanceData, setType);
  };
  const openUpdate = () => {
    formik.setFieldValue("BinanceEmailId", binanceData?.binance_email_id);
    formik.setFieldValue("BinancePayId", binanceData?.binance_pay_id);
    setType(2);
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("BINANCE")} />
        <div className="wrap_us conten_set">
          <div className="row">
            <div className="col-md-7">
              <div className="card_me">
                <div className="card_body">
                  <div className="">
                    <div className="mb-3">
                      {""}
                      {/* {binanceData.length == 0 && (
                    
                  )} */}
                      {type == 0 && (
                        <div className="text-end">
                          <button
                            className="btn btn_theme"
                            onClick={openUpdate}
                          >
                            {t("UPDATE_ACCOUNT")}
                          </button>
                        </div>
                      )}
                      {type == 2 && (
                        <button
                          className="btn btn_theme3"
                          onClick={() => setType(0)}
                        >
                          {t("BACK")}
                        </button>
                      )}
                    </div>

                    {type == 0 && (
                      <div className="ddd">
                        <div className="mb-3">
                          {t("BINANCE_EMAIL")}{" "}
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={binanceData.binance_email_id}
                            readOnly
                          />
                        </div>
                        <div className="mb-3">
                          {t("BINANCE_ID")}{" "}
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={binanceData.binance_pay_id}
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                    {type == 1 && (
                      <div className="dddddd">
                        <div className="" style={{ marginBottom: "20px" }}>
                          <h4> {t("ADD_YOUR_BINANCE_ACCOUNT")}</h4>
                        </div>
                        <div>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="mb-3">
                              {t("BINANCE_EMAIL")}{" "}
                              <input
                                type="text"
                                name="BinanceEmailId"
                                className={
                                  "form-control" +
                                  (formik.errors.BinanceEmailId &&
                                  formik.touched.BinanceEmailId
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={formik.handleChange}
                                value={formik.values.BinanceEmailId}
                              />
                            </div>
                            <div className="invalid-feedback">
                              {formik.errors.BinanceEmailId &&
                              formik.touched.BinanceEmailId
                                ? formik.errors.BinanceEmailId
                                : null}
                            </div>
                            <div className="mb-3">
                              {t("BINANCE_ID")}{" "}
                              <input
                                type="text"
                                name="BinancePayId"
                                className={
                                  "form-control" +
                                  (formik.errors.BinancePayId &&
                                  formik.touched.BinancePayId
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={formik.handleChange}
                                value={formik.values.BinancePayId}
                              />
                            </div>
                            <div className="invalid-feedback">
                              {formik.errors.BinancePayId &&
                              formik.touched.BinancePayId
                                ? formik.errors.BinancePayId
                                : null}
                            </div>
                            <div className="text-end">
                              <button className="btn btn_theme" type="submit">
                                {t("ADD")}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {type == 2 && (
                      <div className="">
                        <div className="" style={{ marginBottom: "20px" }}>
                          <h4> {t("UPDATE_YOUR_BINANCE_ACCOUNT")}</h4>
                        </div>
                        <div>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="mb-3">
                              {t("BINANCE_EMAIL")}{" "}
                              <input
                                type="text"
                                name="BinanceEmailId"
                                className={
                                  "form-control" +
                                  (formik.errors.BinanceEmailId &&
                                  formik.touched.BinanceEmailId
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={formik.handleChange}
                                value={formik.values.BinanceEmailId}
                              />
                            </div>
                            <div className="invalid-feedback">
                              {formik.errors.BinanceEmailId &&
                              formik.touched.BinanceEmailId
                                ? formik.errors.BinanceEmailId
                                : null}
                            </div>
                            <div className="mb-3">
                              {t("BINANCE_ID")}{" "}
                              <input
                                type="text"
                                name="BinancePayId"
                                className={
                                  "form-control" +
                                  (formik.errors.BinancePayId &&
                                  formik.touched.BinancePayId
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={formik.handleChange}
                                value={formik.values.BinancePayId}
                              />
                            </div>
                            <div className="invalid-feedback">
                              {formik.errors.BinancePayId &&
                              formik.touched.BinancePayId
                                ? formik.errors.BinancePayId
                                : null}
                            </div>
                            <div className="text-end">
                              <button className="btn btn_theme" type="submit">
                                {t("UPDATE")}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Binance;
