/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useEffect } from "react";
import { parseISO, format } from "date-fns";
import { Tab, Tabs } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
function Users() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openside, getAllusers, getAllWorker, activeDeactivaeSponsor } =
    useContext(ApiContext);
  const [limit, setLimit] = useState(10);
  const [workerlimit, setWorkerLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [Workerpage, setWorkerPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [workercountPage, setWorkerCountPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [workersearch, setWorkerSearch] = useState("");
  const [datacount, setDataCount] = useState();
  const [workerdatacount, setWorkerDataCount] = useState();
  let [allUsers, setAllUsers] = useState([]);
  let [allworker, setAllWorker] = useState([]);
  const [dates, setDates] = useState("");
  const [workerdates, setWorkerDates] = useState("");

  useEffect(() => {
    getAllusers(
      limit,
      page,
      setLoader,
      setCountPage,
      setAllUsers,
      search,
      setDataCount,
      "",
      ""
    );
  }, [page, limit, search]);
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  useEffect(() => {
    getAllWorker(
      workerlimit,
      Workerpage,
      setLoader,
      setWorkerCountPage,
      setAllWorker,
      workersearch,
      "approve",
      setWorkerDataCount,
      "",
      ""
    );
  }, [Workerpage, workerlimit, workersearch]);

  function activate(status, id, type) {
    let user = type == 0 ? t("USER") : t("WORKER");
    let msg =
      status === false
        ? `${user} ${t("DEACTIVATED_SUCCESSFULLY")}`
        : `${user} ${t("ACTIVATED_SUCCESSFULLY")}`;

    activeDeactivaeSponsor(msg, id);
  }

  function viewUser(item) {
    localStorage.setItem("data", JSON.stringify(item));
    navigate("/view_user_Details/" + item.id);
  }

  function viewWorker(item) {
    localStorage.setItem("data", JSON.stringify(item));
    navigate("/view_Worker_Details/" + item.id);
  }

  const setDateRange = (type) => {
    const first = moment(type == 0 ? dates[0] : workerdates[0]).format(
      "YYYY-MM-DD"
    );
    const second = moment(type == 0 ? dates[1] : workerdates[1]).format(
      "YYYY-MM-DD"
    );
    if (first != "Invalid date" && second != "Invalid date") {
      if (type == 0) {
        getAllusers(
          limit,
          page,
          setLoader,
          setCountPage,
          setAllUsers,
          search,
          setDataCount,
          first,
          second
        );
      } else {
        getAllWorker(
          workerlimit,
          Workerpage,
          setLoader,
          setWorkerCountPage,
          setAllWorker,
          workersearch,
          "approve",
          setWorkerDataCount,
          first,
          second
        );
      }
    } else {
      toast.warning("Please Enter Valid Date");
    }
  };

  const changeTab = (e) => {
    if (e === "users") {
      setWorkerSearch("");
    } else if (e === "workers") {
      setSearch("");
    }
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("USERS")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <Tabs
                defaultActiveKey="users"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(e) => changeTab(e)}
              >
                <Tab eventKey="users" title={t("USERS")}>
                  <div className="">
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label>{t("SHOW_ENTRIES")}</label>
                        <select
                          className="form-control"
                          defaultValue="10"
                          onChange={(e) => {
                            e.stopPropagation();
                            setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="calenderd1">
                        <Calendar
                          value={dates}
                          onChange={(e) => setDates(e.value)}
                          selectionMode="range"
                          readOnlyInput
                          placeholder={t("SEARCH_WITH_DATE")}
                          minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                          maxDate={new Date()}
                        />

                        <button
                          className="btn btn_theme btn-sm"
                          disabled={dates == ""}
                          onClick={() => setDateRange(0)}
                        >
                          {t("FIND")}
                        </button>
                      </div>
                      <div className="right_shiowp">
                        <label> {t("SEARCH")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("SEARCH")}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd">{t("S_NO")}</th>
                            <th className="orderd">{t("USER_NAME")}</th>
                            <th className="orderd">{t("EMAIL")}</th>
                            <th className="orderd">{t("CONTECT")}</th>
                            <th className="orderd">{t("COUNTRY")}</th>
                            <th className="orderd">{t("LANGUAGE")}</th>
                            <th className="orderd">{t("DATE")}</th>
                            <th className="orderd">{t("WARNING")}</th>
                            <th className="orderd">
                              {t("ACTIVATE_DEACTIVATE")}
                            </th>
                            <th style={{ width: "80px" }}>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUsers.map((item, index) => (
                            <tr key={"tr" + index}>
                              {/* <td>{index + 1}</td> */}
                              <td>
                                {page > 1
                                  ? limit * page - limit + index + 1
                                  : index + 1}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item?.display_name != undefined
                                  ? item?.display_name
                                  : "-"}
                              </td>
                              <td>{item?.email}</td>
                              <td>{item?.phone}</td>
                              <td>
                                <img
                                  src={
                                    item?.country_id != null
                                      ? item?.country_id[0]?.flag_url
                                      : ""
                                  }
                                  className="tab_flag"
                                />{" "}
                                {item?.country_id === null
                                  ? "-"
                                  : item?.country_id[0]?.name}
                              </td>
                              <td>
                                {" "}
                                {item.language == "en" ? "English" : "Spanish"}
                              </td>
                              <td>
                                {" "}
                                {format(
                                  parseISO(item?.date_joined),
                                  "dd MMM yyyy"
                                )}
                              </td>
                              <td>{item?.total_warning}</td>
                              <td>
                                <div className="switchh">
                                  <input
                                    data-tooltip-id="view"
                                    data-tooltip-content={
                                      item?.login_permission
                                        ? t("DEACTIVAT")
                                        : t("ACTIVATED")
                                    }
                                    type="checkbox"
                                    defaultChecked={
                                      item?.login_permission === true
                                    }
                                    onChange={(e) =>
                                      activate(e.target.checked, item?.id, 0)
                                    }
                                  />
                                  <Tooltip id="view" />
                                </div>
                              </td>
                              <td>
                                <span className="btn_us1">
                                  <button
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("VIEW_DETAILS")}
                                    className="btn btn_clr"
                                    onClick={() => viewUser(item)}
                                  >
                                    <img src="img/view.png" alt="" />
                                  </button>
                                  <Tooltip id="view" />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {allUsers.length == 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                      </div>
                    )}
                    {allUsers.length > 0 && (
                      <div className="pag_botm">
                        <div className="left_neu">
                          <p>
                            {/* {limit * page - limit + 1} */}
                            {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                            {limit > datacount
                              ? datacount
                              : page * limit > datacount
                              ? datacount
                              : page * limit}{" "}
                            {t("OF")} {datacount} {t("ENTRIES")}
                          </p>
                        </div>
                        <div className="pagddination">
                          <button
                            className="btn prew_1"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (page > 1) {
                                setPage(page - 1);
                              }
                            }}
                          >
                            {t("PREVIOUS")}
                          </button>{" "}
                          <input
                            className="form-control"
                            type="text"
                            style={{ textAlign: "center" }}
                            value={page}
                            readOnly
                          />{" "}
                          <button
                            className="btn next_bth"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (countPage > page) {
                                setPage(page + 1);
                              }
                            }}
                          >
                            {t("NEXT")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab>

                <Tab eventKey="workers" title={t("WORKERS")}>
                  <div className="">
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label>{t("SHOW_ENTRIES")}</label>
                        <select
                          className="form-control"
                          defaultValue="10"
                          onChange={(e) => {
                            e.stopPropagation();
                            setWorkerLimit(e.target.value);
                            setWorkerPage(1);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="calenderd1">
                        <Calendar
                          value={workerdates}
                          onChange={(e) => setWorkerDates(e.value)}
                          selectionMode="range"
                          readOnlyInput
                          placeholder={t("SEARCH_WITH_DATE")}
                          minDate={new Date(2023, 9, 1)} // Restrict to current date onwards
                          maxDate={new Date()}
                        />
                        <button
                          className="btn btn_theme btn-sm"
                          disabled={workerdates == ""}
                          onClick={() => setDateRange(1)}
                        >
                          {t("FIND")}
                        </button>
                      </div>
                      <div className="right_shiowp">
                        <label>{t("SEARCH")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("SEARCH")}
                          value={workersearch}
                          onChange={(e) => setWorkerSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd">{t("S_NO")}.</th>
                            <th className="orderd"> {t("USER_NAME")} </th>
                            <th className="orderd">{t("EMAIL")}</th>
                            <th className="orderd"> {t("CONTECT")}</th>
                            <th className="orderd"> {t("COUNTRY")}</th>
                            <th className="orderd">{t("LANGUAGE")}</th>
                            <th className="orderd">{t("JOINING_DATE")}</th>
                            <th className="orderd">{t("WARNING")}</th>
                            <th className="orderd">
                              {t("ACTIVATE_DEACTIVATE")}
                            </th>
                            <th style={{ width: "80px" }}>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allworker.map((item, index) => (
                            <tr key={"tr" + index}>
                              <td>
                                {Workerpage > 1
                                  ? workerlimit * Workerpage -
                                    workerlimit +
                                    index +
                                    1
                                  : index + 1}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item?.display_name != undefined
                                  ? item?.display_name
                                  : "-"}
                              </td>
                              <td>{item?.email}</td>
                              <td>{item?.phone}</td>
                              <td>
                                <img
                                  src={
                                    item?.country_id != null
                                      ? item?.country_id[0]?.flag_url
                                      : ""
                                  }
                                  className="tab_flag"
                                />{" "}
                                {item?.country_id === null
                                  ? "-"
                                  : item?.country_id[0]?.name}
                              </td>
                              <td>
                                {" "}
                                {item.language == "en" ? "English" : "Spanish"}
                              </td>
                              <td>
                                {" "}
                                {format(
                                  parseISO(item.date_joined),
                                  "dd MMM yyyy"
                                )}
                              </td>
                              <td>{item?.total_warning}</td>
                              <td>
                                <div className="switchh">
                                  <input
                                    data-tooltip-id="view"
                                    data-tooltip-content={
                                      item.login_permission
                                        ? t("DEACTIVAT")
                                        : t("ACTIVATED")
                                    }
                                    type="checkbox"
                                    defaultChecked={
                                      item.login_permission === true
                                    }
                                    onChange={(e) =>
                                      activate(e.target.checked, item.id, 1)
                                    }
                                  />
                                  <Tooltip id="view" />
                                </div>
                              </td>
                              <td>
                                <span className="btn_us1">
                                  <button
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("VIEW_DETAILS")}
                                    className="btn btn_clr"
                                    onClick={() => viewWorker(item)}
                                  >
                                    <img src="img/view.png" alt="" />
                                  </button>
                                  <Tooltip id="view" />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {allworker.length == 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                      </div>
                    )}

                    {allworker.length != 0 && (
                      <div className="pag_botm">
                        <div className="left_neu">
                          <p>
                            {t("SHOWING")}{" "}
                            {workerlimit * Workerpage - workerlimit + 1}{" "}
                            {t("TO")}{" "}
                            {workerlimit > workerdatacount
                              ? workerdatacount
                              : Workerpage * workerlimit > workerdatacount
                              ? workerdatacount
                              : Workerpage * workerlimit}{" "}
                            {t("OF")} {workerdatacount} {t("ENTRIES")}
                          </p>
                        </div>
                        <div className="pagddination">
                          <button
                            className="btn prew_1"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (Workerpage > 1) {
                                setWorkerPage(Workerpage - 1);
                              }
                            }}
                          >
                            {t("PREVIOUS")}
                          </button>{" "}
                          <input
                            className="form-control"
                            type="text"
                            style={{ textAlign: "center" }}
                            value={Workerpage}
                            readOnly
                          />{" "}
                          <button
                            className="btn next_bth"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (workercountPage > Workerpage) {
                                setWorkerPage(Workerpage + 1);
                              }
                            }}
                          >
                            {t("NEXT")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Users;
