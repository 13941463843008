import { useContext, useEffect, useState } from "react";
import Header from "../../Admin/CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

function Sponsorwarning(params) {
  const { t } = useTranslation();
  const { openside, getAllWarning } = useContext(ApiContext);
  const [warninglimit, setWarningLimit] = useState(10);
  const [warningpage, setWarningPage] = useState(1);
  const [warningcountpage, setWarningCountPage] = useState(1);
  const [warningdatacount, setWarningDataCount] = useState();
  const [search, setSearch] = useState("");
  const [allWarning, setAllWarning] = useState([]);
  const [warningData, setWarningData] = useState([]);
  const [sponserID, setSponserID] = useState();

  useEffect(() => {
    let sponsorId = localStorage.getItem("S_TO");
    setSponserID(sponsorId);
  }, []);

  useEffect(() => {
    if (sponserID != undefined) {
      getAllWarning(
        sponserID,
        warningpage,
        warninglimit,
        setWarningCountPage,
        setWarningDataCount,
        setWarningData
      );
    }
  }, [getAllWarning, sponserID, warninglimit, warningpage]);

  return (
    <div className={`main_slide ${openside ? "open_slider" : ""}`}>
      <Header name={t("WARNING")} />
      <div className="wrap_us conten_set">
        <div className="card_me table_des1">
          <div className="card_body">
            <div className="saech_des">
              <div className="left_shiowp">
                <label>{t("SHOW_ENTRIES")}</label>
                <select
                  className="form-control"
                  defaultValue="5"
                  onChange={(e) => {
                    e.stopPropagation();
                    setWarningLimit(e.target.value);
                    setWarningPage(1);
                  }}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="right_shiowp">
                <label>{t("SEARCH")}</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("SEARCH")}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th style={{ width: "80px" }}>{t("S_NO")}.</th>
                    <th style={{ width: "120px" }}>{t("PREVIOUS_WARNING")}</th>
                    <th>{t("WARNING")}</th>
                    <th style={{ width: "250px" }}>{t("WARNING_DATE")}</th>
                  </tr>
                </thead>
                <tbody>
                  {warningData.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {/* <td>{row.sponser_name}</td> */}
                      <td>{row.previous_warning}</td>
                      <td>{row.reason}</td>
                      <td>
                        {format(
                          parseISO(row.create_date_time),
                          "dd MMM yyyy, HH:mm"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {warningData.length == 0 && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
              </div>
            )}
            {warningData.length > 0 && (
              <div className="pag_botm">
                <div className="left_neu">
                  <p>
                    {t("SHOWING")}{" "}
                    {warninglimit * warningpage - warninglimit + 1} {t("TO")}{" "}
                    {warninglimit > warningdatacount
                      ? warningdatacount
                      : warningpage * warninglimit > warningdatacount
                      ? warningdatacount
                      : warningpage * warninglimit}{" "}
                    {t("OF")} {warningdatacount} {t("ENTRIES")}
                  </p>
                </div>
                <div className="pagddination">
                  <button
                    className="btn prew_1"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (warningpage > 1) {
                        setWarningPage(warningpage - 1);
                      }
                    }}
                  >
                    {t("PREVIOUS")}
                  </button>{" "}
                  <input
                    // defaultValue={warningpage}
                    className="form-control"
                    type="text"
                    style={{ textAlign: "center" }}
                    value={warningpage}
                    readOnly
                  />{" "}
                  <button
                    className="btn next_bth"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (warningcountpage > warningpage) {
                        setWarningPage(warningpage + 1);
                      }
                    }}
                  >
                    {t("NEXT")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

    //   {/* <Modal show={show} onHide={modalClose}>
    //       <Modal.Header closeButton></Modal.Header>
    //       <Modal.Body>
    //         <div className="faqs_modal">
    //           <div>
    //             <p>
    //               <b
    //                 dangerouslySetInnerHTML={{
    //                   __html: selectedData?.question,
    //                 }}
    //               ></b>
    //             </p>
    //           </div>
    //           <div>
    //             <p
    //               dangerouslySetInnerHTML={{
    //                 __html: selectedData?.answer,
    //               }}
    //             ></p>
    //           </div>
    //         </div>
    //       </Modal.Body>
    //     </Modal> */}
    // </div>
  );
}
export default Sponsorwarning;
