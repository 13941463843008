import { createContext, useContext, useEffect, useState } from "react";

// import AuthContext from "./AuthContext";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
const ApiContext = createContext();
export default ApiContext;
export const ApiProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  // const { authTokens } = useContext(AuthContext);
  const navigate = useNavigate();
  const api = useAxios();
  const [openside, setOpenSide] = useState(true);

  async function chnageLanguage(item) {
    try {
      const response = await api.post(
        `${baseURL}/vid_user/ChangeLanguage/`,
        item
      );
      const data = await response.data;
      console.log(data);
      if (data.code === 200) {
        // toast.success(`${t(data.CallDetail)}`);
        localStorage.setItem("lng", item.language);
        i18n.changeLanguage(item.language);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      console.error("createSponsor", error);
    }
  }
  async function getSelectedLanguage(item) {
    try {
      const response = await api.post(`${baseURL}/vid_user/CheckStatus/`, item);
      const data = await response.data;
      console.log(data);
      if (data.code === 200) {
        // i18n.changeLanguage(data.StatusData.language);
        localStorage.setItem("lng", data.StatusData.language);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      console.error("createSponsor", error);
    }
  }

  async function getAllCountry(setCountry) {
    try {
      const response = await api.get(`${baseURL}/vid_user/country_list/`);
      const data = await response.data;
      if (data.code === 200) {
        setCountry(data.data);
      } else {
        setCountry([]);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async function getAllSponsor(
    limit,
    page,
    setDataCount,
    setCountPage,
    setRecords,
    search,
    setLoader
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    try {
      setLoader(true);
      const response = await api.get(
        `${baseURL}/vid_user/sponser_list/?page=${page}&limit=${limit}${filter}`
      );
      const data = await response.data;
      if (data.code === 200) {
        setLoader(false);
        var pageCount = Math.ceil(response.data.count / limit);
        setRecords(response.data.data);
        setCountPage(pageCount);
        setDataCount(response.data.count);
      } else {
        setLoader(false);
        setRecords([]);
      }
    } catch (error) {
      console.warn(error);
      setLoader(false);
    }
  }
  async function createSponsor(value) {
    try {
      const response = await api.post(`${baseURL}/vid_user/register`, {
        display_name:
          value.name.slice(0, 1).toUpperCase() +
          value.name.slice(1, value.name.length),
        email: value.email,
        password: value.password,
        country_id: value.country,
        country_id: value.country,
        role_id: "sponser",
      });
      const data = await response.data;
      if (data.code === 201) {
        toast.success(`${t("SPONSOR_CREATED_SUCCESSFULLY")}`);
        navigate("/sponsor");
      } else {
        // toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
        toast.error(data.message);
      }
    } catch (error) {
      toast.warning(error.response.data.message);
      console.error("createSponsor", error);
    }
  }

  async function updateSponsor(value, id) {
    let obj = {
      display_name:
        value.name.slice(0, 1).toUpperCase() +
        value.name.slice(1, value.name.length),
      email: value.email,
      password: value.password,
      country_id: value.country,
      language: value.language,
      sponser_id: id,
    };

    try {
      const response = await api.post(
        `${baseURL}/vid_user/update_sponser_detail`,
        obj
      );
      const data = await response.data;
      if (data.code === 200) {
        toast.success(`${t("SPONSOR_UPDATED_SUCCESSFULLY")}`);
        navigate("/sponsor");
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.warning(error.response.data.message);
      console.error(error);
    }
  }

  const deleteSponsor = async (id) => {
    try {
      const response = await api.delete(
        `${baseURL}/vid_user/delete_sponser/${id}`
      );
      const data = await response.data;

      if (data.code === 200) {
        toast.success(`${t("SPONSOR_DELETED_SUCCESSFULLY")}`);
        navigate("/sponsor");
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.warning(error.response.data.message);
      console.error(error);
    }
  };

  const activeDeactivaeSponsor = async (msg, id) => {
    let obj = { sponser_id: id };
    try {
      const response = await api.post(
        `${baseURL}/vid_user/login_permission`,
        obj
      );
      const data = await response.data;
      if (data.code === 200) {
        toast.success(msg);
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.warning(error.response.data.message);
      console.error(error);
    }
  };

  async function getAllPaymentRequest(
    limit,
    page,
    setCountPage,
    setRecords,
    search,
    setDataCount
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    try {
      const response = await api.get(
        `${baseURL}/vid_user/payment_request_list/?page=${page}&limit=${limit}${filter}`
      );
      const data = await response.data;
      if (data.code === 200) {
        var pageCount = Math.ceil(response.data.count / limit);
        setRecords(response.data.data);
        setCountPage(pageCount);
        setDataCount(response.data.count);
      } else {
        setRecords([]);
      }
    } catch (error) {
      console.warn(error);
      setRecords([]);
    }
  }

  async function getWorkerBySponsorID(
    id,
    setWorker,
    page,
    limit,
    search,
    setDataCount,
    setCountPage
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    try {
      const response = await api.get(
        `${baseURL}/vid_user/worker_list/?sponser_id=${id}&page=${page}&limit=${limit}${filter}`
      );
      const data = await response.data;
      if (data.code === 200) {
        var pageCount = Math.round(response.data.count / limit);
        setWorker(response.data.data);
        setCountPage(pageCount);
        setDataCount(response.data.count);
      } else {
        setWorker([]);
      }
    } catch (error) {
      console.warn(error);
      setWorker([]);
    }
  }

  async function getPaymentRequestBySponsorId(
    id,
    setPaymentRequest,
    limit,
    page,
    setRequestCountPage,
    setRequestDataCount
  ) {
    try {
      const response = await api.get(
        `${baseURL}/vid_user/sponser_payment_request_list/?sponser_id=${id}&page=${page}&limit=${limit}`
      );
      const data = await response.data;
      if (data.code === 200) {
        var pageCount = Math.round(response.data.count / limit);
        setPaymentRequest(response.data.data);
        setRequestCountPage(pageCount);
        setRequestDataCount(response.data.count);
      } else {
        setPaymentRequest([]);
      }
    } catch (error) {
      console.warn(error);
      setPaymentRequest([]);
    }
  }

  async function getPaymentHistoryBySponsorId(
    id,
    setPaymentHistory,
    page,
    limit,
    setWarningCountPage,
    setWarningDataCount
  ) {
    try {
      const response = await api.get(
        `${baseURL}/vid_user/sponser_payment_history_list/?sponser_id=${id}&page=${page}&limit=${limit}`
      );
      const data = await response.data;
      if (data.code === 200) {
        var pageCount = Math.ceil(response.data.count / limit);
        setPaymentHistory(response.data.data);
        setWarningCountPage(pageCount);
        setWarningDataCount(response.data.count);
      } else {
        setPaymentHistory([]);
      }
    } catch (error) {
      console.warn(error);
      setPaymentHistory([]);
    }
  }

  //Get All Workers
  async function getAllWorker(
    limit,
    page,
    setLoader,
    setCountPage,
    setRecords,
    search,
    status,
    setDataCount,
    first,
    second
  ) {
    setLoader(true);
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != "" ? `&start_date=${first}` : "";
    let end_date = second != "" ? `&end_date=${second}` : "";
    try {
      const response = await api.get(
        `${baseURL}/vid_user/worker_list/?page=${page}&limit=${limit}&account_approval_state=${status}${filter}${start_date}${end_date}`
      );
      const data = await response?.data;
      if (data?.code === 200) {
        var pageCount = Math.ceil(response?.data.count / limit);
        setLoader(false);
        setRecords(response?.data?.data);
        setCountPage(pageCount);
        setDataCount(response?.data?.count);
      } else {
        setLoader(false);
        setRecords([]);
        setCountPage(0);
        setDataCount(0);
      }
    } catch (error) {
      setLoader(false);
      console.warn(error);
      setRecords([]);
      setCountPage(0);
      setDataCount(0);
    }
  }

  async function getApprovedWorker(
    workerlimit,
    workerpage,
    workercountPage,
    setWorkerList,
    search,
    status,
    setworkerCount,
    first,
    second
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      const response = await api.get(
        `${baseURL}/vid_user/worker_list/?page=${workerpage}&limit=${workerlimit}&account_approval_state=${status}${filter}${start_date}${end_date}`
      );
      const data = await response?.data;
      if (data?.code === 200) {
        var pageCount = Math.ceil(response?.data.count / workerlimit);
        setWorkerList(response?.data?.data);
        workercountPage(pageCount);
        setworkerCount(response?.data?.count);
      } else {
        setWorkerList([]);
      }
    } catch (error) {
      console.warn(error);
      setWorkerList([]);
    }
  }

  // async function updateWorkerImgVideo(id) {
  //   let obj = { worker_id: id };
  //   try {
  //     const response = await api.post(
  //       `${baseURL}/vid_user/approve_identity`,
  //       obj
  //     );
  //     const data = await response.data;
  //
  //     if (data.code === 200) {
  //       alert("Worker Activated successfully");
  //       navigate("/Workerlist");
  //     } else {
  //       toast.warning("Something went wrong");
  //     }
  //   } catch (error) {
  //     console.error("createSponsor", error);
  //   }
  // }

  async function paymentRecevied(
    limit,
    page,
    setLoader,
    setCountPage,
    setRecivedPayment,
    search,
    setDataCount,
    first,
    second
  ) {
    setLoader(true);
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      const response = await api.get(
        `${baseURL}/vid_user/payment_received_list/?page=${page}&limit=${limit}${filter}${start_date}${end_date}`
      );
      const data = await response.data;
      if (data.code === 200) {
        setLoader(false);
        var pageCount = Math.ceil(response.data.count / limit);
        setRecivedPayment(response.data.data);
        setCountPage(pageCount);
        setDataCount(response.data.count);
      } else {
        setLoader(false);
        setRecivedPayment([]);
      }
    } catch (error) {
      setLoader(false);
      console.warn(error);
      setRecivedPayment([]);
    }
  }

  async function paymentHistory(
    limit,
    page,
    setLoader,
    setCountPage,
    setPaymenthistory,
    search,
    setDataCount,
    first,
    second
  ) {
    setLoader(true);
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      const response = await api.get(
        `${baseURL}/vid_user/payment_history_list/?page=${page}&limit=${limit}${filter}${start_date}${end_date}`
      );
      const data = await response.data;
      if (data.code === 200) {
        setLoader(false);
        var pageCount = Math.ceil(response.data.count / limit);
        setPaymenthistory(response.data.data);
        setCountPage(pageCount);
        setDataCount(response.data.count);
      } else {
        setLoader(false);
        setPaymenthistory([]);
      }
    } catch (error) {
      setLoader(false);
      console.warn(error);
      setPaymenthistory([]);
    }
  }

  async function getCreditCoiinList(
    limit,
    page,
    setCountPage,
    setcoinList,
    setCoinTotalCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_customer/coin_list/?page=${page}&limit=${limit}`
      );
      const data = await res?.data;
      if (res?.status === 200) {
        var pageCount = Math.ceil(data?.count / limit);
        setcoinList(data?.data);
        setCountPage(pageCount);
        setCoinTotalCount(data?.count);
      }
    } catch (error) {
      console.log(error);
      setcoinList([]);
    }
  }

  async function getGiftList(
    giftlimit,
    giftpage,
    setGiftCountPage,
    setGiftList,
    setGiftTotalCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/gift_list/?page=${giftpage}&limit=${giftlimit}`
      );
      const data = await res?.data;
      if (res?.status == 200) {
        var pageCount = Math.ceil(data?.count / giftlimit);

        setGiftList(data?.data);
        setGiftCountPage(pageCount);
        setGiftTotalCount(data?.count);
      }
    } catch (error) {
      console.log(error);
      setGiftList([]);
    }
  }

  async function createGift(item, type, id, afterCreateUpdateGift) {
    let formData = new FormData();
    formData.append(
      "name",
      item.title.slice(0, 1).toUpperCase() +
        item.title.slice(1, item.title.length)
    );
    formData.append("coin", item.Credit);
    if (item?.gift[0] != undefined) {
      formData.append("file", item?.gift[0]);
    }
    if (type === 1) {
      formData.append("GiftId", id);
    }
    let msg =
      type == 1
        ? `${t("GIFT_UPDATED_SUCCESSFULLY")}`
        : `${t("GIFT_CREATED_SUCCESSFULLY")}`;
    try {
      const response = await api.post(
        `${baseURL}/vid_user/create-gift/`,
        formData
      );
      const data = await response.data;
      if (data.code === 200) {
        toast.success(msg);
        afterCreateUpdateGift();
      } else {
        toast.warning(data.error);
      }
    } catch (error) {
      toast.error(`${t("SOMETHING_WENT_WRONG")}`);
      console.error(error);
    }
  }

  async function createCredit(item, afterCreateUpdate) {
    try {
      const response = await api.post(
        `${baseURL}/vid_user/create_credit_coin/`,
        item
      );
      const data = await response.data;
      if (data.code === 201) {
        afterCreateUpdate();
        toast.success(`${t("CREDIT_CREATED_SUCCESSFULLY")}`);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  }

  async function updateCredit(item, id, afterCreateUpdate) {
    try {
      const response = await api.put(
        `${baseURL}/vid_user/update_credit_coin/` + id,
        item
      );
      const data = await response.data;
      if (data.code === 200) {
        toast.success(`${t("CREDIT_UPDATED_SUCCESSFULLY")}`);
        afterCreateUpdate();
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  }

  async function workerDetailsById(id, setworderDetails) {
    try {
      const res = await api.get(`${baseURL}/vid_worker/worker_detail/` + id);
      const data = await res.data;
      if (res.status === 200) {
        setworderDetails(data.data);
      }
    } catch (error) {
      console.log(error);
      // toast.error(`${t("SOMETHING_WENT_WRONG")}`);
      setworderDetails([]);
    }
  }

  async function varifyWorker(value, handleClose, afterApporveDecline) {
    try {
      const res = await api.post(`${baseURL}/vid_user/approve_identity`, value);
      const data = await res.data;
      if (data.code == 200) {
        // setIscheck(true);
        if (value.status != "approve") {
          handleClose();
        }
        toast.success(
          `${t("WORKER")} ${
            value.status == "approve" ? "approved" : value.status
          } ${t("SUCCESSFULLY")}`
        );
        afterApporveDecline();
      } else {
        toast.error(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      // setIscheck(false);
      toast.error(`${t("SOMETHING_WENT_WRONG")}`);
      console.log(error);
      // setworderDetails([]);
    }
  }

  async function bestWorker(todate, fromdate, setWorkers) {
    let value = {
      from_date: fromdate,
      to_date: todate,
    };

    try {
      const res = await api.post(`${baseURL}/vid_user/top_worker_list/`, value);
      const data = await res.data;
      if (res.data.code == 200) {
        setWorkers(data.data);
      } else {
        // toast.error(data.message);
        setWorkers([]);
      }
    } catch (error) {
      console.log(error);
      setWorkers([]);
    }
  }

  async function getReportListForSponsor(
    reportlimit,
    reportpage,
    setReportCountPage,
    setsponsorReportList,
    setReportCount,
    first,
    second
  ) {
    // let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      const res = await api.get(
        `${baseURL}/vid_user/report_list_of_sponsor/?page=${reportpage}&limit=${reportlimit}${start_date}${end_date}`
      );
      const data = await res.data;

      if (data.code === 200) {
        var pageCount = Math.ceil(data.count / reportlimit);
        setsponsorReportList(data.data);
        setReportCount(data.count);
        setReportCountPage(pageCount);
      } else {
        // toast.error(data.message)
        setsponsorReportList([]);
      }
    } catch (error) {
      console.log(error);
      setsponsorReportList([]);
    }
  }

  async function getReportListForUser(
    limit,
    page,
    setCountPage,
    setUserReportList,
    search,
    setUserCount,
    first,
    second
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      const res = await api.get(
        `${baseURL}/vid_user/get_report_list_of_user/?page=${page}&limit=${limit}${filter}${start_date}${end_date}`
      );
      const data = await res.data;
      if (data.code === 200) {
        var pageCount = Math.ceil(data.count / limit);
        setUserReportList(data.data);
        setCountPage(pageCount);
        setUserCount(data.count);
      } else {
        // toast.error(data.message)
        setUserReportList([]);
      }
    } catch (error) {
      console.log(error);
      setUserReportList([]);
    }
  }

  async function getsupportList(
    limit,
    page,
    setLoader,
    setCountPage,
    setSuportList,
    search,
    setDataCount,
    first,
    second
  ) {
    setLoader(true);
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      const res = await api.get(
        `${baseURL}/vid_user/get_help_and_support/?page=${page}&limit=${limit}${filter}${start_date}${end_date}`
      );
      const data = await res.data;

      if (data.code === 200) {
        setLoader(false);

        var pageCount = Math.ceil(data.count / limit);
        setSuportList(data.data);
        setCountPage(pageCount);
        setDataCount(data.count);
      } else {
        setLoader(false);
        // toast.error(data.message)
        setSuportList([]);
      }
    } catch (error) {
      console.log(error);
      setSuportList([]);
    }
  }

  async function getAllFaq(
    limit,
    page,
    setCountPage,
    setFaqList,
    search,
    setDataCount
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    try {
      const response = await api.get(
        `${baseURL}/vid_user/frequent_question_answer_list/?page=${page}&limit=${limit}${filter}`
      );
      const data = response?.data;
      if (data?.code === 200) {
        var pageCount = Math.ceil(data?.count / limit);
        setFaqList(data?.data);
        setCountPage(pageCount);
        setDataCount(data?.count);
      } else {
        setFaqList([]);
      }
    } catch (err) {
      console.warn(err);
      setFaqList([]);
    }
  }

  async function createFaq(item) {
    try {
      const response = await api.post(
        `${baseURL}/vid_user/create_frequent_question_answer/`,
        item
      );
      const data = await response.data;
      if (data.code === 201) {
        toast.success(`${t("FAQ_CREATED_SUCCESSFULLY")}`);
        navigate("/Faq");
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.error(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function updateFaq(id, item) {
    try {
      const response = await api.put(
        `${baseURL}/vid_user/update_frequent_QA/${id}`,
        item
      );
      const data = await response.data;
      if (data.code === 200) {
        toast.success(`${t("FAQ_UPDATED_SUCCESSFULLY")}`);
        navigate("/Faq");
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.error(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function getFAQById(setFaqDetails, id) {
    try {
      const response = await api.get(
        `${baseURL}/vid_user/detail_frequent_question_answer/?fqa_id=${id}`
      );

      const data = await response.data;
      if (data.code == 200) {
        setFaqDetails(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function chnageFaqOrder(id, item) {
    try {
      const response = await api.put(
        `${baseURL}/vid_user/update_frequent_QA_sequence/${id}`,
        item
      );
      const data = await response.data;
      if (data.code === 200) {
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.error(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function getDashboardDetails(setData) {
    try {
      const response = await api.get(
        `${baseURL}/vid_user/get_count_of_report_and_payment_request/`
      );
      const data = await response.data;
      if (data.code === 200) {
        setData(data.data);
      } else {
        setData({});
      }
    } catch (error) {
      console.log(error.error);
      setData({});
    }
  }

  async function deleteFaq(id, afterDelete) {
    try {
      const response = await api.delete(
        `${baseURL}//vid_user/delete_frequent_question_answer/${id}`
      );
      const data = await response.data;
      if (data.code === 200) {
        afterDelete(id);
        toast.success(`${t("FAQ_DELETED_SUCCESSFULLY")}`);
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.error(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function replaySupport(data, handleClose) {
    try {
      const response = await api.post(
        `${baseURL}/vid_user/reply_help_and_support/`,
        data
      );
      if (response.data.code === 201) {
        handleClose();
        toast.success(`${t("MESSAGE_SEND_SUCCESSFULLY")}`);
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  }

  async function Warning(data, handleClose, getdata) {
    try {
      const response = await api.post(
        `${baseURL}/vid_user/add_warning_for_user/`,
        data
      );

      if (response.data.code == 201) {
        handleClose();
        getdata();
        toast.success(`${t("WARNING_SEND_SUCCESSFULLY")}`);
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function allotedCoin(obj, setShow, afterSendGift) {
    try {
      const response = await api.post(`${baseURL}/vid_user/alloted_coin`, obj);

      if (response.data.code == 201) {
        toast.success(`${t("GIFT_SENDED_SUCCESFULLY")}`);
        setShow(false);
        afterSendGift();
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  }

  async function getAllusers(
    limit,
    page,
    setLoader,
    setCountPage,
    setAllUsers,
    search,
    setDataCount,
    first,
    second
  ) {
    setLoader(true);
    try {
      let filter = search !== "" ? `&search=${search}` : "";
      let start_date = first != "" ? `&start_date=${first}` : "";
      let end_date = second != "" ? `&end_date=${second}` : "";
      const res = await api.get(
        `${baseURL}/vid_user/users_list/?page=${page}&limit=${limit}${filter}${start_date}${end_date}`
      );
      if (res?.data?.code == 200) {
        setLoader(false);
        var pageCount = Math.ceil(res?.data?.count / limit);
        setAllUsers(res?.data?.data);
        setCountPage(pageCount);
        setDataCount(res?.data?.count);
      } else {
        setLoader(false);
        setAllUsers([]);
      }
    } catch (error) {
      setAllUsers([]);
      console.log(error.message);
      setLoader(false);
    }
  }

  // async function sendNotification(id, afterSendNotification) {
  //   try {
  //     const res = await api.get(
  //       `${baseURL}/vid_user/SponserPaymentNotification/` + id
  //     );
  //     if (res?.data?.code == 200) {
  //       afterSendNotification();
  //       toast.success(`${t("NOTIFICATION_SENDED_SUCCESSFULLY")}`);
  //     } else {
  //       toast.error(res?.data?.message);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }

  async function uploadRecipt(
    requestId,
    recieptImage,
    afterSendNotification,
    modalClose
  ) {
    let formData = new FormData();
    formData.append("file", recieptImage);
    formData.append("id", requestId);
    formData.append("Status", "paid");
    try {
      const response = await api.post(
        `${baseURL}/vid_user/Upload_receipt/`,
        formData
      );
      if (response.data.code == 200) {
        afterSendNotification();
        modalClose();
        toast.success(`${t("RECIEPT_UPLODATED_SUCCESFULLY")}`);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  }

  async function getPaymentRecivedDetails(
    limit,
    page,
    setCountPage,
    setRecord,
    setDataCount,
    id,
    paymentid,
    setTotalAmount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/list_of_workers_accepted_by_admin/?page=${page}&limit=${limit}&sponser_id=${id}&payment_request_id=${paymentid}`
      );
      if (res?.data?.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / limit);
        setRecord(res?.data?.data);
        setCountPage(pageCount);
        setDataCount(res?.data?.count);
        setTotalAmount(res?.data?.total_amount);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function createProhibitedBank(data) {
    try {
      const res = await api.post(
        `${baseURL}/vid_user/prohibitedbank/create/`,
        data
      );
      if ((res.data.code = 200)) {
        toast.success(`${t("PROHIBITED_BANK_CREATED_SUCCESSFULLY")}`);
        navigate("/Prohibited_bank");
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateProhibitedBank(data) {
    try {
      const res = await api.post(
        `${baseURL}/vid_user/prohibitedbank/update/`,
        data
      );
      if ((res.data.code = 200)) {
        toast.success(`${t("PROHIBITED_BANK_UPDATE_SUCCESSFULLY")}`);
        navigate("/Prohibited_bank");
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllProhibitedBank(
    limit,
    page,
    setCountPage,
    setRecord,
    search,
    setDataCount
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    try {
      const res = await api.get(
        `${baseURL}/vid_user/prohibitedbank/list/?page=${page}&limit=${limit}${filter}`
      );
      if ((res.data.code = 200)) {
        setRecord(res.data.data);
        var pageCount = Math.ceil(res?.data?.count / limit);
        setCountPage(pageCount);
        setDataCount(res?.data?.count);
      } else {
        setRecord([]);
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      setRecord([]);
      console.log(error);
    }
  }

  async function getProhibitedBankId(id, setRecord, setisCheck) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/prohibitedbank/list/?CountryId=` + id
      );
      if (res.data.code == 200) {
        setRecord(res?.data?.data);
        setisCheck(true);
      } else {
        setRecord([]);
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      setRecord([]);
      console.log(error);
    }
  }

  async function adminReports(
    data,
    limit,
    page,
    setLoader,
    setRecord,
    setCountPage,
    setCountData,
    search
  ) {
    try {
      setLoader(true);
      // setLoader(false);
      let filter = search !== "" ? `&search=${search}` : "";
      const res = await api.post(
        `${baseURL}/vid_user/reports_for_admin/?page=${page}&limit=${limit}${filter}`,
        data
      );
      if (res.data.code == 200) {
        setLoader(false);

        var pageCount = Math.ceil(res?.data?.count / limit);
        setCountPage(pageCount);
        setRecord(res.data.data);
        setCountData(res?.data?.count);
      } else {
        setLoader(false);
        setCountPage(0);
        setRecord([]);
        setCountData(0);
        toast.warning(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function DownloadExl(data) {
    try {
      const res = await api.post(`${baseURL}/vid_user/excel_download/`, data);
      if (res.data.code == 200) {
        window.open(res.data.url, "_blank");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getAllDeductCoin(setDebitList) {
    try {
      const res = await api.get(`${baseURL}/vid_user/amount_list/`);
      if (res.data.code == 200) {
        setDebitList(res.data.data);
      } else {
        setDebitList([]);
      }
    } catch (error) {
      setDebitList([]);
      console.log(error.message);
    }
  }

  async function createDeductCoin(data, afterCrUpDebit) {
    try {
      const res = await api.post(`${baseURL}/vid_user/add_amount/`, data);
      if (res.data.code == 200) {
        toast.success(`${t("CREATED_SUCCESSFULLY")}`);
        afterCrUpDebit();
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function updateDeductCoin(id, data, afterCrUpDebit) {
    try {
      const res = await api.put(
        `${baseURL}/vid_user/update_amount/` + id,
        data
      );
      if (res.data.code == 200) {
        toast.success(`${t("UPDATED_SUCCESSFULLY")}`);
        afterCrUpDebit();
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function userCallHistory(
    id,
    setCallhistory,
    page,
    limit,
    setCountPage,
    setDataCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/user_call_history_list/?page=${page}&limit=${limit}&user_id=${id}`
      );
      if (res.data.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / limit);
        setCallhistory(res?.data?.data);
        setCountPage(pageCount);
        setDataCount(res?.data?.count);
      } else {
        setCallhistory([]);
        setCountPage(0);
        setDataCount(0);
      }
    } catch (error) {
      setCallhistory([]);
    }
  }

  async function userCreditsHistory(
    id,
    setCredithistory,
    creditpage,
    creditlimit,
    setCreditCountPage,
    setCreditDataCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/user_credit_history_list/?page=${creditpage}&limit=${creditlimit}&user_id=${id}`
      );
      if (res.data.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / creditlimit);
        setCredithistory(res?.data?.data);
        setCreditCountPage(pageCount);
        setCreditDataCount(res?.data?.count);
      } else {
        setCredithistory([]);
        setCreditCountPage(0);
        setCreditDataCount(0);
      }
    } catch (error) {
      setCreditCountPage(0);
      setCreditDataCount(0);
      setCredithistory([]);
    }
  }

  async function userWarningHistory(
    id,
    setWarningtHistory,
    warninglimit,
    warningpage,
    setWarningCountPage,
    setWarningDataCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/user_warning_history_list/?page=${warningpage}&limit=${warninglimit}&user_id=${id}`
      );
      if (res.data.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / warninglimit);
        setWarningtHistory(res?.data?.data);
        setWarningCountPage(pageCount);
        setWarningDataCount(res?.data?.count);
      } else {
        setWarningtHistory([]);
        setWarningCountPage(0);
        setWarningDataCount(0);
      }
    } catch (error) {
      setWarningCountPage(0);
      setWarningDataCount(0);
      setWarningtHistory([]);
    }
  }

  async function workerCallHistory(
    id,
    setCallhistory,
    page,
    limit,
    setCountPage,
    setDataCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/worker_call_history_list/?page=${page}&limit=${limit}&worker_id=${id}`
      );
      if (res.data.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / limit);
        setCallhistory(res?.data?.data);
        setCountPage(pageCount);
        setDataCount(res?.data?.count);
      } else {
        setCallhistory([]);
        setCountPage(0);
        setDataCount(0);
      }
    } catch (error) {
      setCallhistory([]);
    }
  }

  async function WorkerPaymentHistory(
    id,
    setPaymentHistory,
    historypage,
    historylimit,
    setHistoryCountPage,
    setHistoryDataCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/worker_payment_history_list/?page=${historypage}&limit=${historylimit}&worker_id=${id}`
      );
      if (res.data.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / historylimit);
        setPaymentHistory(res?.data?.data);
        setHistoryCountPage(pageCount);
        setHistoryDataCount(res?.data?.count);
      } else {
        setPaymentHistory([]);
        setHistoryCountPage(0);
        setHistoryDataCount(0);
      }
    } catch (error) {
      setPaymentHistory([]);
    }
  }

  async function workerPaymentRequestHistory(
    id,
    setRequestHistory,
    requestlimit,
    requestpage,
    setRequestCountPage,
    setRequestDataCount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_user/worker_payment_request_history_list/?page=${requestpage}&limit=${requestlimit}&worker_id=${id}`
      );
      if (res.data.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / requestlimit);
        setRequestHistory(res?.data?.data);
        setRequestCountPage(pageCount);
        setRequestDataCount(res?.data?.count);
      } else {
        setRequestHistory([]);
        setRequestCountPage(0);
        setRequestDataCount(0);
      }
    } catch (error) {
      setRequestHistory([]);
    }
  }

  async function gerWorkerUserGraphData(year, setWorkerData) {
    const data = {
      year: Number(year),
    };
    try {
      const res = await api.post(
        `${baseURL}/vid_user/users_and_workers_monthly_count/`,
        data
      );

      if (res.data.code == 200) {
        // setTimeout(() => {
        setWorkerData(res.data.data);

        // }, 2000);
      } else {
        toast.error();
      }
    } catch (error) {}
  }

  async function deleteDebit(id, afterCrUpDebit) {
    try {
      const res = await api.delete(`${baseURL}/vid_user/delete_amount/` + id);

      if (res.data.code == 200) {
        afterCrUpDebit();
      } else {
        toast.error();
      }
    } catch (error) {}
  }

  async function getWorkerwithdrawalCredits(setWorkerwithdrawalList) {
    try {
      const res = await api.get(`${baseURL}/vid_worker/coin_list_for_worker/`);
      if (res?.data?.code == 200) {
        setWorkerwithdrawalList(res?.data?.data);
      } else {
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const deleteCoin = async (id, afterDelete) => {
    try {
      const response = await api.delete(
        `${baseURL}/vid_user/delete_credit_coin/${id}`
      );
      const data = await response?.data;
      if (data?.code === 200) {
        afterDelete();
        toast.success(`${t("DELETED_SUCCESSFULLY")}`);
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      toast.warning(error?.response?.data?.message);
      console.error(error);
    }
  };

  async function getCallCredit(setcallCreditData) {
    try {
      const response = await api.get(
        `${baseURL}/vid_sponser/CallDeductionCoin/`
      );
      const data = await response.data;
      console.log(data);
      if (data.code === 200) {
        setcallCreditData(data.CallDeductionCoin);
      } else if (data.code === 404) {
        setcallCreditData([]);
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.warn(error);
      toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function createCallCredit(item, aftercrup) {
    try {
      const response = await api.post(
        `${baseURL}/vid_sponser/CallDeductionCoin/`,
        item
      );
      const data = response.data;
      if (data.code == 200) {
        if (item.CoinId != undefined) {
          toast.success(`${t("UPDATE_MESSAGE")}`);
        } else {
          toast.success(`${t("ADD_MESSAGE")}`);
        }
        aftercrup();
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error.error);
    }
  }

  async function getAllWarning(
    id,
    warningpage,
    warninglimit,
    setWarningCountPage,
    setWarningDataCount,
    setWarningData
  ) {
    console.log(warningpage, warninglimit);
    try {
      const response = await api.get(
        // `${baseURL}/vid_user/Warninglist/?PersonId=${id}&page=${warningpage}&limit=${warninglimit}`
        `${baseURL}/vid_user/Warninglist/?PersonId=${id}&page=${warningpage}&limit=${warninglimit}`
      );
      const data = await response.data;
      if (data.code === 200) {
        setWarningData(data.data);
        var pageCount = Math.ceil(data?.count / warninglimit);
        setWarningCountPage(pageCount);
        setWarningDataCount(data?.count);
      } else {
        setWarningData([]);
      }
    } catch (error) {
      console.warn(error);
      toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function DownlodImag(imageURl) {
    fetch(imageURl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.jpg");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        toast.error("Error downloading image");
      });
  }

  // -----------------------------Sponsor panel api---------------------------------------------

  async function getAllClient(
    limit,
    page,
    setLoader,
    setCountPage,
    setAllClient,
    search,
    setDataCount,
    first,
    second
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      setLoader(true);
      const response = await api.get(
        `${baseURL}/vid_sponser/client_list/?page=${page}&limit=${limit}${filter}${start_date}${end_date}`
      );
      const data = await response.data;
      if (data.code === 200) {
        setLoader(false);
        setAllClient(data.data);
        let countpage = Math.ceil(data.count / limit);
        setCountPage(countpage);
        setDataCount(data.count);
      } else {
        setLoader(false);
        setAllClient([]);
      }
    } catch (error) {
      console.warn(error);
      toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function getAllRequest(
    limit,
    page,
    setLoader,
    setCountPage,
    setAllRequest,
    search,
    status,
    setDataCount,
    first,
    second
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first !== undefined ? `&start_date=${first}` : "";
    let end_date = second !== undefined ? `&end_date=${second}` : "";
    console.log(status, start_date, end_date);
    try {
      setLoader(true);
      const response = await api.get(
        `${baseURL}/vid_sponser/sponsorship_list/?page=${page}&limit=${limit}${filter}&status=${status}${start_date}${end_date}`
      );
      const data = await response.data;
      if (data.code === 200) {
        setLoader(false);
        setAllRequest(data.data);
        let countpage = Math.ceil(data.count / limit);
        setCountPage(countpage);
        setDataCount(data.count);
      } else {
        setLoader(false);
        setAllRequest([]);
      }
    } catch (error) {
      console.warn(error);
      // toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function acceptRequest(item, msg, afterAcpCan) {
    try {
      // alert();
      const response = await api.post(
        `${baseURL}/vid_sponser/WorkerRequestStatus/`,
        item
      );
      const data = response.data;
      if (data.code == 200) {
        toast.success(`${t("REQUEST")} ${msg} ${t("SUCCESSFULLY")}`);
        afterAcpCan();
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error.error);
    }
  }

  async function getAllWrokerPaymentRequest(
    limit,
    page,
    setCountPage,
    setDataCount,
    setPaymentRequestList,
    search
  ) {
    try {
      let filter = search !== "" ? `&search=${search}` : "";
      const res = await api.get(
        `${baseURL}/vid_sponser/worker_payment_request_list/?page=${page}&limit=${limit}${filter}`
      );
      if (res?.data?.code == 200) {
        let countpage = Math.ceil(res?.data?.count / limit);
        setPaymentRequestList(res.data.data);
        setCountPage(countpage);
        setDataCount(res.data.count);
      } else {
        setPaymentRequestList([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getAllWrokerInprocessPayment(
    processlimit,
    processPage,
    setProcessCountPage,
    setProcessDataCount,
    setPaymentInprocessList,
    status
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_sponser/worker_payment_request_in_process_list/?page=${processPage}&limit=${processlimit}&status=${status}`
      );
      if (res?.data?.code == 200) {
        let countpage = Math.ceil(res?.data?.count / processlimit);
        setPaymentInprocessList(res.data.data);
        setProcessCountPage(countpage);
        setProcessDataCount(res.data.count);
      } else {
        setPaymentInprocessList([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function sendRequestforWorkerAmount(data, afterSendMessage) {
    try {
      const res = await api.post(
        `${baseURL}/vid_user/admin-payment-request/`,
        data
      );
      if (res.data.code === 200) {
        afterSendMessage();
        toast.success(`${t("PAYMENT_REQUEST_SENDED_SUCCESSFULLY")}`);
      } else if (res.data.code === 404) {
        // toast.error(`${t("SOMETHING_WENT_WRONG")}`);
        Swal.fire({
          title: "Warning",
          text: "Binance account not availible please add account first",
          icon: "warning",
        });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadReciptForWorker(
    requestId,
    recieptImage,
    afterSendNotification,
    modalClose
  ) {
    let formData = new FormData();
    formData.append("file", recieptImage);
    formData.append("id", requestId);
    formData.append("Status", "paid");
    try {
      const response = await api.post(
        `${baseURL}/vid_sponser/upload_receipt_of_worker/`,
        formData
      );
      afterSendNotification();
      modalClose();
      if (response.data.code == 200) {
        toast.success(`${t("RECIEPT_UPLODATED_SUCCESFULLY")}`);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  }

  async function sponsorPaymentHistory(
    limit,
    page,
    setLoader,
    setCountPage,
    setPaymenthistory,
    search,
    setDataCount,
    first,
    second
  ) {
    let filter = search !== "" ? `&search=${search}` : "";
    let start_date = first != undefined ? `&start_date=${first}` : "";
    let end_date = second != undefined ? `&end_date=${second}` : "";
    try {
      setLoader(true);
      const response = await api.get(
        `${baseURL}/vid_sponser/worker_payment_history_list/?page=${page}&limit=${limit}${filter}${start_date}${end_date}`
      );
      const data = await response.data;
      if (data.code === 200) {
        setLoader(false);
        setPaymenthistory(data.data);
        let countpage = Math.ceil(data.count / limit);
        setCountPage(countpage);
        setDataCount(data.count);
      } else {
        setLoader(false);
        setPaymenthistory([]);
      }
    } catch (error) {
      console.warn(error);
      toast.error(`${t("SOMETHING_WENT_WRONG")}`);
    }
  }

  async function getPaymentHistoryAdminToSponsor(
    admlimit,
    admpage,
    setAdmCountPage,
    setAdmHistory,
    admsearch,
    setAdmDataCount,
    first,
    second
  ) {
    try {
      let filter = admsearch !== "" ? `&search=${admsearch}` : "";
      let start_date = first != undefined ? `&start_date=${first}` : "";
      let end_date = second != undefined ? `&end_date=${second}` : "";
      const res = await api.get(
        `${baseURL}/vid_sponser/admin_to_sponsor_payment_history/?page=${admpage}&limit=${admlimit}${filter}${start_date}${end_date}`
      );
      if (res?.data?.code == 200) {
        let countpage = Math.ceil(res?.data?.count / admlimit);
        setAdmHistory(res.data.data);
        setAdmCountPage(countpage);
        setAdmDataCount(res.data.count);
      } else {
        setAdmHistory([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getSponsorDashboardDetails(setDashboardCount) {
    try {
      const response = await api.get(
        `${baseURL}/vid_sponser/sponsor_dashboard_count/`
      );
      const data = await response.data;
      if (data.code === 200) {
        setDashboardCount(data.data);
      } else {
        setDashboardCount({});
      }
    } catch (error) {
      console.log(error.error);
      setDashboardCount({});
    }
  }

  async function sponsorWorkersGraph(obj, setWorkerGraphData) {
    try {
      const response = await api.post(
        `${baseURL}/vid_sponser/worker_count_for_graph/`,
        obj
      );
      if (response.data.code == 200) {
        setWorkerGraphData(response.data.data);
      } else {
      }
    } catch (error) {}
  }

  async function sponsoRevenueGraph(obj, setWorkerGraphData) {
    try {
      const response = await api.post(
        `${baseURL}/vid_sponser/revenue_count_for_graph/`,
        obj
      );
      if (response.data.code == 200) {
        setWorkerGraphData(response.data.data);
      } else {
      }
    } catch (error) {}
  }

  async function paymentDetailsBySponsor(
    limit,
    page,
    setCountPage,
    setRecord,
    setDataCount,
    id,
    paymentid,
    setTotalAmount
  ) {
    try {
      const res = await api.get(
        `${baseURL}/vid_sponser/workers_based_on_payment_request/?page=${page}&limit=${limit}&sponser_id=${id}&payment_request_id=${paymentid}`
      );
      if (res?.data?.code == 200) {
        var pageCount = Math.ceil(res?.data?.count / limit);
        setRecord(res?.data?.data);
        setCountPage(pageCount);
        setDataCount(res?.data?.count);
        setTotalAmount(res?.data?.total_amount);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function DownloadExlForSponsor(data) {
    try {
      const res = await api.post(
        `${baseURL}//vid_sponser/excel_download_of_sponsor_report/`,
        data
      );
      if (res.data.code == 200) {
        window.open(res.data.url, "_blank");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function SponsorReports(
    data,
    limit,
    page,
    setLoader,
    setRecord,
    setCountPage,
    setCountData,
    search
  ) {
    data.limit = limit;
    data.page = page;
    // data.search = search;
    console.log(data);
    try {
      setLoader(true);
      const res = await api.post(
        // `${baseURL}/vid_sponser/reports_for_sponsor/?page=${page}&limit=${limit}${filter}`,
        `${baseURL}/vid_sponser/reports_for_sponsor/`,
        data
      );
      if (res.data.code == 200) {
        setLoader(false);
        var pageCount = Math.ceil(res?.data?.count / limit);
        setCountPage(pageCount);
        setRecord(res.data.data);
        setCountData(res?.data?.count);
      } else {
        setLoader(false);
        setCountPage(0);
        setRecord([]);
        setCountData(0);
        toast.warning(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function rejectPaymentRequest(item, afterReject) {
    try {
      const response = await api.post(
        `${baseURL}/vid_sponser/WorkerPaymentRequestStatus/`,
        item
      );
      const data = response.data;
      if (data.code == 200) {
        toast.success(`${t("REQUEST_REJECTED_SUCCESSFULLY")}`);
        afterReject(item.id);
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error.error);
    }
  }

  async function getBinanceAccount(setBinanceData, setType) {
    try {
      const response = await api.get(
        `${baseURL}/vid_sponser/AddSponserBinanceAcc/`
      );
      const data = response.data;
      if (data.code == 200) {
        // toast.success(`${t("REQUEST_REJECTED_SUCCESSFULLY")}`);
        setType(0);
        setBinanceData(data.BinanceAccountDetail);
      } else if (data.code == 404) {
        setType(1);
        setBinanceData(data.BinanceAccountDetail);
      } else {
        toast.error(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error.error);
    }
  }

  async function createBinanceAccount(item, afterCreate) {
    try {
      const response = await api.post(
        `${baseURL}/vid_sponser/AddSponserBinanceAcc/`,
        item
      );
      const data = response.data;
      if (data.code == 200) {
        toast.success(`${t("ACCOUNT_ADDED_SUCCESSFULLY")}`);
        afterCreate();
      } else {
        toast.warning(`${t("SOMETHING_WENT_WRONG")}`);
      }
    } catch (error) {
      console.log(error.error);
    }
  }

  const contextData = {
    // header,
    getAllWarning,
    getAllSponsor,
    updateSponsor,
    deleteSponsor,
    createSponsor,
    getAllCountry,
    activeDeactivaeSponsor,
    getAllPaymentRequest,
    getWorkerBySponsorID,
    getPaymentRequestBySponsorId,
    getPaymentHistoryBySponsorId,
    getAllWorker,
    getCallCredit,
    // updateWorkerImgVideo,
    paymentRecevied,
    getGiftList,
    paymentHistory,
    getCreditCoiinList,
    createGift,
    createCredit,
    updateCredit,
    workerDetailsById,
    varifyWorker,
    bestWorker,
    setOpenSide,
    openside,
    createBinanceAccount,
    getWorkerwithdrawalCredits,
    getReportListForSponsor,
    getReportListForUser,
    getsupportList,
    getAllFaq,
    createFaq,
    updateFaq,
    getFAQById,
    deleteFaq,
    replaySupport,
    Warning,
    chnageFaqOrder,
    adminReports,
    deleteCoin,
    DownloadExl,
    userCallHistory,
    userCreditsHistory,
    userWarningHistory,
    workerPaymentRequestHistory,
    WorkerPaymentHistory,
    workerCallHistory,
    gerWorkerUserGraphData,
    // ---Sponsor panel
    getAllRequest,
    acceptRequest,
    getAllClient,
    getDashboardDetails,
    allotedCoin,
    getApprovedWorker,
    getAllusers,
    getAllWrokerPaymentRequest,
    sendRequestforWorkerAmount,
    // sendNotification,
    uploadRecipt,
    getPaymentRecivedDetails,
    getAllProhibitedBank,
    createProhibitedBank,
    updateProhibitedBank,
    getProhibitedBankId,
    getAllDeductCoin,
    createDeductCoin,
    updateDeductCoin,
    getAllWrokerInprocessPayment,
    uploadReciptForWorker,
    sponsorPaymentHistory,
    getPaymentHistoryAdminToSponsor,
    getSponsorDashboardDetails,
    sponsorWorkersGraph,
    sponsoRevenueGraph,
    paymentDetailsBySponsor,
    DownloadExlForSponsor,
    SponsorReports,
    rejectPaymentRequest,
    deleteDebit,
    getBinanceAccount,
    createCallCredit,
    DownlodImag,
    chnageLanguage,
    getSelectedLanguage,
  };

  return (
    <ApiContext.Provider value={contextData}>
      {children} <ToastContainer />
    </ApiContext.Provider>
  );
};
