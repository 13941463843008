/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useContext, useEffect, useState, useRef } from "react";
import { Tooltip } from "react-tooltip";
import { parseISO, format } from "date-fns";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
function Paymentrequest() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const imageRef = useRef(null);
  const { t } = useTranslation();
  const { getAllPaymentRequest, openside, uploadRecipt } =
    useContext(ApiContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [datacount, setDataCount] = useState();
  const [requestId, setRequestId] = useState();
  const [viewReciept, setViewReciept] = useState("");
  const [recieptImage, setRecieptImage] = useState();
  const [show, setShow] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [name, setName] = useState("");
  const [crop, setCrop] = useState({
    unit: "%",
    x: 20,
    y: 20,
    width: 25,
    height: 25,
    aspect: 0.1 / 0.1,
    locked: true,
  });
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [cropModal, setCropModal] = useState(false);
  const navigate = useNavigate();
  const modalClose = () => {
    setShow(false);
    setViewReciept("");
  };
  const croperModalClose = () => {
    setCropModal(false);
    setViewReciept("");
  };
  const viewModalClose = () => {
    setViewModal(false);
  };

  useEffect(() => {
    getAllPaymentRequest(
      limit,
      page,
      setCountPage,
      setRecords,
      search,
      setDataCount
    );
  }, [limit, page, search]);

  // function sendNoitify(id) {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to send notification!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#5cb85c",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       sendNotification(id, afterSendNotification);
  //     }
  //   });
  // }

  function afterSendNotification() {
    getAllPaymentRequest(
      limit,
      page,
      setCountPage,
      setRecords,
      search,
      setDataCount
    );
  }

  function openUploadRecieptModal(item, name) {
    setRequestId(item);
    setName(name);
    setShow(true);
  }

  function uploadImage(e) {
    setCropModal(true);
    setFileName(e.target.files[0].name);
    setViewReciept(URL.createObjectURL(e.target.files[0]));
  }
  function onImageLoaded() {
    setImage(imageRef.current);
  }

  async function sendRecipt() {
    await uploadRecipt(
      requestId.payment_request_id,
      recieptImage,
      afterSendNotification,
      modalClose
    );
  }

  // const copyToClipboard = async (value) => {
  //   try {
  //     await navigator.clipboard
  //       .writeText(value)
  //       .then(() => {
  //         toast.success(`${t("COPIED_TO_CLIPBOARD")}:` + value);
  //       })
  //       .catch((err) => {
  //         console.error(`${t("UNABLE_TO_COPY")}:` + value);
  //       });
  //   } catch (error) {
  //     toast.error(`${t("CLIPBOARD_IS_NOT_WORKING_ON_INCOGNITO")}`);
  //   }
  // };
  const copyToClipboard = async (textContent) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(textContent);
        toast.success(`${t("COPIED_TO_CLIPBOARD")}: ${textContent}`);
      } else {
        const textField = document.createElement("textarea");
        textField.innerText = textContent;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        toast.success(`${t("COPIED_TO_CLIPBOARD")}: ${textContent}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(`${t("UNABLE_TO_COPY")}: ${textContent}`);
    }
  };

  function viewPage(params) {
    localStorage.setItem("data", JSON.stringify(params));
    console.log(params);
    navigate(
      `/Viewdetailspaymentbyid/${params?.sponser_id?.id}/${params?.payment_request_id}`
    );
  }

  const saveCropImage = () => {
    makeClientCrop(crop);
    setCropModal(false);
  };

  async function makeClientCrop(crop) {
    if (viewReciept && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(image, crop, fileName);
      setRecieptImage(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        const croppedImageUrl = canvas.toDataURL("image/jpeg");
        setViewReciept(croppedImageUrl);

        canvas.toBlob((blob) => {
          const file = new File([blob], fileName, {
            type: "image/jpeg",
          });
          resolve(file);
        }, "image/jpeg");
      }, "image/jpeg");
    });
  }

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("PAYMENT_REQUEST")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des">
                <div className="left_shiowp">
                  <label>{t("SHOW_ENTRIES")}</label>
                  <select
                    className="form-control"
                    defaultValue="10"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="right_shiowp">
                  <label>Search</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("SEARCH")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="orderd">{t("S_NO")}.</th>
                      <th className="orderd">{t("SPONSOR_NAME")}</th>
                      <th className="orderd">{t("EMAIL")}</th>
                      <th className="orderd">{t("PAYMENT_REQUEST_DATE")}</th>
                      <th className="orderd">{t("WALLET_ADDRESS")}</th>
                      <th className="orderd">{t("COUNTRY")}</th>
                      <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                      <th className="orderd">{t("SPONSOR_PERCENTAGE_10%")}</th>
                      <th className="orderd">{t("TOTAL_PAY_AMOUNT")}</th>
                      <th
                        style={{
                          width: "150px",
                        }}
                      >
                        {t("ACTION")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item, index) => (
                      <tr key={"1" + index}>
                        <td>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {" "}
                          {item?.sponser_name}
                        </td>
                        <td>{item?.email}</td>
                        <td>
                          {format(
                            parseISO(item.create_date_time),
                            "dd MMM yyyy, HH:mm"
                          )}
                        </td>

                        <td>
                          {item?.sponser_binance_acc_id?.binance_pay_id}{" "}
                          <img
                            style={{
                              marginLeft: "10px",
                              marginTop: "4px",
                              height: "17px",
                              float: "right",
                            }}
                            src="/img/copy.png"
                            data-tooltip-id="view"
                            data-tooltip-content={t("COPY_ACCOUNT_ID")}
                            onClick={() =>
                              copyToClipboard(
                                item?.sponser_binance_acc_id?.binance_pay_id
                              )
                            }
                          />
                        </td>
                        <td>
                          <img
                            src={
                              item?.country_id != undefined
                                ? item?.country_id[0].flag_url
                                : ""
                            }
                            className="tab_flag"
                          />{" "}
                          {item?.country_id === undefined
                            ? "NA"
                            : item?.country_id[0]?.name}
                        </td>
                        <td>{"$" + item?.total_amount_request}</td>
                        <td>
                          {"$" + Math.floor(item?.total_amount_request * 0.1)}
                        </td>
                        <td>
                          {"$" +
                            Math.floor(
                              +item?.total_amount_request +
                                item?.total_amount_request * 0.1
                            )}
                        </td>
                        <td>
                          <span className="btn_us1">
                            <button
                              data-tooltip-id="view"
                              data-tooltip-content={t("VIEW_DETAILS")}
                              className="btn btn_clr"
                              onClick={() => viewPage(item)}
                            >
                              <img src="img/view.png" alt="" />
                            </button>
                            <button
                              className="btn btn_clr"
                              data-tooltip-id="view"
                              data-tooltip-content={t(
                                "UPLOAD_RECEIPT_AFTER_PAYMENT"
                              )}
                              onClick={() =>
                                openUploadRecieptModal(item, item.sponser_name)
                              }
                            >
                              <img src="img/receipt_2.png" />
                            </button>
                            {/* {item.notify_payment_sent ? (
                            <button
                              className="btn btn_clr"
                              data-tooltip-id="view"
                              data-tooltip-content="Upload recipet"
                              onClick={() =>
                                openUploadRecieptModal(item.payment_request_id)
                              }
                            >
                              <img src="img/receipt_2.png" />
                            </button>
                          ) : (
                            <button
                              className="btn btn_clr"
                              data-tooltip-id="view"
                              data-tooltip-content="Notify payment send"
                              onClick={() =>
                                sendNoitify(item.payment_request_id)
                              }
                            >
                              <img src="img/notify_payment.png" />
                            </button>
                          )} */}

                            <Tooltip id="view" />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {records.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}

              {records.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    <p>
                      {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                      {limit > datacount
                        ? datacount
                        : page * limit > datacount
                        ? datacount
                        : page * limit}{" "}
                      {t("OF")} {datacount} {t("ENTRIES")}
                    </p>
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Upload Receipt modal */}
      <Modal show={show} onHide={modalClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("UPLOAD_RECIPET_TO")} {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("NAME")} : </b>
                {requestId?.sponser_name}
              </li>
              <li>
                <b>{t("EMAIL")} : </b>
                {requestId?.email}
              </li>
              {requestId?.create_date_time != undefined ? (
                <li>
                  <b> {t("REQUEST_DATE")} : </b>
                  {format(
                    parseISO(requestId?.create_date_time),
                    "dd MMM yyyy,HH.mm"
                  )}
                </li>
              ) : (
                ""
              )}

              <li>
                <b>{t("AMOUNT")} : </b>${requestId?.total_amount_request}
              </li>
            </ul>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="Credit"> {t("UPLOAD_RECIPET")} </label>
            <input
              type="file"
              id="giftupload"
              onChange={uploadImage}
              className={"form-control"}
            />
            {viewReciept !== "" ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="down_respt my-4" style={{ width: 230 }}>
                    <img className="img_r" src={viewReciept} />

                    <h4>{t("RECEIPT")} </h4>
                    <button className="btn" onClick={() => setViewModal(true)}>
                      <i
                        className="uil uil-eye"
                        data-tooltip-id="view"
                        data-tooltip-content={t("VIEW_RECEIPT")}
                        style={{ fontSize: 25 }}
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {/* <div className="form-group mt-3">
              <label htmlFor="Credit">{t("UPLOAD_RECIPET")}</label>
              <input
                type="file"
                id="giftupload"
                onChange={uploadImage}
                className={"form-control"}
              />
              {viewReciept !== "" ? (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <img
                    style={{ height: "120px", width: "120px" }}
                    src={viewReciept}
                  />
                </div>
              ) : (
                ""
              )}
            </div> */}

            <div className="mt-3">
              <button
                style={{ float: "right" }}
                type="submit"
                className="btn btn_theme"
                onClick={sendRecipt}
                disabled={recieptImage == undefined}
              >
                {t("SEND_RECIPT")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Upload Receipt modal */}

      {/* Crop Receipt modal */}
      <Modal
        show={cropModal}
        size="xl"
        onHide={croperModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("IMAGE_CROPPER")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_image_croup">
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              maxWidth={1200}
              maxHeight={400}
              minHeight={100}
              minWidth={100}
            >
              <img
                ref={imageRef}
                src={viewReciept}
                onLoad={() => onImageLoaded()}
              />
            </ReactCrop>
          </div>
          <div className="flot_us3">
            <button
              type="submit"
              className="btn btn_theme"
              onClick={saveCropImage}
              // disabled={recieptImage == undefined}
            >
              {t("SAVE")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Crop Receipt modal */}

      {/*View Receipt modal */}
      <Modal
        show={viewModal}
        size="xl"
        onHide={viewModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("VIEW_RECEIPT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_image_croup">
            <img src={viewReciept} />
          </div>
        </Modal.Body>
      </Modal>
      {/*View Receipt modal */}
    </>
  );
}
export default Paymentrequest;
