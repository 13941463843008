/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { useNavigate } from "react-router-dom";
import { parseISO, format } from "date-fns";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
function Workerlist() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAllWorker, openside } = useContext(ApiContext);
  const [limit, setLimit] = useState(5);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [isccheck, setIscheck] = useState(false);
  const [status, setStatus] = useState("none");
  const [datacount, setDataCount] = useState();
  const [dates, setDates] = useState("");
  useEffect(() => {
    getAllWorker(
      limit,
      page,
      setLoader,
      setCountPage,
      setRecords,
      search,
      status,
      setDataCount,
      "",
      ""
    );
  }, [limit, page, search, isccheck === true, status]);

  function viewDetails(id) {
    navigate("/viewworkrdetails/" + id);
  }

  const setDateRange = () => {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    if (first != "Invalid date" && second != "Invalid date") {
      getAllWorker(
        limit,
        page,
        setLoader,
        setCountPage,
        setRecords,
        search,
        status,
        setDataCount,
        first,
        second
      );
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  };

  return (
    <div className={`main_slide ${openside ? "open_slider" : ""}`}>
      {loader && (
        <div className="loaderr2">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Header name={t("WORKERS_REQUEST")} />
      <div className="wrap_us conten_set">
        <div className="card_me table_des1">
          <div className="card_body">
            <div className="saech_des Workerlist_searh">
              <div className="left_shiowp">
                <label>{t("SHOW_ENTRIES")}</label>
                <select
                  className="form-control"
                  defaultValue="5"
                  onChange={(e) => {
                    e.stopPropagation();
                    setLimit(e.target.value);
                    setPage(1);
                  }}
                >
                  <option defaultValue="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="right_shiowp">
                <select
                  defaultValue="none"
                  className="form-control"
                  style={{
                    width: "120px",
                    marginRight: "10px",
                  }}
                  onChange={(e) => {
                    e.stopPropagation();
                    setStatus(e.target.value);
                  }}
                >
                  <option value="all">{t("ALL")}</option>
                  <option value="approve">{t("APPROVED")}</option>
                  <option value="decline ">{t("REJECTED")}</option>
                  <option value="none">{t("PENDING")}</option>
                </select>
                <div className="calenderd1" style={{ marginRight: "10px" }}>
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    placeholder={t("SEARCH_WITH_DATE")}
                    minDate={new Date(2023, 9, 1)}
                    maxDate={new Date()}
                  />

                  <button
                    className="btn btn_theme btn-sm"
                    disabled={dates == ""}
                    onClick={setDateRange}
                  >
                    {t("FIND")}
                  </button>
                </div>
                {"  "}
                {/* <label>Search</label> */}
                <input
                  className="form-control"
                  type="text"
                  style={{
                    width: "200px",
                  }}
                  placeholder={t("SEARCH")}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="ttt">
              <div className="">
                <div style={{ float: "right" }}></div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="orderd">{t("S_NO")}.</th>
                        <th className="orderd">{t("NAME")}</th>
                        <th className="orderd">{t("EMAIL")}</th>
                        <th className="orderd">{t("COUNTRY")}</th>
                        <th className="orderd">{t("REQUEST_DATE")}</th>
                        <th className="orderd text-center">{t("IMAGE")}</th>
                        <th className="orderd text-center">{t("VIDEO")}</th>
                        <th
                          style={{
                            width: "200px",
                          }}
                        >
                          {t("ACTION")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((item, index) => (
                        <tr key={"tr" + index}>
                          <td>
                            {page > 1
                              ? limit * page - limit + index + 1
                              : index + 1}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item?.display_name}
                          </td>
                          <td>{item?.email}</td>
                          <td>
                            <img
                              src={
                                item?.country_id != null
                                  ? item?.country_id[0]?.flag_url
                                  : ""
                              }
                              className="tab_flag"
                            />{" "}
                            {item?.country_id != null
                              ? item?.country_id[0]?.name
                              : "-"}
                          </td>
                          <td>
                            {" "}
                            {format(parseISO(item.date_joined), "dd MMM yyyy")}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {item.id_image ? (
                              <img
                                style={{
                                  width: "30px",
                                }}
                                src={"img/image.png"}
                              />
                            ) : (
                              <img
                                style={{
                                  width: "30px",
                                }}
                                src={"img/image_n.png"}
                              />
                            )}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {item.video_link ? (
                              <img
                                style={{
                                  width: "30px",
                                }}
                                src={"img/video.png"}
                              />
                            ) : (
                              <img
                                style={{
                                  width: "30px",
                                }}
                                src={"img/video_n.png"}
                              />
                            )}
                          </td>

                          <td>
                            <span className="btn_us3">
                              {item.account_approval_state === "none" ? (
                                <div>
                                  <button
                                    className="btn btn-sm btn_theme3"
                                    onClick={() => viewDetails(item.id)}
                                  >
                                    {t("VIEW")}
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    className="btn btn-sm btn_theme3"
                                    onClick={() => viewDetails(item.id)}
                                  >
                                    {t("VIEW")}
                                  </button>
                                  <span>
                                    {item.account_approval_state ===
                                    "approve" ? (
                                      <button className="btn btn-sm btn-success">
                                        <i className="uil uil-check"></i>{" "}
                                        {t("APPROVED")}
                                      </button>
                                    ) : (
                                      <button className="btn btn-sm btn-danger">
                                        <i className="uil uil-multiply"></i>{" "}
                                        {t("REJECTED")}
                                      </button>
                                    )}
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {records.length == 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "15px",
                    }}
                  >
                    <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
                {records.length != 0 && (
                  <div className="pag_botm">
                    <div className="left_neu">
                      {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                      {limit > datacount
                        ? datacount
                        : page * limit > datacount
                        ? datacount
                        : page * limit}{" "}
                      {t("OF")} {datacount}
                      {t("ENTRIES")}
                    </div>
                    <div className="pagddination">
                      <button
                        className="btn prew_1"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (page > 1) {
                            setPage(page - 1);
                          }
                        }}
                      >
                        {t("PREVIOUS")}
                      </button>{" "}
                      <input
                        className="form-control"
                        type="text"
                        style={{ textAlign: "center" }}
                        defaultValue={page}
                        readOnly
                      />{" "}
                      <button
                        className="btn next_bth"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (countPage > page) {
                            setPage(page + 1);
                          }
                        }}
                      >
                        {t("NEXT")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Workerlist;
