/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState, useTransition } from "react";
import Header from "../../Admin/CommonPage/Header";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ApiContext from "../../context/ApiContext";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import $ from "jquery";
function Myworkerdetails() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const {
    openside,
    workerCallHistory,
    getAllWarning,
    WorkerPaymentHistory,
    workerPaymentRequestHistory,
    DownlodImag,
  } = useContext(ApiContext);
  const routeId = useParams();
  const [warningData, setWarningData] = useState([]);
  //   -----------------------------------------
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [datacount, setDataCount] = useState();
  // -------------------------------------------
  const [warninglimit, setWarningLimit] = useState(5);
  const [warningpage, setWarningPage] = useState(1);
  const [warningcountpage, setWarningCountPage] = useState(1);
  const [warningdatacount, setWarningDataCount] = useState();
  // -------------------------------------------
  const [historylimit, setHistoryLimit] = useState(5);
  const [historypage, setHistoryPage] = useState(1);
  const [historycountPage, setHistoryCountPage] = useState(1);
  const [historydatacount, setHistoryDataCount] = useState();
  // -------------------------------------------
  const [requestlimit, setRequestLimit] = useState(5);
  const [requestpage, setRequestPage] = useState(1);
  const [requestcountpage, setRequestCountPage] = useState(1);
  const [requestdatacount, setRequestDataCount] = useState();
  // -------------------------------------------
  const [callhistory, setCallhistory] = useState([]);
  const [paymenthistory, setPaymentHistory] = useState([]);
  const [requesthistory, setRequestHistory] = useState([]);
  const [userData, setUserData] = useState();

  const [details, setDetails] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    let data = localStorage.getItem("data");
    setUserData(JSON.parse(data));
  }, []);

  useEffect(() => {
    workerCallHistory(
      routeId.id,
      setCallhistory,
      page,
      limit,
      setCountPage,
      setDataCount
    );
  }, [limit, page]);
  useEffect(() => {
    getAllWarning(
      routeId.id,
      warningpage,
      warninglimit,
      setWarningCountPage,
      setWarningDataCount,
      setWarningData
    );
  }, [warninglimit, warningpage]);

  useEffect(() => {
    WorkerPaymentHistory(
      routeId.id,
      setPaymentHistory,
      historypage,
      historylimit,
      setHistoryCountPage,
      setHistoryDataCount
    );
  }, [historypage, historylimit]);

  useEffect(() => {
    workerPaymentRequestHistory(
      routeId.id,
      setRequestHistory,
      requestlimit,
      requestpage,
      setRequestCountPage,
      setRequestDataCount
    );
  }, [requestlimit, requestpage]);

  function changeTab(key) {
    switch (key) {
      case "callhistory":
        if (callhistory.length === 0) {
          workerCallHistory(
            routeId.id,
            setCallhistory,
            page,
            limit,
            setCountPage,
            setDataCount
          );
        }
        break;

      case "warning":
        if (warningData.length === 0) {
          getAllWarning(
            routeId.id,
            warningpage,
            warninglimit,
            setWarningCountPage,
            setWarningDataCount,
            setWarningData
          );
        }
        break;

      default:
        break;
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month
      }-${year}`;
  };

  const seeReceipt = (item) => {
    $("#tb" + item.request_id).addClass("acTive");
    setDetails(item);
    setShow(true);
  };
  const handleClose = () => {
    var myElement = document.getElementById("tb" + details.request_id);
    myElement.classList.remove("acTive");
    setShow(false);
  };
  const downloadImage = (imageURL) => {
    DownlodImag(imageURL);
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={`${t("VIEW_DETALIS_OF")}  ${userData?.worker_name} `} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="ttt">
                <div className=" mb-5">
                  <div className="row view_user2">
                    <div className="col-md-3">
                      <div className="vide_img">
                        {/* <h3>Image</h3> */}
                        {userData?.worker_profile_image ? (
                          <img
                            style={{ height: "266px" }}
                            src={baseURL + userData?.worker_profile_image}
                          />
                        ) : (
                          <img
                            style={{ height: "266px" }}
                            src={"/img/woman_profile.jpg"}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="grid-container">
                        <div className="grid-item">
                          <span style={{ textTransform: "capitalize" }}>
                            {" "}
                            <b>{t("USER_NAME")} : </b> {userData?.worker_name}
                          </span>
                        </div>
                        <div className="grid-item">
                          <b>{t("TOTAL_CALL")} : </b> {userData?.call_count}
                        </div>
                        <div className="grid-item">
                          <b>
                            {t("TOTAL_PROFIT")} ({t("CREDITS")} ) :{" "}
                          </b>{" "}
                          {userData?.worker_total_earn_coin}
                        </div>

                        <div className="grid-item">
                          <b>{t("JOIN_DATE")} : </b>
                          {formatDate(userData?.start_datetime)}
                        </div>

                        <div className="grid-item">
                          <b>{t("TOTAL_WARNING")} : </b>{" "}
                          {userData?.worker_total_warning == null
                            ? "NA"
                            : userData?.worker_total_warning}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="callhistory"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(e) => changeTab(e)}
                >
                  <Tab eventKey="callhistory" title={t("CALL_HISTORY")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label>{t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setLimit(e.target.value);
                              setPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div style={{ float: "right" }}></div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "80px" }}>{t("S_NO")}.</th>
                              <th>{t("USER_NAME")}</th>
                              <th>{t("COUNTRY")}</th>
                              <th>{t("START_TIME")}</th>
                              <th>{t("END_TIME")}</th>
                              <th>{t("DURATION")}</th>
                              <th>{t("EARNING_CREDITS")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {callhistory.map((row, index) => (
                              <tr key={"tt" + row.vid}>
                                <td>
                                  {page > 1
                                    ? limit * page - limit + index + 1
                                    : index + 1}
                                </td>
                                <td>{row?.user_name}</td>
                                <td>
                                  <img
                                    src={row.user_country_flag}
                                    className="tab_flag"
                                  />{" "}
                                  {row.user_country_name}
                                </td>
                                <td>
                                  {format(
                                    parseISO(row.start_datetime),
                                    "ccc, dd MMM yyyy, HH:mm"
                                  )}
                                </td>
                                <td>
                                  {row.end_datetime != null ? (
                                    <div>
                                      {format(
                                        parseISO(row.end_datetime),
                                        "ccc, dd MMM yyyy, HH:mm"
                                      )}
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>{row.duration}</td>
                                <td>{row.call_earning}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {callhistory.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                      {callhistory.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")} {limit * page - limit + 1}{" "}
                              {t("TO")}{" "}
                              {limit > datacount
                                ? datacount
                                : page * limit > datacount
                                  ? datacount
                                  : page * limit}{" "}
                              {t("OF")} {datacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (page > 1) {
                                  setPage(page - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={page}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (countPage > page) {
                                  setPage(page + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="paymenthistory" title={t("PAYMENT_HISTORY")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label> {t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setHistoryLimit(e.target.value);
                              setHistoryPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th> {t("S_NO")}.</th>
                              <th>{t("AMOUNT")}</th>
                              <th>{t("STATUS")}</th>
                              <th>{t("REQUEST_ID")}</th>
                              <th>{t("REQUEST_TO")}</th>
                              <th>{t("REQUEST_DATE")}</th>
                              <th>{t("PAID_DATE")}</th>
                              <th>{t("ACTION")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymenthistory.map((row, index) => (
                              <tr key={index} id={"tb" + row.request_id}>
                                <td style={{ width: "80px" }}>
                                  {historypage > 1
                                    ? historylimit * historypage -
                                    historylimit +
                                    index +
                                    1
                                    : index + 1}
                                </td>
                                <td>{"$" + row.amount}</td>
                                <td style={{ width: "180px" }}>
                                  {row.request_status}
                                </td>
                                <td>
                                  {row.payment_request_id != null
                                    ? row.payment_request_id
                                    : "-"}
                                </td>
                                <td>{row?.sponsor_name}</td>
                                <td style={{ width: "250px" }}>
                                  {row?.request_date != null ? (
                                    <div>
                                      {" "}
                                      {format(
                                        parseISO(row?.request_date),
                                        "ccc, dd MMM yyyy, HH:mm"
                                      )}
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td style={{ width: "250px" }}>
                                  {row?.paid_date != null ? (
                                    <div>
                                      {" "}
                                      {format(
                                        parseISO(row?.paid_date),
                                        "ccc, dd MMM yyyy, HH:mm"
                                      )}
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  <span className="btn_us1">
                                    <button
                                      className="btn btn_clr"
                                      data-tooltip-id="view"
                                      data-tooltip-content={t("VIEW_RECEIPT")}
                                      onClick={() => seeReceipt(row, 0)}
                                    >
                                      <img src="/img/view.png" alt="" />
                                    </button>
                                  </span>
                                  <Tooltip id="view" />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {paymenthistory.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>
                            {" "}
                            {t("NO_RECORD_FOUND")}
                          </p>
                        </div>
                      )}
                      {paymenthistory.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")}{" "}
                              {historylimit * historypage - historylimit + 1}
                              {t("TO")}{" "}
                              {historylimit > historydatacount
                                ? historydatacount
                                : historypage * historylimit > historydatacount
                                  ? historydatacount
                                  : historypage * historylimit}{" "}
                              {t("OF")} {historydatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (historypage > 1) {
                                  setHistoryPage(historypage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              // defaultValue={historypage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={historypage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (historycountPage > historypage) {
                                  setHistoryPage(historypage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="paymentrequest" title={t("PAYMENT_REQUEST")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label> {t("SHOW_ENTRIES")} </label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setRequestLimit(e.target.value);
                              setRequestPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "80px" }} className="orderd">
                                {t("S_NO")} .
                              </th>
                              <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                              <th
                                style={{ width: "500px" }}
                                className="orderd "
                              >
                                {t("SPONSOR_NAME")}
                              </th>
                              <th>{t("STATUS")}</th>
                              <th style={{ width: "280px" }} className="orderd">
                                {t("REQUEST_DATE")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {requesthistory.map((row, index) => (
                              <tr key={index}>
                                <td style={{ width: "80px" }}>
                                  {requestpage > 1
                                    ? requestlimit * requestpage -
                                    requestlimit +
                                    index +
                                    1
                                    : index + 1}
                                </td>
                                <td>{"$" + row?.amount}</td>
                                <td>{row.sponsor_name}</td>
                                <td>{row.request_status}</td>
                                <td style={{ width: "250px" }}>
                                  {row?.request_date != null ? (
                                    <div>
                                      {" "}
                                      {format(
                                        parseISO(row?.request_date),
                                        "ccc, dd MMM yyyy, HH:mm"
                                      )}
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                  {/* {row.request_date} */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {requesthistory.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                      {requesthistory.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("Showing")}{" "}
                              {requestlimit * requestpage - requestlimit + 1}{" "}
                              {t("TO")}{" "}
                              {requestlimit > requestdatacount
                                ? requestdatacount
                                : requestpage * requestlimit > requestdatacount
                                  ? requestdatacount
                                  : requestpage * requestlimit}{" "}
                              {t("OF")} {requestdatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (requestpage > 1) {
                                  setRequestPage(requestpage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              // defaultValue={requestpage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={requestpage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (requestcountpage > requestpage) {
                                  setRequestPage(requestpage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="warning" title={t("WARNING")}>
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label>{t("SHOW_ENTRIES")}</label>
                          <select
                            className="form-control"
                            defaultValue="5"
                            onChange={(e) => {
                              e.stopPropagation();
                              setWarningLimit(e.target.value);
                              setWarningPage(1);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ width: "60px" }}>{t("S_NO")}.</th>
                              {/* <th>{t("NAME")}</th> */}
                              {/* <th>{t("PREVIOUS")}</th> */}
                              <th style={{ width: "15%" }}>
                                {t("WARNING_DATE")}
                              </th>
                              <th style={{ width: "100%" }}>{t("WARNING")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {warningData.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                {/* <td>{row.sponser_name}</td> */}
                                {/* <td>{row.previous_warning}</td> */}
                                <td>
                                  {format(
                                    parseISO(row.create_date_time),
                                    "dd MMM yyyy, HH:mm"
                                  )}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row.reason}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {warningData.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                      {warningData.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")}{" "}
                              {warninglimit * warningpage - warninglimit + 1}{" "}
                              {t("TO")}{" "}
                              {warninglimit > warningdatacount
                                ? warningdatacount
                                : warningpage * warninglimit > warningdatacount
                                  ? warningdatacount
                                  : warningpage * warninglimit}{" "}
                              {t("OF")} {warningdatacount} {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (warningpage > 1) {
                                  setWarningPage(warningpage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              // defaultValue={warningpage}
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={warningpage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (warningcountpage > warningpage) {
                                  setWarningPage(warningpage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("VIEW_RECEIPT")}
            {/* {t("REQUEST_ID")}{" "}
            {details?.payment_request_id} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("NAME")} : </b>
                {details?.worker_name}
              </li>
              <li>
                <b>{t("METHOD")} : </b>
                {details?.sponsor_name}
              </li>
              <li>
                <b>{t("EMAIL")} : </b>
                {details?.worker_email}
              </li>

              {details?.request_date != undefined ? (
                <li>
                  <b> {t("REQUEST_DATE")} : </b>
                  {format(parseISO(details?.request_date), "dd MMM yyyy")}
                </li>
              ) : (
                ""
              )}

              <li>
                <b>{t("AMOUNT")} : </b>${details?.amount}
              </li>
              {details?.paid_date != undefined ? (
                <li>
                  <b> {t("RECIVED_DATE")} : </b>
                  {format(parseISO(details?.paid_date), "dd MMM yyyy")}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="down_respt">
                <img
                  className="img_r"
                  src={
                    details?.receipts_image != undefined
                      ? baseURL + details?.receipts_image
                      : "./public/img/image_n.png"
                  }
                  alt=""
                />
                <h4>{t("RECEIPT")} </h4>
                <button
                  className="btn btn-sm btn_theme"
                  onClick={() => downloadImage(baseURL + details?.receipts_image)}
                >
                  <i class="uil uil-import"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
export default Myworkerdetails;
