/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../../Admin/CommonPage/Header";
import { parseISO, format } from "date-fns";
import ApiContext from "../../context/ApiContext";
import { Modal } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
function Sponsorshiprequest() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const { openside, getAllRequest, acceptRequest } = useContext(ApiContext);
  const [allrequest, setAllRequest] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [dataCount, setDataCount] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("pending");
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState("");
  const [dates, setDates] = useState("");
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
  };
  useEffect(() => {
    getAllRequest(
      limit,
      page,
      setLoader,
      setCountPage,
      setAllRequest,
      search,
      status,
      setDataCount
    );
  }, [limit, page, search, status]);

  function accept(type, id) {
    let msg = type === "cancel" ? "Decline" : "Accepted";
    let msg1 = type === "cancel" ? `${t("DECLINE")}` : `${t("ACCE")}`;
    let data = { WorkerId: id, Status: type };
    Swal.fire({
      title: t("ARE_YOU_SURE"),
      text: t("YOU_WANT_TO") + msg1 + " " + t("THIS_WORKER"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      cancelButtonColor: "#d33",
      confirmButtonText: t("YES"),
      cancelButtonText: t("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        acceptRequest(data, msg, afterAcpCan);
      }
    });
  }

  function afterAcpCan() {
    getAllRequest(
      limit,
      page,
      setLoader,
      setCountPage,
      setAllRequest,
      search,
      status,
      setDataCount
    );
  }

  function view(data) {
    setDetails(data);
    setShow(true);
  }
  const setDateRange = () => {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    if (first != "Invalid date" && second != "Invalid date") {
      getAllRequest(
        limit,
        page,
        setLoader,
        setCountPage,
        setAllRequest,
        search,
        status,
        setDataCount,
        first,
        second
      );
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  };

  const changeOption = (value) => {
    setStatus(value);
    setPage(1);
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div className="loaderr2">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("SPONSORSHIP_REQUEST")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des Sponsorship_searh">
                <div className="left_shiowp">
                  <label>{t("SHOW_ENTRIES")}</label>
                  <select
                    className="form-control"
                    defaultValue="10"
                    onChange={(e) => {
                      e.stopPropagation();
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="right_shiowp">
                  <select
                    style={{
                      width: "120px",
                      marginRight: "10px",
                    }}
                    defaultValue={"pending"}
                    className="form-control"
                    onChange={(e) => {
                      e.stopPropagation();
                      changeOption(e.target.value);
                    }}
                  >
                    <option value="all">{t("ALL")}</option>
                    <option value="accepted">{t("APPROVED")}</option>
                    <option value="cancel ">{t("REJECTED")}</option>
                    <option value="pending">{t("PENDING")}</option>
                  </select>
                  <div className="calenderd1" style={{ marginRight: "10px" }}>
                    <Calendar
                      value={dates}
                      onChange={(e) => setDates(e.value)}
                      selectionMode="range"
                      readOnlyInput
                      placeholder={t("SEARCH_WITH_DATE")}
                      minDate={new Date(2023, 9, 1)}
                      maxDate={new Date()}
                    />

                    <button
                      className="btn btn_theme btn-sm"
                      disabled={dates == ""}
                      onClick={setDateRange}
                    >
                      {t("FIND")}
                    </button>
                  </div>
                  <label>{t("SEARCH")}</label>
                  <input
                    style={{
                      width: "200px",
                    }}
                    className="form-control"
                    type="text"
                    placeholder={t("SEARCH")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: "80px" }} className="orderd">
                        {t("S_NO")}.
                      </th>
                      <th className="orderd">{t("NAME")}</th>
                      <th className="orderd">{t("COUNTRY")}</th>
                      <th className="orderd">{t("REQUEST_DATE")}</th>
                      <th>{t("ACTION")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allrequest.map((item, index) => (
                      <tr key={"tr" + index}>
                        <td>
                          {page > 1
                            ? limit * page - limit + index + 1
                            : index + 1}
                        </td>
                        <td>{item?.worker_name}</td>
                        <td>
                          <img
                            src={
                              item?.country_flag != undefined
                                ? item?.country_flag
                                : ""
                            }
                            className="tab_flag"
                          />{" "}
                          {item?.country_name === undefined
                            ? "-"
                            : item?.country_name}
                        </td>
                        <td>
                          {format(
                            parseISO(item.start_datetime),
                            " dd MMM yyyy"
                          )}
                        </td>
                        <td style={{ width: "300px" }}>
                          <span className="btn_us3">
                            {item.current_status === "pending" ? (
                              <div>
                                <button
                                  className="btn btn-sm btn_theme"
                                  onClick={() =>
                                    accept("accepted", item.worker_id)
                                  }
                                >
                                  {t("ACCE")}
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() =>
                                    accept("cancel", item.worker_id)
                                  }
                                >
                                  {t("DECLINE")}
                                </button>
                                <button
                                  className="btn btn-sm btn_theme3"
                                  onClick={() => view(item)}
                                >
                                  {t("VIEW")}
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button
                                  className="btn btn-sm btn_theme3"
                                  onClick={() => view(item)}
                                >
                                  {t("VIEW")}
                                </button>
                                {item.current_status === "accepted" ? (
                                  <button className="btn btn-sm btn-success">
                                    <i className="uil uil-check"></i>
                                    {t("APPEOVED")}
                                  </button>
                                ) : (
                                  <button className="btn btn-sm btn-danger">
                                    <i className="uil uil-multiply"></i>{" "}
                                    {t("REJECTED")}
                                  </button>
                                )}
                              </div>
                            )}
                          </span>
                          <span className="btn_us1"></span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {allrequest.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ color: "red" }}>{t("No_Record_Found")}</p>
                </div>
              )}
              {allrequest.length != 0 && (
                <div className="pag_botm">
                  <div className="left_neu">
                    <p>
                      {t("SHOWING")} {limit * page - limit + 1}
                      {t("TO")}{" "}
                      {limit > dataCount
                        ? dataCount
                        : page * limit > dataCount
                        ? dataCount
                        : page * limit}{" "}
                      {t("OF")} {dataCount}
                      {t("ENTRIES")}
                    </p>
                  </div>
                  <div className="pagddination">
                    <button
                      className="btn prew_1"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {t("PREVIOUS")}
                    </button>{" "}
                    <input
                      className="form-control"
                      type="text"
                      style={{ textAlign: "center" }}
                      defaultValue={page}
                      readOnly
                    />{" "}
                    <button
                      className="btn next_bth"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (countPage > page) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <button onClick={handleClose} className="btn btn-danger close_prof">
            <i className="uil uil-multiply"> </i>
          </button>
          <div className="profile_md1 conten_set">
            <div className="head_bg"></div>
            <div className="profile_img">
              <div className="img_file_p">
                <img
                  src={
                    details?.worker_profile_image != null
                      ? baseURL + details?.worker_profile_image
                      : "img/girl.png"
                  }
                />
                <span className="verd1">
                  <img src="img/star.png" />
                </span>
              </div>
              <h4>
                {details.worker_name}
                {/* <span>{details.worker_email}</span> */}
              </h4>
            </div>
            <div className="details_more">
              <ul className="ul_set">
                <li>
                  <span>
                    <img
                      src={baseURL + details.country_flag}
                      className="tab_flag"
                    />{" "}
                    {details.country_name}
                  </span>
                  {t("COUNTRY")}
                </li>
                {/* <li>
                  <span>+9155547812</span>
                  Phone
                </li> */}
                <li>
                  <span>{formatDate(details.start_datetime)}</span>
                  {t("REQUEST_DATE")}
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Sponsorshiprequest;
