/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";
function Bestworker() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { t } = useTranslation();
  const { bestWorker, openside, allotedCoin } = useContext(ApiContext);
  const [date11, setDate] = useState(null);
  const [workers, setWorkers] = useState([]);
  // const [defaultSatrt, setDefaultSatrt] = useState("");
  // const [defaultEnd, setDefaultEnd] = useState("");
  const [details, setDetails] = useState();
  const [creditvalue, setCreditValue] = useState("");
  // const [StartDate, setStartDate] = useState("");
  // const [EndDate, setEndDate] = useState("");
  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayPreviousMonth = new Date(firstDayCurrentMonth.getTime() - 1);
    setDate(lastDayPreviousMonth);
    let firstDayOfMonth = new Date(
      lastDayPreviousMonth.getFullYear(),
      lastDayPreviousMonth.getMonth(),
      1
    );
    let lastDayOfMonth = new Date(
      lastDayPreviousMonth.getFullYear(),
      lastDayPreviousMonth.getMonth() + 1,
      0
    );

    bestWorker(
      moment(lastDayOfMonth).format("YYYY-MM-DD"),
      moment(firstDayOfMonth).format("YYYY-MM-DD"),
      setWorkers
    );
  }, []);

  function findbestWorker() {
    let firstDayOfMonth = new Date(date11.getFullYear(), date11.getMonth(), 1);
    let lastDayOfMonth = new Date(
      date11.getFullYear(),
      date11.getMonth() + 1,
      0
    );
    bestWorker(
      moment(lastDayOfMonth).format("YYYY-MM-DD"),
      moment(firstDayOfMonth).format("YYYY-MM-DD"),
      setWorkers
    );
  }
  function openModal(item) {
    setDetails(item);
    setShow(true);
  }

  function sendGift() {
    let obj = {
      worker_id: details.worker_id,
      give_credit: creditvalue,
    };

    allotedCoin(obj, setShow, afterSendGift);
    setCreditValue("");
  }
  function afterSendGift() {
    let firstDayOfMonth = new Date(date11.getFullYear(), date11.getMonth(), 1);
    let lastDayOfMonth = new Date(
      date11.getFullYear(),
      date11.getMonth() + 1,
      0
    );
    bestWorker(lastDayOfMonth, firstDayOfMonth, setWorkers);
  }

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("BEST_WORKER")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des">
                <div
                  className="calenderd1"
                  style={{
                    margin: "0",
                    marginBottom: "15px",
                  }}
                >
                  <Calendar
                    value={date11}
                    onChange={(e) => setDate(e.value)}
                    view="month"
                    dateFormat="mm/yy"
                    minDate={new Date(2023, 12, 30)}
                    maxDate={new Date()}
                  />
                  <button
                    disabled={date11 == null}
                    className="btn btn_theme"
                    onClick={findbestWorker}
                  >
                    {t("FIND")}
                  </button>
                </div>
              </div>

              <div className="profile_lsit">
                <div className="row">
                  {workers.map((item, index) => (
                    <div className="col-md-4" key={index}>
                      <div className="profile_d1">
                        <div className="pro_img">
                          <img
                            src={
                              item.image_url_link != null
                                ? baseURL + item.image_url_link
                                : "/img/woman_profile.jpg"
                            }
                          />
                        </div>
                        <div className="pro_cont">
                          <h4>
                            <img src={baseURL + item.country_flag_url} />{" "}
                            {item.first_name.slice(0, 1).toUpperCase() +
                              item.first_name.slice(1, item.first_name.length)}
                          </h4>
                          <div className="right_uicm">
                            <h3>{item.call_earning}</h3>
                          </div>
                          <h4 className="che_mobile">
                            <span>{item.email}</span>
                            <div>
                              {!item.is_gift_send ? (
                                <button
                                  className="btn btn-sm btn_theme"
                                  onClick={() => openModal(item)}
                                >
                                  {t("SEND_GIFT")}
                                </button>
                              ) : (
                                <span style={{ color: "#00a800" }}>
                                  {" "}
                                  {item.gift_credit} {t("CREDITS_SENT")}
                                </span>
                              )}
                            </div>
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {workers.length == 0 && (
                <div
                  className="col-md-12 text-center mt-5 pt-5"
                  style={{ color: "red" }}
                >
                  {t("NO_RECORD_FOUND")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={modalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("SEND_GIFT_TO")} {details?.first_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="Credit">{t("CREDITS")}</label>
            <input
              type="number"
              className="form-control  hide-number-spinners"
              placeholder={t("ENTER_CREDITS")}
              name="amount"
              onChange={(e) => setCreditValue(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn_theme"
            disabled={creditvalue == ""}
            onClick={sendGift}
          >
            {t("SEND")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Bestworker;
