/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ApiContext from "../../context/ApiContext";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { toast } from "react-toastify";
import { parseISO, format } from "date-fns";
import Header from "../../Admin/CommonPage/Header";
import { useTranslation } from "react-i18next";
function Sponsorreport() {
  const { t } = useTranslation();
  const { openside, SponsorReports, DownloadExlForSponsor } =
    useContext(ApiContext);
  const [initialized, setInitialized] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(1);
  const [search, setSearch] = useState("");
  const [reportName, setReportName] = useState("");
  const [dates, setDates] = useState("");
  const [record, setRecord] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (initialized) {
      if (reportName != "") {
        loadData();
      }
    } else {
      setInitialized(true);
    }
  }, [limit, page]);

  function loadData() {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    let data = {
      start_date: first,
      end_date: second,
      key: reportName,
    };
    if (first != "Invalid date" && second != "Invalid date") {
      SponsorReports(
        data,
        limit,
        page,
        setLoader,
        setRecord,
        setCountPage,
        setCountData,
        search
      );
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  }

  function downloadData() {
    const first = moment(dates[0]).format("YYYY-MM-DD");
    const second = moment(dates[1]).format("YYYY-MM-DD");
    let data = {
      start_date: first,
      end_date: second,
      key: reportName,
    };
    if (first != "Invalid date" && second != "Invalid date") {
      DownloadExlForSponsor(data);
    } else {
      toast.warning(`${t("PLEASE_ENTER_VALID_DATE")}`);
    }
  }
  const chnageReport = (value) => {
    setReportName(value);
    setRecord([]);
    setLimit(10);
    setPage(1);
    setCountData(1);
    setCountPage(1);
  };
  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        {loader && (
          <div class="loaderr2">
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Header name={t("REPORTS")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="saech_des Reports_search2">
                <div>
                  <select
                    style={{
                      width: "250px",
                    }}
                    defaultValue={""}
                    className="form-control"
                    onChange={(e) => chnageReport(e.target.value)}
                  >
                    <option value="" disabled>
                      {t("SELECT_OPTION")}
                    </option>
                    <option value="revenue">{t("REVENUE")}</option>
                    <option value="worker_request">{t("WORKERS")}</option>
                  </select>
                </div>
                <div className="calenderd1">
                  <Calendar
                    value={dates}
                    onChange={(e) => setDates(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    placeholder={t("SEARCH_WITH_DATE")}
                    minDate={new Date(2023, 9, 1)}
                    maxDate={new Date()}
                  />
                  <button
                    className="btn btn_theme"
                    disabled={dates == "" || reportName == ""}
                    onClick={loadData}
                  >
                    {t("LOAD")}
                  </button>
                </div>
                <div className="">
                  {""}
                  <button
                    className="btn btn_theme"
                    onClick={downloadData}
                    disabled={dates == "" || reportName == ""}
                  >
                    {t("DOWNLOAD")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {reportName != "" && (
          <div className="wrap_us conten_set">
            <div className="card_me table_des1">
              <div className="card_body">
                <div className="saech_des">
                  <div className="left_shiowp">
                    <label> {t("SHOW_ENTRIES")} </label>
                    <select
                      className="form-control"
                      defaultValue="5"
                      onChange={(e) => {
                        e.stopPropagation();
                        setLimit(e.target.value);
                        setPage(1);
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  {/* <div className="right_shiowp">
                    <label>Search</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search.."
                      onChange={(e) => setSearch(e.target.value)}
                    />{" "}
                  </div> */}
                </div>

                {reportName == "revenue" && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="orderd"> {t("S_NO")}.</th>
                          <th className="orderd"> {t("REQUEST_AMOUNT")} </th>
                          <th className="orderd">
                            {" "}
                            {t("SPONSOR_PERCENTAGE")}{" "}
                          </th>
                          <th className="orderd"> {t("RECIVED_AMOUNT")} </th>
                          <th className="orderd"> {t("RECIVED_DATE")} </th>
                          <th className="orderd"> {t("PAYMENT_ID")} </th>
                          <th className="orderd"> {t("PAYMENT_METHOD")} </th>
                        </tr>
                      </thead>

                      <tbody>
                        {record.map((item, index) => (
                          <tr key={"1" + index}>
                            <td style={{ width: "80px" }}>
                              {page > 1
                                ? limit * page - limit + index + 1
                                : index + 1}
                            </td>
                            <td>{"$" + item.total_amount_request}</td>
                            <td>
                              {"$" +
                                Math.floor(item.total_amount_request * 0.1)}
                            </td>
                            <td>
                              {"$" +
                                Math.floor(
                                  +item.total_amount_request +
                                    item.total_amount_request * 0.1
                                )}
                            </td>
                            <td>
                              {" "}
                              {format(
                                parseISO(item.create_date_time),
                                "dd MMM yyyy"
                              )}
                            </td>
                            <td>
                              {item?.sponser_binance_acc_id != null
                                ? item?.sponser_binance_acc_id
                                : "-"}
                            </td>
                            <td> {item.active_payment_method}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {reportName == "worker_request" && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="orderd">{t("S_NO")}.</th>
                          <th className="orderd">{t("NAME")}</th>
                          <th className="orderd">{t("WARNING")}</th>
                          <th className="orderd">{t("TOTAL_CALL")}</th>
                          <th className="orderd">
                            {t("EARNING")} ({t("CREDITS")})
                          </th>
                          <th className="orderd">{t("COUNTRY")}</th>
                          <th className="orderd">{t("JOINING_DATE")}</th>
                          <th className="orderd">{t("STATUS")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {record.map((item, index) => (
                          <tr key={"1" + index}>
                            <td style={{ width: "80px" }}>
                              {page > 1
                                ? limit * page - limit + index + 1
                                : index + 1}
                            </td>
                            <td style={{ textTransform: "capitalize" }}>
                              {" "}
                              {item?.worker_name}
                            </td>
                            <td>{item?.worker_total_warning}</td>
                            <td>{item?.call_count}</td>
                            <td>{item?.worker_total_earn_coin}</td>
                            <td>
                              <img
                                src={
                                  item?.country_flag_url != undefined
                                    ? item?.country_flag_url
                                    : ""
                                }
                                className="tab_flag"
                              />{" "}
                              {item?.country_name === undefined
                                ? "-"
                                : item?.country_name}
                            </td>
                            <td>
                              {format(
                                parseISO(
                                  item?.start_datetime.replace(/\.\d+Z$/, "")
                                ),
                                "dd MMM yyyy"
                              )}
                            </td>
                            <td>
                              {item?.current_status == "none"
                                ? `${t("PENDING")}`
                                : item?.current_status}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {/* {reportName == "payment_request" && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="orderd">S No.</th>
                        <th className="orderd">Sponsor Name</th>
                        <th className="orderd">Email</th>
                        <th className="orderd">Payment Request Date</th>
                        <th className="orderd">Wallet Address</th>
                        <th className="orderd">Country</th>
                        <th className="orderd">Request Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.map((item, index) => (
                        <tr key={"1" + index}>
                          <td>
                            {page > 1
                              ? limit * page - limit + index + 1
                              : index + 1}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {" "}
                            {item?.sponser_name}
                          </td>
                          <td>{item?.email}</td>
                          <td>
                            {format(
                              parseISO(item.create_date_time),
                              "dd MMM yyyy"
                            )}
                          </td>

                          <td>{item?.sponser_binance_acc_id}</td>
                          <td>
                            <img
                              src={
                                item?.country_flag != undefined
                                  ? item?.country_flag
                                  : ""
                              }
                              className="tab_flag"
                            />{" "}
                            {item?.country_name === undefined
                              ? "NA"
                              : item?.country_name}
                          </td>
                          <td>{"$" + item?.total_amount_request}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )} */}

                {record.length == 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "15px",
                    }}
                  >
                    <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
                {record.length != 0 && (
                  <div className="pag_botm">
                    <div className="left_neu">
                      {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                      {limit > countData
                        ? countData
                        : page * limit > countData
                        ? countData
                        : page * limit}{" "}
                      {t("OF")} {countData} {t("ENTRIES")}
                    </div>
                    <div className="pagddination">
                      <button
                        className="btn prew_1"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (page > 1) {
                            setPage(page - 1);
                          }
                        }}
                      >
                        {t("PREVIOUS")}
                      </button>{" "}
                      <input
                        className="form-control"
                        type="text"
                        style={{ textAlign: "center" }}
                        defaultValue={page}
                        readOnly
                      />{" "}
                      <button
                        className="btn next_bth"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (countPage > page) {
                            setPage(page + 1);
                          }
                        }}
                      >
                        {t("NEXT")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Sponsorreport;
