/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useRef } from "react";
// import Header from "../../Admin/CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { parseISO, format } from "date-fns";
import Swal from "sweetalert2";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import Header from "../../Admin/CommonPage/Header";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
function SendPaymentRequest() {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const imageRef = useRef(null);
  const { t } = useTranslation();
  const {
    openside,
    getAllWrokerPaymentRequest,
    sendRequestforWorkerAmount,
    getAllWrokerInprocessPayment,
    uploadReciptForWorker,
    rejectPaymentRequest,
  } = useContext(ApiContext);
  const [initialized, setInitialized] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [dataCount, setDataCount] = useState(1);
  const [search, setSearch] = useState("");
  const [processlimit, setProcessLimit] = useState(10);
  const [processPage, setProcessPage] = useState(1);
  const [processCountPage, setProcessCountPage] = useState(1);
  const [processDataCount, setProcessDataCount] = useState(1);
  const [paymentRequestList, setPaymentRequestList] = useState([]);
  const [paymentInprocessList, setPaymentInprocessList] = useState([]);
  const [workerList, setWorkerList] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [workerId, setWorkerId] = useState([]);
  const [show, setShow] = useState(false);
  const [cropModal, setCropModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewReciept, setViewReciept] = useState("");
  const [recieptImage, setRecieptImage] = useState();
  const [requestId, setRequestId] = useState();
  const [reflect, setReflect] = useState(false);
  const [status, setStatus] = useState("all");
  const [details, setDetails] = useState("");
  const [crop, setCrop] = useState({
    unit: "%",
    x: 20,
    y: 20,
    width: 25,
    height: 25,
    aspect: 0.1 / 0.1,
    locked: true,
  });
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");

  const modalClose = () => {
    var myElement = document.getElementById("tr" + details.request_id);
    myElement.classList.remove("acTive");
    setShow(false);
    setViewReciept("");
  };
  const croperModalClose = () => {
    setCropModal(false);
    setViewReciept("");
  };
  const viewModalClose = () => {
    setViewModal(false);
  };

  useEffect(() => {
    getAllWrokerPaymentRequest(
      limit,
      page,
      setCountPage,
      setDataCount,
      setPaymentRequestList,
      search
    );
  }, [limit, page, search, reflect]);

  useEffect(() => {
    let totalSum = 0;
    setWorkerId([]);
    workerList.forEach((item) => {
      totalSum += +item.amount;
      setWorkerId((x) => [...x, item.request_id]);
    });
    setTotalAmount(totalSum);
  }, [workerList]);

  useEffect(() => {
    if (initialized) {
      getAllWrokerInprocessPayment(
        processlimit,
        processPage,
        setProcessCountPage,
        setProcessDataCount,
        setPaymentInprocessList,
        status
      );
    } else {
      setInitialized(true);
    }
  }, [processlimit, processPage, initialized, status]);

  const selectWorker = (event, item) => {
    // const isChecked = event.target.checked;
    // if (isChecked) {
    //   setWorkerList((prevList) => [...prevList, item]);
    // } else {
    //   const updatedList = workerList.filter(
    //     (worker) => worker.request_id !== item.request_id
    //   );
    //   setWorkerList(updatedList);
    // }
    const selectedIndex = workerList.indexOf(item);
    if (selectedIndex === -1 && event) {
      setWorkerList([...workerList, item]);
    } else {
      setWorkerList(
        workerList.filter((itemId) => itemId.request_id !== item.request_id)
      );
    }
  };

  function sendRequest() {
    let data = {
      AmountRequest: totalAmount,
      list_of_worker_request_id: workerId,
    };
    Swal.fire({
      title: t("ARE_YOU_SURE"),
      text: t("YOU_WANT_TO_SEND_REQUEST"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      cancelButtonColor: "#d33",
      confirmButtonText: t("YES"),
      cancelButtonText: t("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        sendRequestforWorkerAmount(data, afterSendMessage);
      }
    });
  }

  function afterSendMessage() {
    setWorkerList([]);
    setPage(1);
    setReflect((prevState) => !prevState);
  }

  function changeTab(tabName) {
    switch (tabName) {
      case "paymentrequest":
        if (paymentRequestList?.length === 0) {
          getAllWrokerPaymentRequest(
            limit,
            page,
            setCountPage,
            setPaymentRequestList,
            search
          );
        }
        break;
      case "paymentProcess":
        if (paymentInprocessList?.length === 0) {
          getAllWrokerInprocessPayment(
            processlimit,
            processPage,
            setProcessCountPage,
            setProcessDataCount,
            setPaymentInprocessList
          );
        }
        break;

      default:
        break;
    }
  }

  function uploadImage(e) {
    // setRecieptImage(e.target.files);
    setCropModal(true);
    setFileName(e.target.files[0].name);
    setViewReciept(URL.createObjectURL(e.target.files[0]));
  }

  function openUploadRecieptModal(id, name) {
    var myElement = document.getElementById("tr" + id);
    myElement.classList.add("acTive");
    setDetails(name);
    setRequestId(id);
    setShow(true);
  }

  async function sendRecipt() {
    await uploadReciptForWorker(
      requestId,
      recieptImage,
      afterSendNotification,
      modalClose
    );
  }

  function afterSendNotification() {
    getAllWrokerInprocessPayment(
      processlimit,
      processPage,
      setProcessCountPage,
      setProcessDataCount,
      setPaymentInprocessList,
      status
    );
  }

  // const copyToClipboard = async (value) => {
  //   let gettext = document.getElementById("td" + value);
  //   let textContent = "";
  //   if (gettext) {
  //     gettext.childNodes.forEach((node) => {
  //       if (node.nodeType === Node.TEXT_NODE) {
  //         textContent += node.textContent.trim();
  //       }
  //     });
  //   }
  //   try {
  //     await navigator.clipboard
  //       .writeText(textContent)
  //       .then(() => {
  //         toast.success(`${t("COPIED_TO_CLIPBOARD")}:` + textContent);
  //       })
  //       .catch((err) => {
  //         toast.error(`${t("UNABLE_TO_COPY")}:` + textContent);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(`${t("CLIPBOARD_IS_NOT_WORKING_ON_INCOGNITO")}`);
  //   }
  // };
  const copyToClipboard = async (textContent) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(textContent);
        toast.success(`${t("COPIED_TO_CLIPBOARD")}: ${textContent}`);
      } else {
        // Fallback method for browsers that don't support clipboard API
        const textField = document.createElement("textarea");
        textField.innerText = textContent;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        toast.success(`${t("COPIED_TO_CLIPBOARD")}: ${textContent}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(`${t("UNABLE_TO_COPY")}: ${textContent}`);
    }
  };

  function selectAll(check) {
    if (check) {
      const allIds = paymentRequestList.map((item) => item);
      setWorkerList(allIds);
    } else {
      setWorkerList([]);
    }
  }

  function reject(item) {
    let data = {
      id: item.request_id,
      Status: "cancel",
    };

    Swal.fire({
      title: "Are you sure?",
      html:
        "<span style='width:100%'> You want to reject  <span style='color:red;margin-bottom: 0px;' >" +
        item.worker_name +
        "</span> request! </span>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        rejectPaymentRequest(data, afterReject);
      }
    });
  }
  function afterReject(id) {
    // if (workerList.length != 0) {
    //   setWorkerList(workerList.filter((itemId) => itemId.request_id !== id));
    // }
    setReflect((prevState) => !prevState);
  }

  function onImageLoaded() {
    setImage(imageRef.current);
  }

  const saveCropImage = () => {
    makeClientCrop(crop);
    setCropModal(false);
  };
  async function makeClientCrop(crop) {
    if (viewReciept && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(image, crop, fileName);
      console.log(croppedImageUrl);
      setRecieptImage(croppedImageUrl);
      console.log(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        const croppedImageUrl = canvas.toDataURL("image/jpeg");
        setViewReciept(croppedImageUrl);

        canvas.toBlob((blob) => {
          const file = new File([blob], fileName, {
            type: "image/jpeg",
          });
          resolve(file);
        }, "image/jpeg");
      }, "image/jpeg");
    });
  }

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("WORKER_PAYMENT_REQUEST")} />
        <div className="wrap_us conten_set">
          <div className="card_me table_des1">
            <div className="card_body">
              <Tabs
                defaultActiveKey="paymentrequest"
                id="uncontrolled-tab-example"
                onSelect={(e) => changeTab(e)}
                className="mb-3"
              >
                <Tab eventKey="paymentrequest" title={t("PAYMENT_REQUEST")}>
                  <div className="">
                    <div className="saech_des">
                      <div className="left_shiowp">
                        <label>{t("SHOW_ENTRIES")}</label>
                        <select
                          className="form-control"
                          defaultValue="10"
                          onChange={(e) => {
                            e.stopPropagation();
                            setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="right_shiowp">
                        <label>{t("SEARCH")}</label>
                        <button
                          className="btn btn_theme me-2"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                          onClick={sendRequest}
                          disabled={totalAmount == 0}
                        >
                          {t("REQUEST")}{" "}
                          {totalAmount != 0 ? "$" + totalAmount : ""}
                        </button>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("SEARCH")}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="orderd">{t("S_NO")}.</th>
                            <th className="orderd">{t("WORKER_NAME")}</th>
                            <th className="orderd">{t("REQUEST_DATE")}</th>
                            <th className="orderd">{t("COUNTRY")}</th>
                            <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                            <th className="orderd">{t("PAYMENT_METHOD")}</th>
                            <th
                              style={{
                                width: "200px",
                              }}
                            >
                              {/* Action  */}
                              {t("SELECT_ALL")}{" "}
                              <span>
                                {" "}
                                <input
                                  onChange={(e) => selectAll(e.target.checked)}
                                  checked={
                                    paymentRequestList.length ==
                                      workerList.length &&
                                    paymentRequestList.length != 0
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                />
                              </span>
                            </th>
                            <th>{t("ACTION")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentRequestList.map((item, index) => (
                            <tr key={item.request_id}>
                              <td style={{ width: "80px" }}>
                                {page > 1
                                  ? limit * page - limit + index + 1
                                  : index + 1}
                              </td>
                              <td>{item?.worker_name}</td>
                              <td style={{ width: "180px" }}>
                                {format(
                                  parseISO(item.request_date),
                                  " dd MMM yyyy,HH.mm"
                                )}
                                {/* {item.request_date} */}
                              </td>
                              <td>
                                <img
                                  src={item.worker_country_flag_url}
                                  className="tab_flag"
                                />{" "}
                                {item?.worker_country_name}
                              </td>
                              <td>{"$" + item.amount}</td>
                              <td>{item.active_payment_method}</td>
                              <td>
                                <span className="chech_us">
                                  <input
                                    type="checkbox"
                                    className={"check_" + index}
                                    checked={
                                      workerList.findIndex(
                                        (x) => x.request_id == item.request_id
                                      ) !== -1
                                        ? true
                                        : false
                                    }
                                    onChange={(event) =>
                                      selectWorker(event, item)
                                    }
                                  />
                                  <span className="box_che"></span>
                                </span>
                              </td>
                              <td style={{ width: "210px" }}>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => reject(item)}
                                  disabled={
                                    workerList.findIndex(
                                      (x) => x.request_id == item.request_id
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                >
                                  {t("REJECT_REQUEST")}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {paymentRequestList.length == 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        <p style={{ color: "red" }}> {t("NO_RECORD_FOUND")}</p>
                      </div>
                    )}
                    {paymentRequestList.length != 0 && (
                      <div className="pag_botm">
                        <div className="left_neu">
                          <p>
                            {t("SHOWING")} {limit * page - limit + 1} {t("TO")}{" "}
                            {limit > dataCount
                              ? dataCount
                              : page * limit > dataCount
                              ? dataCount
                              : page * limit}{" "}
                            {t("OF")} {dataCount} {t("ENTRIES")}
                          </p>
                        </div>
                        <div className="pagddination">
                          <button
                            className="btn prew_1"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (page > 1) {
                                setPage(page - 1);
                              }
                            }}
                          >
                            {t("PREVIOUS")}
                          </button>{" "}
                          <input
                            className="form-control"
                            type="text"
                            style={{ textAlign: "center" }}
                            value={page}
                            readOnly
                          />{" "}
                          <button
                            className="btn next_bth"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (countPage > page) {
                                setPage(page + 1);
                              }
                            }}
                          >
                            {t("NEXT")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="paymentProcess" title={t("PAYMENT_INPROCESS")}>
                  <div className="">
                    <div className="">
                      <div className="saech_des">
                        <div className="left_shiowp">
                          <label> {t("SHOW_ENTRIES")}</label>
                          {/* pagination */}
                          <select
                            className="form-control"
                            defaultValue="10"
                            onChange={(e) => {
                              e.stopPropagation();
                              setProcessLimit(e.target.value);
                              setProcessPage(1);
                              // getCustomer()
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          {/* pagination */}
                        </div>

                        <div className="right_shiowp">
                          <select
                            defaultValue={status}
                            className="form-control"
                            style={{
                              width: "120px",
                              marginRight: "10px",
                            }}
                            onChange={(e) => {
                              e.stopPropagation();
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="all">{t("ALL")}</option>
                            <option value="approve">{t("APPROVED")}</option>
                            <option value="notapprove">
                              {t("NOT_APPROVED")}
                            </option>
                          </select>
                          <label>{t("SEARCH")}</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={t("SEARCH")}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="orderd">{t("S_NO")}.</th>
                              <th className="orderd">{t("WORKER_NAME")}</th>
                              <th className="orderd">{t("REQUEST_DATE")}</th>
                              <th className="orderd">{t("COUNTRY")}</th>
                              <th className="orderd">{t("BANK_ACOUNT_ID")}</th>
                              <th className="orderd">{t("REQUEST_AMOUNT")}</th>
                              <th className="orderd">{t("PAYMENT_METHOD")}</th>
                              <th className="orderd">{t("STATUS")}</th>

                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                {t("ACTION")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentInprocessList.map((item, index) => (
                              <tr key={"1" + index} id={"tr" + item.request_id}>
                                <td>
                                  {processPage > 1
                                    ? processlimit * processPage -
                                      processlimit +
                                      index +
                                      1
                                    : index + 1}
                                </td>
                                <td>{item?.worker_name}</td>
                                <td>
                                  {format(
                                    parseISO(item.request_date),
                                    " dd MMM yyyy,HH.mm"
                                  )}
                                  {/* {item.request_date} */}
                                </td>
                                <td>
                                  <img
                                    src={item.worker_country_flag_url}
                                    className="tab_flag"
                                  />{" "}
                                  {item?.worker_country_name}
                                </td>
                                <td id={"td" + item.account_id}>
                                  {item.account_id}
                                  <img
                                    style={{
                                      marginLeft: "10px",
                                      marginTop: "4px",
                                      height: "17px",
                                      float: "right",
                                    }}
                                    src="/img/copy.png"
                                    data-tooltip-id="view"
                                    data-tooltip-content={t("COPY_ACCOUNT_ID")}
                                    onClick={() =>
                                      copyToClipboard(item?.account_id)
                                    }
                                  />
                                  <Tooltip id="view" />
                                </td>
                                <td>{"$" + item.amount}</td>
                                <td>{item.active_payment_method}</td>
                                <td>
                                  <span className="chech_us">
                                    {item.payment_done_by_admin && (
                                      <p className="text-success">
                                        {t("PAYMENT_RECIVED")}
                                      </p>
                                    )}
                                    {!item.payment_done_by_admin && (
                                      <p className="text-danger">
                                        {t("PENDING_FOR_ADMIN_APPROVAL")}
                                      </p>
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="btn_us1">
                                    {item.payment_done_by_admin ? (
                                      <button
                                        className="btn btn_clr"
                                        data-tooltip-id="view"
                                        data-tooltip-content={t(
                                          "UPLOAD_RECEIPT_AFTER_PAYMENT"
                                        )}
                                        onClick={() =>
                                          openUploadRecieptModal(
                                            item.request_id,
                                            item
                                          )
                                        }
                                      >
                                        <img src="img/receipt_2.png" />
                                      </button>
                                    ) : (
                                      "-"
                                    )}

                                    <Tooltip id="view" />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {paymentInprocessList.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{ color: "red" }}>{t("NO_RECORD_FOUND")}</p>
                        </div>
                      )}
                      {paymentInprocessList.length != 0 && (
                        <div className="pag_botm">
                          <div className="left_neu">
                            <p>
                              {t("SHOWING")}{" "}
                              {processlimit * processPage - processlimit + 1}{" "}
                              {t("TO")}{" "}
                              {processlimit > processDataCount
                                ? processDataCount
                                : processPage * processlimit > processDataCount
                                ? processDataCount
                                : processPage * processlimit}{" "}
                              {t("OF")} {processDataCount}
                              {t("ENTRIES")}
                            </p>
                          </div>
                          <div className="pagddination">
                            <button
                              className="btn prew_1"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (processPage > 1) {
                                  setProcessPage(processPage - 1);
                                }
                              }}
                            >
                              {t("PREVIOUS")}
                            </button>{" "}
                            <input
                              className="form-control"
                              type="text"
                              style={{ textAlign: "center" }}
                              value={processPage}
                              readOnly
                            />{" "}
                            <button
                              className="btn next_bth"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (processCountPage > processPage) {
                                  setProcessPage(processPage + 1);
                                }
                              }}
                            >
                              {t("NEXT")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      {/* Upload Receipt modal */}
      <Modal show={show} onHide={modalClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("UPLOAD_RECIPET_TO")} {details.worker_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list_desig">
            <ul className="ul_set">
              <li>
                <b>{t("NAME")} : </b>
                {details?.worker_name}
              </li>
              <li>
                <b>{t("METHOD")} : </b>
                {details?.active_payment_method}
              </li>
              {details?.request_date != undefined ? (
                <li>
                  <b> {t("REQUEST_DATE")} : </b>
                  {format(parseISO(details?.request_date), "dd MMM yyyy,HH.mm")}
                </li>
              ) : (
                ""
              )}

              <li>
                <b>{t("AMOUNT")} : </b>${details?.amount}
              </li>
            </ul>
          </div>
          <div>
            <div className="form-group mt-3">
              <label htmlFor="Credit"> {t("UPLOAD_RECIPET")} </label>
              <input
                type="file"
                id="giftupload"
                onChange={uploadImage}
                className={"form-control"}
              />
              {viewReciept !== "" ? (
                // <div className="image_cro_view">
                //   <img src={viewReciept} />
                // </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="down_respt my-4" style={{ width: 230 }}>
                      <img className="img_r" src={viewReciept} />

                      <h4>{t("RECEIPT")} </h4>
                      <button
                        className="btn"
                        onClick={() => setViewModal(true)}
                      >
                        <i
                          className="uil uil-eye"
                          data-tooltip-id="view"
                          data-tooltip-content={t("VIEW_RECEIPT")}
                          style={{ fontSize: 25 }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mt-3">
              <button
                style={{ float: "right" }}
                type="submit"
                className="btn btn_theme"
                onClick={sendRecipt}
                disabled={recieptImage == undefined}
              >
                {t("SEND_RECIPT")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Upload Receipt modal */}

      {/* Crop Receipt modal */}
      <Modal
        show={cropModal}
        size="xl"
        onHide={croperModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("IMAGE_CROPPER")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_image_croup">
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              maxWidth={1200}
              maxHeight={400}
              minHeight={100}
              minWidth={100}
            >
              <img
                ref={imageRef}
                src={viewReciept}
                onLoad={() => onImageLoaded()}
              />
            </ReactCrop>
          </div>
          <div className="flot_us3">
            <button
              type="submit"
              className="btn btn_theme"
              onClick={saveCropImage}
              // disabled={recieptImage == undefined}
            >
              {t("SAVE")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/*Crop Receipt modal */}

      {/*View Receipt modal */}
      <Modal
        show={viewModal}
        size="xl"
        onHide={viewModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("VIEW_RECEIPT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_image_croup">
            <img src={viewReciept} />
          </div>
        </Modal.Body>
      </Modal>
      {/*View Receipt modal */}
    </>
  );
}
export default SendPaymentRequest;
