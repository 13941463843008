/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import Header from "../CommonPage/Header";
import ApiContext from "../../context/ApiContext";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const Registration = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Worker & User Registration",
    },
  },
};
export const Revenue = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Revenue",
    },
  },
};

function Dashboard() {
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const { openside, getDashboardDetails } = useContext(ApiContext);
  const [datadetails, setData] = useState({});
  const [workerDate, setWorkerDate] = useState(new Date());
  const [revenueDate, setRevenueDate] = useState(new Date());
  const [workerGraph, setWerkerGraph] = useState({});
  const [revenueGraph, setrRvenueGraph] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [isCheckRevenue, setIsCheckRevenue] = useState(false);
  const [deafultData, setDeafultData] = useState({
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Worker",
        data: Array(12).fill(0), // Initialize with zeroes for 12 months
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "User",
        data: Array(12).fill(0), // Initialize with zeroes for 12 months
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  });

  const [deafultRevenu, setDeafultRevenue] = useState({
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Revenue",
        data: Array(12).fill(0),
        backgroundColor: "rgba(255, 165, 0)",
      },
    ],
  });

  useEffect(() => {
    getDashboardDetails(setData);
  }, []);

  useEffect(() => {
    getGraphData();
  }, [workerDate]);

  useEffect(() => {
    getRevenueData();
  }, [revenueDate]);

  const getGraphData = async () => {
    const obj = {
      year: Number(moment(workerDate).format("YYYY")),
    };
    try {
      const res = await api.post(
        `${baseURL}/vid_user/users_and_workers_monthly_count/`,
        obj
      );
      if (res.data.code == 200) {
        if (res.data.data != undefined) {
          setWerkerGraph(res.data.data);
        } else {
          setWerkerGraph(deafultData);
        }
        setIsCheck(true);
      } else {
        setWerkerGraph(deafultData);
        setIsCheck(false);
      }
    } catch (error) {
      setIsCheck(true);
      setWerkerGraph(deafultData);
    }
  };

  const getRevenueData = async () => {
    const obj = {
      year: Number(moment(revenueDate).format("YYYY")),
    };
    try {
      const res = await api.post(
        `${baseURL}/vid_user/monthly_revenue_count/`,
        obj
      );
      if (res.data.code == 200) {
        if (res.data.data != undefined) {
          setrRvenueGraph(res.data.data);
        } else {
          setrRvenueGraph(deafultRevenu);
        }
        setIsCheckRevenue(true);
      } else {
        toast.error();
        setrRvenueGraph(deafultRevenu);
        setIsCheckRevenue(false);
      }
    } catch (error) {
      setIsCheckRevenue(true);
      setrRvenueGraph(deafultRevenu);
    }
  };

  const findGraphData = (type, year) => {
    if (type == 0) {
      setWorkerDate(year);
    } else {
      setRevenueDate(year);
    }
  };

  return (
    <>
      <div className={`main_slide ${openside ? "open_slider" : ""}`}>
        <Header name={t("DASHBOARD")} />

        <div className="wrap_us conten_set">
          <div className="row row_sm dash_chart">
            <div className="col-md-3">
              <NavLink
                to={datadetails.worker_request_count != 0 && "/Workerlist"}
              >
                <div className="card_me grap_cont">
                  <span
                    className="icon_das"
                    style={{
                      background: "#3478F614",
                      color: "#3478F6",
                    }}
                  >
                    <i className="uil uil-user-plus"></i>
                  </span>
                  <h5>{t("TODAY'S_WORKER_REQUEST")}</h5>
                  <h6> {datadetails.worker_request_count}</h6>
                </div>
              </NavLink>
            </div>
            <div className="col-md-3">
              <NavLink
                to={
                  datadetails.worker_pending_request_count != 0 && "/Workerlist"
                }
              >
                <div className="card_me grap_cont">
                  <span
                    className="icon_das"
                    style={{
                      background: "#ffa50014",
                      color: "#ffa500",
                    }}
                  >
                    <i className="uil uil-user-check"></i>
                  </span>
                  <h5>{t("WORKER_PENDING_REQUEST")}</h5>
                  <h6>{datadetails.worker_pending_request_count}</h6>
                </div>
              </NavLink>
            </div>
            <div className="col-md-3">
              {/* <NavLink
                to={
                  datadetails.Payment_request_count == 0
                    ? ""
                    : "/paymentrequest"
                } */}
              <NavLink
                to={datadetails.Payment_request_count != 0 && "/paymentrequest"}
              >
                <div className="card_me grap_cont">
                  <span
                    className="icon_das"
                    style={{
                      background: "#4bc25a14",
                      color: "#4bc25a",
                    }}
                  >
                    <i className="uil uil-bill"></i>
                  </span>
                  <h5>{t("PAYMENT_REQUEST")}</h5>
                  <h6>{datadetails.Payment_request_count}</h6>
                </div>
              </NavLink>
            </div>
            <div className="col-md-3">
              <NavLink to={datadetails.Todays_report_count != 0 && "/Warning"}>
                <div className="card_me grap_cont">
                  <span
                    className="icon_das"
                    style={{
                      background: "#ffa50014",
                      color: "#ffa500",
                    }}
                  >
                    <i className="uil uil-file-graph"></i>
                  </span>
                  <h5>{t("TODAY'S_COMPLAINS")}</h5>
                  <h6>{datadetails.Todays_report_count}</h6>
                </div>
              </NavLink>
            </div>
            <div className="col-md-3">
              <NavLink to={datadetails.Report_count != 0 && "/Warning"}>
                <div className="card_me grap_cont">
                  <span
                    className="icon_das"
                    style={{
                      background: "#ffa50014",
                      color: "#ffa500",
                    }}
                  >
                    <i className="uil uil-file-graph"></i>
                  </span>
                  <h5>{t("TOTAL_COMPLAINS")}</h5>
                  <h6>{datadetails.Report_count}</h6>
                </div>
              </NavLink>
            </div>
            <div className="col-md-3">
              <NavLink
                to={datadetails.today_collection != 0 && "/paymentreceived"}
              >
                <div className="card_me grap_cont">
                  <span
                    className="icon_das"
                    style={{
                      background: "#ffa50014",
                      color: "#ffa500",
                    }}
                  >
                    <i className="uil uil-usd-circle"></i>
                  </span>
                  <h5>{t("PAYMENT_RECEIVED")}</h5>
                  <h6> $ {datadetails.today_collection}</h6>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="card_me table_des1">
            <div className="card_body">
              <div className="ttt">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="chart mb-3">
                      <div
                        className="calenderd1 year_selll"
                        style={{ marginLeft: "0" }}
                      >
                        <Calendar
                          value={workerDate}
                          onChange={(e) => findGraphData("0", e.value)}
                          view="year"
                          dateFormat="yy"
                          minDate={new Date(2023, 11, 31)} // Restrict to current date onwards
                          maxDate={new Date()}
                        />
                        <button className="btn btn_theme btn-sm">
                          {t("YEARS")}
                        </button>
                      </div>

                      {isCheck && (
                        <>
                          <Bar options={Registration} data={workerGraph} />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="chart  mb-3">
                      <div
                        className="calenderd1 year_selll"
                        style={{ marginLeft: "0" }}
                      >
                        <Calendar
                          value={revenueDate}
                          onChange={(e) => findGraphData("1", e.value)}
                          view="year"
                          dateFormat="yy"
                          minDate={new Date(2023, 11, 31)} // Restrict to current date onwards
                          maxDate={new Date()}
                        />
                        <button className="btn btn_theme btn-sm">
                          {" "}
                          {t("YEARS")}
                        </button>
                      </div>

                      {isCheckRevenue && (
                        <>
                          <Bar options={Revenue} data={revenueGraph} />
                        </>
                      )}
                      {/* <Bar options={Revenue} data={revenueGraph} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
