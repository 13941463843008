import Header from "../CommonPage/Header";
import React, { useContext, useEffect, useState } from "react";
import ApiContext from "../../context/ApiContext";
import { Chips } from "primereact/chips";
import { useTranslation } from "react-i18next";
function Createprohibited() {
  const { t } = useTranslation();
  const { openside, getAllCountry, createProhibitedBank } =
    useContext(ApiContext);
  const [country, setCountry] = useState([]);
  const [value, setValue] = useState([]);
  const [countryVal, serCountryVal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    getAllCountry(setCountry);
  }, []);

  function chipsSet(e) {
    if (
      (e?.value[e?.value?.length - 1]?.length === 4 ||
        e?.value[e?.value?.length - 1]?.length === undefined) &&
      (e?.value[e?.value?.length - 1]?.length === undefined ||
        /^\d*$/.test(e?.value[e?.value?.length - 1]))
    ) {
      setValue(e.value);
      setErrorMessage("");
    } else {
      if (!/^\d*$/.test(e?.value[e?.value?.length - 1])) {
        setErrorMessage(`${t("PLEASE_ENTER_NUMBER")}`);
      } else {
        setErrorMessage(`${t("PLEASE_ENTER_4_DIGITS")}`);
      }
    }
  }

  function cteareProhibited() {
    let data = {
      CountryId: countryVal,
      prohibitedbankcodelist: value,
    };
    createProhibitedBank(data, afterCreate);
  }
  function afterCreate() {}

  return (
    <div className={`main_slide ${openside ? "open_slider" : ""}`}>
      <Header name={t("CREATE_PROHIBITED")} />
      <div className="wrap_us conten_set">
        <div className="row">
          <div className="col-md-10">
            <div className="card_me">
              <div className="card_body">
                <div className="">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>{t("COUNTRY")}</label>
                      <select
                        name="country"
                        defaultValue={""}
                        className={"form-control"}
                        onChange={(e) => serCountryVal(e.target.value)}
                      >
                        <option value={""} disabled>
                          {t("SELECT_CONTRY")}
                        </option>
                        {country?.map((country) => {
                          if (!country.prohibited_bank_exist) {
                            return (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>{t("CODE")}*</label>
                      <div className=" p-fluid">
                        <Chips
                          value={value}
                          onChange={(e) => chipsSet(e)}
                          placeholder={t("ENTER_CODE")}
                        />
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3 text-end">
                  <button
                    type="submit"
                    className="btn btn_theme btn-lg"
                    disabled={value.length == 0 || countryVal == ""}
                    onClick={cteareProhibited}
                  >
                    {t("CREATE")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Createprohibited;
