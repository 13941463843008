import Header from "../CommonPage/Header";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiContext from "../../context/ApiContext";
import { useTranslation } from "react-i18next";
function Createsponsor() {
  const { t } = useTranslation();
  const { openside, getAllCountry, createSponsor } = useContext(ApiContext);
  const [country, setCountry] = useState();
  useEffect(() => {
    getAllCountry(setCountry);
  }, []);
  // form validation  Start
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const namePattern = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
  const validationSchema = (email) =>
    Yup.object().shape({
      email: Yup.string()
        .matches(emailPattern, `${t("INVALID_EMAIL_FORMAT")}`)
        .required(`${t("EMAIL_IS_REQUIRED")}`),
      password: Yup.string()
        .required(`${t("PASSWORD_IS_REQUIRED")}`)
        .min(6, `${t("DIGIT_LENGTH")}`)
        .max(40, `${t("PASSWORD_DIGIT")}`),
      name: Yup.string()
        .matches(namePattern, `${t("NAME_VALIDATION")}`)
        .required(`${t("NAME_IS_REQUIRED")}`),
      country: Yup.string().required(`${t("COUNTRY_IS_REQUIRED")}`),
    });
  // form validation end

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      country: "",
    },
    validationSchema,
    onSubmit: (data) => {
      createSponsor(data);
    },
  });

  return (
    <div className={`main_slide ${openside ? "open_slider" : ""}`}>
      <Header name={t("CREATE_SPONSOR")} />
      <div className="wrap_us conten_set">
        <div className="row">
          <div className="col-md-10">
            <form onSubmit={formik.handleSubmit}>
              <div className="card_me">
                <div className="card_body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>{t("NAME")}*</label>
                        <input
                          style={{ whiteSpace: "nowrap" }}
                          type="text"
                          name="name"
                          className={
                            "form-control" +
                            (formik.errors.name && formik.touched.name
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik.handleChange}
                          value={formik.values.name.replace(/\s{2,}/g, " ")}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.name && formik.touched.name
                            ? formik.errors.name
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>{t("EMAIL")}</label>
                        <input
                          name="email"
                          className={
                            "form-control" +
                            (formik.errors.email && formik.touched.email
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.email && formik.touched.email
                            ? formik.errors.email
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>{t("PASSWORD")}*</label>
                        <input
                          type="password"
                          name="password"
                          className={
                            "form-control" +
                            (formik.errors.password && formik.touched.password
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik.handleChange}
                          value={formik.values.password.replace(/\s{1,}/g, " ")}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.password && formik.touched.password
                            ? formik.errors.password
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>{t("COUNTRY")}</label>
                        <select
                          name="country"
                          className={
                            "form-control" +
                            (formik.errors.country && formik.touched.country
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik.handleChange}
                          value={formik.values.country}
                        >
                          <option value={""} disabled>
                            {t("SELECT_COUNTRY")}
                          </option>
                          {country?.map((country) => {
                            return (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {formik.errors.country && formik.touched.country
                            ? formik.errors.country
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>{t("LANGUAGE")}</label>
                        <select
                          name="language"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik.values.language}
                        >
                          <option value="en">
                            English
                          </option>
                          <option value="es">
                            Spanish
                          </option>
                        </select>

                      </div>
                    </div>
                  </div>

                  <div className="mb-3 text-end">
                    <button type="submit" className="btn btn_theme btn-lg">
                      {t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Createsponsor;
